import api from '@api'

import {
  getModuleTranslator,
  getModulePluaralTranslator,
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import moduleConfig from './config'
import {
  transformIncomingEmailConfigForServer,
  transformIncomingEmailConfig,
  transformOutgoingEmailConfig,
  transformOutgoingEmailConfigForServer,
  transformSupportPortalConfig,
  transformSupportPortalConfigForServer,
  transformChatConfig,
  transformChatConfigForServer,
  transformQuestions,
  transformQuestionsForServer,
  transformSupportData,
  transformOutfoingEmailRuleConfigForServer,
  transformOutfoingEmailRuleConfig,
} from '@data/it-infrastructure'
import { isLoggedIn } from '@utils/auth'

const __t = getModuleTranslator(moduleConfig.translationKey)
const __tc = getModulePluaralTranslator(moduleConfig.translationKey)
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function testOutgoingEmailConfigApi(config) {
  return api.post(
    '/email/outgoing/testconnection',
    {
      mailAddress: config.mailAddress,
      id: config.id,
    },
    {
      notification: {
        message: __t('connection_success'),
        description: __t('connection_succeeded'),
        error: {
          message: __t('connection_failure'),
          description: __t('connection_failed'),
        },
      },
    }
  )
}

export function getQuestionsApi() {
  return api.post('/security_question/search/byqual').then((data) => {
    return {
      items: (data.objectList || []).map(transformQuestions),
      total: data.totalCount,
    }
  })
}

export function deleteQuestionApi(item) {
  return api.delete(`/security_question/${item.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('question'),
    }),
  })
}

export function updateQuestionApi(item) {
  return api
    .patch(`/security_question/${item.id}`, transformQuestionsForServer(item), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('question'),
      }),
    })
    .then(transformQuestions)
}

export function createQuestionApi(item) {
  return api
    .post('/security_question', transformQuestionsForServer(item), {
      message: __rootT('created_successfully', {
        resource: __rootTc('question'),
      }),
    })
    .then(transformQuestions)
}

export function testIncomingEmailConfigApi(config) {
  return api.post(
    '/email/incoming/testconnection',
    {
      id: config.id,
    },
    {
      notification: {
        message: __t('connection_success'),
        description: __t('connection_succeeded'),
        error: {
          message: __t('connection_failure'),
          description: __t('connection_failed'),
        },
      },
    }
  )
}

// incoming email config api

export function getIncomingEmailConfigsApi(limit, offset) {
  return api
    .get(`email/incoming`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformIncomingEmailConfig),
        total: data.totalCount,
      }
    })
}

export function createIncomingEmailConfigApi(data) {
  return api
    .post(`/email/incoming`, transformIncomingEmailConfigForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('incoming_email_server'),
      }),
    })
    .then(transformIncomingEmailConfig)
}

export function getIncomingEmailConfigApi(id) {
  return api.get(`email/incoming/${id}`).then(transformIncomingEmailConfig)
}

export function updateIncomingEmailConfigApi(data) {
  return api
    .patch(
      `/email/incoming/${data.id}`,
      transformIncomingEmailConfigForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('incoming_email_server'),
        }),
      }
    )
    .then(transformIncomingEmailConfig)
}

export function deleteIncomingEmailConfigApi(id) {
  return api.delete(`/email/incoming/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('incoming_email_server'),
    }),
  })
}

// support portal configuration

export function getSupportPortalConfigApi() {
  return api.get(`/supportPortalConfig`).then(transformSupportPortalConfig)
}

export function updateSupportPortalConfigApi(data) {
  return api
    .patch(
      `/supportPortalConfig`,
      transformSupportPortalConfigForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __tc('support_portal_config'),
        }),
      }
    )
    .then(transformSupportPortalConfig)
}

// chat configuration

export function getChatConfigApi() {
  return api
    .get(`${!isLoggedIn() ? 'public' : ''}/chat/config`)
    .then(transformChatConfig)
}

export function updateChatConfigApi(data) {
  return api
    .patch(`/chat/config`, transformChatConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __tc('chat_config'),
      }),
    })
    .then(transformChatConfig)
}

export function getApplicableActionTypeDataApi() {
  return api.get(`/chat/flow/supporteddata`)
}

// outgoing email config api

export function getOutgoingEmailConfigApi(id) {
  return api.get(`email/outgoing/${id}`).then(transformOutgoingEmailConfig)
}

export function getOutgoingEmailConfigsApi(limit, offset) {
  return api
    .get(`email/outgoing`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformOutgoingEmailConfig),
        total: data.totalCount,
      }
    })
}

export function createOutgoingEmailConfigApi(data) {
  return api
    .post(`/email/outgoing`, transformOutgoingEmailConfigForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('outgoing_email_server'),
      }),
    })
    .then(transformOutgoingEmailConfig)
}

export function updateOutgoingEmailConfigApi(data) {
  return api
    .patch(
      `/email/outgoing/${data.id}`,
      transformOutgoingEmailConfigForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('outgoing_email_server'),
        }),
      }
    )
    .then(transformOutgoingEmailConfig)
}

export function deleteOutgoingEmailConfigApi(id) {
  return api.delete(`/email/outgoing/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('outgoing_email_server'),
    }),
  })
}

export function getOutgoingRuleEmailConfigApi(model) {
  return api
    .get(`${model}/rule/supportedData`)
    .then((data) => transformSupportData(data))
}

export function updateOutgoingEmailRulesApi(data) {
  return api.patch(
    `/mailRule`,
    transformOutfoingEmailRuleConfigForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('outgoing_email')} ${__rootTc('rule')}`,
      }),
    }
  )
}

export function getOutgoingEmailRulesApi() {
  return api
    .get(`/mailRule`)
    .then((data) => transformOutfoingEmailRuleConfig(data))
}

<template>
  <FlotoContentLoader
    classes="h-100"
    :transition="useTransition ? undefined : 'none'"
    :loading="loading"
  >
    <DetailView
      v-if="project.id"
      default-sidebar-visible
      :disabled="isProjectDisabled"
      :resource="project"
      :additional-info-col-size="9"
      :module-name="moduleName"
      :update-api="update"
      v-bind="{ ...$attrs }"
    >
      <template v-slot:before-title>
        <FlotoBackButton
          :to="backLink || $modules.getModuleRoute('project', '')"
          class="text-neutral-light mr-2"
        />
      </template>
      <template v-slot:actions>
        <slot name="actions">
          <slot name="action-before" />
          <MButton
            v-if="project.projectStatus === 'implementation'"
            id="close-btn"
            class="ml-2"
            @click="handleCloseProject"
          >
            {{ $t('close') }} {{ $tc(moduleName) }}
          </MButton>
          <MButton
            v-if="canManagePlanning && project.projectStatus === 'open'"
            id="start-planning-btn"
            class="ml-2"
            @click="handleStartPlanning"
          >
            {{ $t('start') }} {{ $tc('planning') }}
          </MButton>
          <MButton
            v-if="canManagePlanning && project.projectStatus === 'planning'"
            id="start-implementation-btn"
            class="ml-2"
            @click="handleStartImplementation"
          >
            {{ $t('start') }} {{ $tc('implementation') }}
          </MButton>
          <ActionMenu
            :project="project"
            :module-name="moduleName"
            :disabled="isProjectDisabled"
            :update-api="update"
            @archive="handleArchiveProject"
            @update="update"
            @refresh="refreshProject"
          />
          <slot name="action-after" />
        </slot>
      </template>
      <!-- default content as tabs -->
      <slot :ticket="project">
        <div :key="tabsRerenderCount" class="tabs">
          <MAffix>
            <MTab v-model="activeTabKey" size="small" class="affixed-tab">
              <template v-for="tab in allowedTabs">
                <MTabPane v-if="!tab.disabled" :key="tab.id" :tab="tab.name" />
              </template>
            </MTab>
          </MAffix>
          <div class="my-4">
            <component
              :is="tabComponent"
              :disabled="
                tabComponentProps.alwaysEditable
                  ? false
                  : isProjectDisabled || tabComponentProps.disabled
              "
              :resource-id="project.id"
              :module-name="moduleName"
              :resource="project"
              v-bind="tabComponentProps"
              :custom-rules="{ isAllowedWorklogAccessForTask: true }"
              :update-api="update"
              @relation-added="handleRelationCount"
              @relation-removed="handleRelationRemoved"
              @refresh="refreshProject"
            />
          </div>
        </div>
      </slot>
    </DetailView>
    <FlotoLoadError
      v-if="error"
      class="flex-1"
      :authorization-error="error.response && error.response.status === 403"
    />
    <ConfirmCloseModal
      v-if="showConfirmCloseModal"
      :module-name="moduleName"
      @confirm="update($event, true)"
      @hide="showConfirmCloseModal = false"
    >
      <template v-slot:message>
        {{
          $t('confirm_close', {
            resource: $tc(moduleName),
            resources: $tc(moduleName, 2),
          })
        }}
      </template>
    </ConfirmCloseModal>
    <ConfirmCloseModal
      v-if="showConfirmStartPlanningModal"
      :module-name="moduleName"
      @confirm="update({ ...pendingChanges, projectStatus: 'planning' }, true)"
      @hide="showConfirmStartPlanningModal = false"
    >
      <template v-slot:message>
        {{ $t('confirm_start_planning') }}
      </template>
    </ConfirmCloseModal>
    <ConfirmCloseModal
      v-if="showConfirmStartImplementationModal"
      :module-name="moduleName"
      @confirm="update({ projectStatus: 'implementation' }, true)"
      @hide="showConfirmStartImplementationModal = false"
    >
      <template v-slot:message>
        {{ $t('confirm_start_implementation') }}
      </template>
    </ConfirmCloseModal>
    <NoteRules ref="noteDrawer" :create-fn="handleNoteAdded" />
    <DialogRulesConfirmModal
      ref="dialogRulesConfirmModalRef"
      :module-name="moduleName"
      @confirm="handleConfirmDialogRule"
    />
  </FlotoContentLoader>
</template>

<script>
import Bus from '@utils/emitter'
import CloneDeep from 'lodash/cloneDeep'
import Intersection from 'lodash/intersection'
import { PreferenceComputed } from '@state/modules/preference'
import { FormComputed } from '@state/modules/form'
import { authComputed } from '@state/modules/auth'
import { StatusComputed } from '@state/modules/status'
import AuditContainer from '@components/audit/audit-container'
import EmailNotificationContainer from '@components/email-notification/email-notification-container'
import RelationContainer from '@components/relation/relation-container'
import WorkLogContainer from '@components/worklog/worklog-container'
import ConversationContainer from '@components/conversation/conversation-container.vue'
import { getApi, updateApi, deleteApi } from '../project-api'
import NoteRules from '@components/note-rules/note-rules'
import DialogRulesConfirmModal from '@components/dialog-rules/dialog-rules'
import { CustomRulesComputed } from '@state/modules/custom-rules'
import { getRelationCountApi } from '@components/relation/relation-api'
import ActionMenu from './action-menu'
import DetailView from './detail/detail.vue'
import ConfirmCloseModal from './confirm-close-modal.vue'
import PlannigTab from './planning/planning-tab'
import MemberContainer from './member/member-container-tab'
export default {
  name: 'ProjectDetail',
  components: {
    DetailView,
    AuditContainer,
    EmailNotificationContainer,
    RelationContainer,
    ConversationContainer,
    WorkLogContainer,
    ActionMenu,
    NoteRules,
    DialogRulesConfirmModal,
    ConfirmCloseModal,
    MemberContainer,
  },
  inheritAttrs: false,
  props: {
    moduleName: { type: String, required: true },
    useTransition: { type: Boolean, default: false },
    resourceId: { type: [String, Number], required: true },
    onChange: { type: Function, default: undefined },
    disabled: { type: Boolean, default: false },
    backLink: { type: Object, default: undefined },
  },
  data() {
    return {
      showConfirmCloseModal: false,
      showConfirmStartPlanningModal: false,
      showConfirmStartImplementationModal: false,
      pendingChanges: null,
      project: {},
      activeTabKey: 'planning',
      tabsRerenderCount: 1,
      error: null,
      loading: true,
      allowedTabs: [],
      vendor: {},
      settlementsInnerTab: null,
      relationCount: 0,
    }
  },
  computed: {
    ...FormComputed,
    ...authComputed,
    ...StatusComputed,
    ...CustomRulesComputed,
    ...PreferenceComputed,
    canManagePlanning() {
      return this.myAllowedModules.indexOf('project.manage_planning') >= 0
    },
    customFormFields() {
      return this[`${this.moduleName}Fields`]({
        exclude: [...(this.useTemplate ? [] : ['template'])],
      })
    },
    priceCustomFields() {
      return this.customFormFields.filter(
        (f) => f.attributes.costField === 'true'
      )
    },
    isProjectDisabled() {
      return (
        this.disabled ||
        this.project.projectStatus === 'closed' ||
        this.project.projectStatus === 'cancelled'
      )
    },
    tabComponent() {
      return (this.allowedTabs.find((t) => t.id === this.activeTabKey) || {})
        .component
    },
    tabComponentProps() {
      return (
        (this.allowedTabs.find((t) => t.id === this.activeTabKey) || {})
          .componentProps || {}
      )
    },
  },
  watch: {
    resourceId: {
      immediate: true,
      handler: 'getProject',
    },
  },
  created() {
    this.setTabs()
    const conversationHandler = (e, k, command, nextCommand) => {
      let availableCommands = []
      availableCommands = ['collaborate', 'note']
      if (
        availableCommands.indexOf(nextCommand) >= 0 &&
        this.activeTabKey !== 'work'
      ) {
        this.activeTabKey = 'work'
      }
    }
    Bus.$on('conversation', conversationHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('conversation', conversationHandler)
    })
    this.getRelationModuleCount()
  },
  methods: {
    getRelationModuleCount() {
      return getRelationCountApi(this.moduleName, this.resourceId).then(
        (data) => {
          Object.values(data).filter((value) => {
            this.relationCount = this.relationCount + value
          })
          this.setTabs()
        }
      )
    },
    handleRelationCount() {
      this.relationCount = 0
      this.getRelationModuleCount()
    },
    handleRelationRemoved() {
      this.relationCount = 0
      this.getRelationModuleCount()
    },
    setTabs() {
      let tabs = [
        {
          id: 'planning',
          name: this.$tc('planning'),
          component: PlannigTab,
          componentProps: {
            stickySearch: true,
            offsetTop: 38,
          },
        },
        {
          id: 'member',
          name: this.$tc('member'),
          component: MemberContainer,
          componentProps: {
            stickySearch: true,
            offsetTop: 38,
          },
        },
        {
          id: 'work',
          name: this.$tc('collaboration'),
          component: ConversationContainer,
          componentProps: {
            stickySearch: true,
            alwaysEditable: true,
            offsetTop: 38,
          },
        },
        {
          id: 'work_log',
          name: this.$t('work_log'),
          component: WorkLogContainer,
          componentProps: {
            stickySearch: true,
            offsetTop: 38,
            disabled: true,
          },
        },
        {
          id: 'relation',
          name: `${this.$tc('relation', 2)} (${this.relationCount})`,
          component: RelationContainer,
          componentProps: {
            stickySearch: true,
            offsetTop: 38,
          },
        },
        {
          id: 'activity',
          name: this.$tc('audit_trail'),
          component: AuditContainer,
        },
        {
          id: 'email_notification',
          name: this.$tc('notification', 2),
          component: EmailNotificationContainer,
          componentProps: {
            alwaysEditable: true,
            stickySearch: true,
            offsetTop: 38,
          },
        },
      ]
      this.allowedTabs = Object.freeze(tabs)
    },
    getProject() {
      this.loading = true
      this.project = {}
      return getApi(this.resourceId, this.isPortalLogin)
        .then((project) => {
          this.loading = false
          this.project = project
          this.setTabs()
          return project
        })
        .catch((e) => {
          this.error = e
          this.loading = false
          this.$router.replace(this.$currentModule.getRoute(''))
        })
    },
    refreshProject(reRenderTabs = undefined) {
      return getApi(this.resourceId, this.isPortalLogin).then((project) => {
        this.project = project
        this.setTabs()
        if (reRenderTabs && reRenderTabs.indexOf(this.activeTabKey) >= 0) {
          this.tabsRerenderCount = this.tabsRerenderCount + 1
        }
        // if (
        //   reRenderTabs &&
        //   reRenderTabs.indexOf('planning') >= 0 &&
        //   this.allowedTabs.find((t) => t.id === 'planning') &&
        //   this.activeTabKey !== 'planning'
        // ) {
        //   this.activeTabKey = 'planning'
        // }

        return project
      })
    },
    update(change, forceUpdate = false, ignoreDialog = false) {
      if (Object.keys(change).length) {
        const noteRequiredFields = CloneDeep(
          (this[`${this.moduleName}NoteRequiredFields`] || []).map(
            (i) => `${i}`
          )
        )
        if (
          Intersection(noteRequiredFields, Object.keys(change)).length &&
          forceUpdate === false
        ) {
          this.pendingChanges = change
          this.$refs.noteDrawer.openForm()
          return Promise.resolve()
        } else {
          const checkIsDialogRulesQualifide =
            this.$refs.dialogRulesConfirmModalRef &&
            this.$refs.dialogRulesConfirmModalRef.checkIsDialogRulesQualifide(
              change
            )
          if (!forceUpdate && checkIsDialogRulesQualifide) {
            if (ignoreDialog) {
              this.$refs.dialogRulesConfirmModalRef.handleHide()
            } else {
              this.pendingChanges = change
              this.$refs.dialogRulesConfirmModalRef.handleShow()
              return Promise.resolve()
            }
          }
        }
        if (
          forceUpdate !== true &&
          'projectStatus' in change &&
          change.projectStatus === 'closed'
        ) {
          this.showConfirmCloseModal = true
          return Promise.resolve()
        }
        if (
          forceUpdate !== true &&
          'projectStatus' in change &&
          change.projectStatus === 'planning'
        ) {
          this.pendingChanges = change
          this.showConfirmStartPlanningModal = true
          return Promise.resolve()
        }
        if (
          forceUpdate !== true &&
          'projectStatus' in change &&
          change.projectStatus === 'implementation'
        ) {
          this.showConfirmStartImplementationModal = true
          return Promise.resolve()
        }
        return updateApi({
          id: this.project.id,
          ...change,
        })
          .then((data) => {
            this.pendingChanges = null
            this.project = { ...this.project, ...data }
            if (this.onChange) {
              this.onChange(data)
            }
            if ('projectStatus' in change) {
              this.refreshProject(['planning'])
            }
            return data
          })
          .catch(() => {
            if (this.$refs.dialogRulesConfirmModalRef) {
              this.$refs.dialogRulesConfirmModalRef.handleHide()
            }
          })
      }
      return this.refreshProject(['planning'])
    },
    handleNoteAdded(data) {
      return this.update(
        {
          ...data,
          ...this.pendingChanges,
        },
        true
      ).then(() => {
        this.$refs.noteDrawer.closeForm()
        this.refreshTicket(['work'])
      })
    },
    handleConfirmDialogRule() {
      return this.update(
        {
          ...this.pendingChanges,
        },
        true,
        true
      ).then(() => {
        this.$refs.dialogRulesConfirmModalRef.handleHide()
      })
    },
    handleArchiveProject() {
      deleteApi(this.project.id).then(() => this.$emit('delete'))
    },
    handleCloseProject(data) {
      this.update({
        id: this.project.id,
        projectStatus: 'closed',
      })
    },
    handleStartPlanning(data) {
      this.update({
        id: this.project.id,
        projectStatus: 'planning',
      })
    },
    handleStartImplementation(data) {
      this.update({
        id: this.project.id,
        projectStatus: 'implementation',
      })
    },
  },
}
</script>

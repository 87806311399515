<template>
  <tr class="purchase-item-row">
    <td class="text-ellipsis"> {{ itemIndex + 1 }}. </td>
    <td class="text-ellipsis">
      <ProductPicker
        disabled
        :value="item.productId"
        text-only
        placeholder="---"
      />
    </td>
    <td
      v-if="(hiddenColumns || []).indexOf('partNumber') < 0"
      class="text-ellipsis"
    >
      <FlotoFormItem
        v-if="!disabled"
        v-model="item.partNumber"
        class="mb-0"
        :validation-label="$tc('part_number')"
        :placeholder="$tc('part_number')"
      />
      <span v-else :title="item.partNumber || '---'">
        {{ item.partNumber || '---' }}
      </span>
    </td>
    <td v-if="(hiddenColumns || []).indexOf('price') < 0" class="text-ellipsis">
      <FlotoFormItem
        v-if="!disabled"
        v-model="item.price"
        type="number"
        :precision="2"
        class="mb-0"
        :min="0"
        :validation-label="$tc('price')"
        :placeholder="$tc('price')"
        rules="min_value:1"
      />
      <span v-else :title="(item.price || 0).toFixed(2)">
        {{ (item.price || 0).toFixed(2) }}
      </span>
    </td>
    <td
      v-if="(hiddenColumns || []).indexOf('taxRate') < 0"
      class="text-ellipsis"
    >
      <FlotoFormItem
        v-if="!disabled"
        v-model="item.taxRate"
        type="number"
        :precision="2"
        :min="0"
        :max="100"
        class="mb-0"
        :validation-label="$tc('tax_rate')"
        :placeholder="$tc('tax_rate')"
        rules="min_value:0|max_value:100"
      />
      <span v-else :title="(item.taxRate || 0).toFixed(2)">
        {{ (item.taxRate || 0).toFixed(2) }}
      </span>
    </td>
    <td class="text-ellipsis">
      <FlotoFormItem
        v-if="!disabled"
        v-model="item.quantity"
        type="number"
        :precision="0"
        :min="1"
        :max="10000"
        class="mb-0"
        :validation-label="$tc('quantity')"
        :placeholder="$tc('quantity')"
        rules="min_value:1|max_value:10000|numeric"
      />
      <span v-else :title="item.quantity || 0">{{ item.quantity || 0 }}</span>
    </td>
    <td
      v-if="(hiddenColumns || []).indexOf('remainingQuantity') < 0"
      class="text-ellipsis"
    >
      {{ item.remainingQuantity || 0 }}
    </td>
    <td
      v-if="(hiddenColumns || []).indexOf('receivedQuantity') < 0"
      class="text-ellipsis"
    >
      <FlotoFormItem
        v-if="canEditeceivedQuantity"
        v-model="item.receivedQuantity"
        type="number"
        :precision="0"
        :min="item.quantity - item.remainingQuantity"
        :max="item.quantity"
        class="mb-0"
        :validation-label="`${$tc('received')} ${$tc('quantity')}`"
        :placeholder="`${$tc('received')} ${$tc('quantity')}`"
        :rules="{
          // eslint-disable-next-line
          min_value: 1,
          // eslint-disable-next-line
          max_value: item.quantity,
          numeric: true,
        }"
      />
      <span v-else :title="item.receivedQuantity || 0">
        {{ item.receivedQuantity || 0 }}
      </span>
    </td>
    <td
      v-if="(hiddenColumns || []).indexOf('amount') < 0"
      class="text-right text-ellipsis"
    >
      <FlotoFormItem
        v-if="!disabled"
        v-model="item.totalAmount"
        disabled
        type="number"
        :precision="2"
        class="mb-0"
        :validation-label="$tc('amount')"
        :placeholder="$tc('amount')"
      />
      <div
        v-else
        :title="(item.totalAmount || 0).toFixed(2)"
        class="text-right text-ellipsis"
      >
        {{ (item.totalAmount || 0).toFixed(2) }}
      </div>
    </td>
    <td
      v-if="(hiddenColumns || []).indexOf('assetTypeId') < 0"
      class="text-ellipsis"
    >
      <FlotoAssetTypePicker
        v-if="item.productType === 'asset'"
        v-model="item.assetTypeId"
        as-input
        allow-clear
        :disabled="
          item.remainingQuantity !== item.quantity ||
          Boolean(assetType) ||
          item.receivedQuantity <= 0
        "
        :available-asset-type="availableAssetType"
      />
      <template v-else>---</template>
    </td>
    <td v-if="!disabled" class="text-left">
      <FlotoDeleteBtn
        :message="
          $t('confirm_delete_item', { item: `${$tc('order')} ${$tc('item')}` })
        "
        @confirm="handleRemove"
      />
    </td>
  </tr>
</template>

<script>
import { getAssetTypeSystemName } from '@modules/asset/helpers/asset-type'
import * as HELPER from '../../helpers/utils'
import ProductPicker from '@components/data-picker/product-picker'
export default {
  name: 'PurchaseItemRow',
  components: { ProductPicker },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    update: { type: Function, default: undefined },
    remove: { type: Function, default: undefined },
    itemIndex: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    hiddenColumns: { type: Array, required: true },
    canEditeceivedQuantity: { type: Boolean, default: false },
  },
  data() {
    return {
      assetType: 0,
    }
  },
  computed: {
    availableAssetType() {
      return [
        getAssetTypeSystemName.hardware,
        getAssetTypeSystemName.software,
        getAssetTypeSystemName.non_it_asset,
      ]
    },
  },
  watch: {
    'item.price': {
      // immediate: true,
      handler: 'handlePriceUpdate',
    },
    'item.taxRate': {
      // immediate: true,
      handler: 'handleTaxRateUpdate',
    },
    'item.quantity': {
      // immediate: true,
      handler: 'handleQuantityUpdate',
    },
    'item.receivedQuantity': {
      handler: 'handleReceivedQuantityUpdate',
    },
  },
  created() {
    this.calculateAmount()
    this.assetType = Object.freeze(this.item.assetTypeId)
  },
  methods: {
    calculateAmount() {
      this.$nextTick(() => {
        this.update({
          ...this.item,
          totalAmount: HELPER.getTotalAmount(
            this.item.price,
            this.item.quantity,
            this.item.taxRate
          ),
        })
      })
    },
    handleRemove() {
      this.remove()
    },
    handlePriceUpdate(data) {
      const price = HELPER.priceChecker(data)
      this.update({
        // price,
        totalAmount: HELPER.getTotalAmount(
          price,
          this.item.quantity,
          this.item.taxRate
        ),
      })
    },
    handleTaxRateUpdate(data) {
      const taxRate = HELPER.priceChecker(data)
      this.update({
        // taxRate,
        totalAmount: HELPER.getTotalAmount(
          this.item.price,
          this.item.quantity,
          taxRate
        ),
      })
    },
    handleQuantityUpdate(data) {
      const quantity = HELPER.qtyChecker(data)
      this.update({
        // quantity,
        totalAmount: HELPER.getTotalAmount(
          this.item.price,
          quantity,
          this.item.taxRate
        ),
      })
    },
    handleReceivedQuantityUpdate(newValue) {
      if (newValue <= 0) {
        this.update({
          assetTypeId: this.assetType,
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>

import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.risk-type` },
    meta: { moduleName, breadcrumbKey: 'release_management', nonLinked: true },
    children: [
      {
        path: 'risk-type',
        name: `${routeNamePrefix}.risk-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/risk-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('release_risk')}`
          },
        },
      },
      {
        path: 'release-type',
        name: `${routeNamePrefix}.release-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/release-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('release_type')}`
          },
        },
      },
      {
        path: 'reason-type',
        name: `${routeNamePrefix}.reason-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/reason-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('release_reason')}`
          },
        },
      },
    ],
  },
]

<template>
  <FlotoDrawerForm :open="open" @submit="handleSubmit" @cancel="handleCancel">
    <template v-slot:header> {{ $tc('add') }} {{ $tc('member') }} </template>
    <div class="flex flex-col flex-1 px-4">
      <UserList
        type="technician"
        selectable
        searchable
        :fetch-fn="fetchUsers"
        :hidden-columns="userHiddenColumns"
        @selection-change="handleTargetSelection"
      />
    </div>
    <template v-slot:actions="{ submit, hide }">
      <MRow>
        <MCol :size="12" class="text-right">
          <MButton
            id="add-btn"
            class="mx-1"
            :loading="processing"
            @click="submit"
            >{{ $tc('add') }}</MButton
          >
          <MButton id="cancel-btn" variant="default" @click="hide">
            {{ $tc('cancel') }}
          </MButton>
        </MCol>
      </MRow>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import { buildRelationalQualificationStructure } from '@data/qualification'
import { getUsersApi } from '@modules/users/users-api'
import UserList from '@components/item-selection-list/user-list'
import { getMembersApi } from './../../project-api'
export default {
  name: 'MemberContainerForm',
  components: { UserList },
  props: {
    createFn: { type: Function, required: true },
    resource: { type: Object, required: true },
    resourceId: { type: Number, required: true },
    availableMemberIds: { type: Array, required: true },
  },
  data() {
    this.userHiddenColumns = ['department', 'location']
    return {
      processing: false,
      open: true,
      formData: {},
    }
  },
  methods: {
    fetchUsers(type, searchCriterias, limit, offset) {
      const quals = buildRelationalQualificationStructure(
        'user.id',
        'not_in',
        this.availableMemberIds || [],
        'long'
      )
      const updatedCriteria = {
        quals: [quals, ...searchCriterias.quals],
        type: searchCriterias.type || 'FlatQualificationRest',
      }
      return getUsersApi(type, {}, updatedCriteria, limit, offset)
    },
    getMembers(limit, offset) {
      return getMembersApi(this.resourceId, limit, offset)
    },
    handleSubmit() {
      this.processing = true
      return this.createFn({
        id: this.resourceId,
        ...this.formData,
      })
        .then(() => {
          this.open = false
          setTimeout(() => {
            this.$emit('hide')
          }, 400)
        })
        .finally(() => {
          this.processing = false
        })
    },
    handleCancel() {
      this.open = false
      setTimeout(() => {
        this.$emit('hide')
      }, 400)
    },
    handleTargetSelection($event) {
      this.formData = {
        ...this.formData,
        members: $event.map(({ id }) => id).concat(this.availableMemberIds),
      }
    },
  },
}
</script>

<template>
  <a @click="trigger">
    <slot>
      <MTooltip>
        <template v-slot:trigger>
          <MIcon
            name="info-circle"
            class="mr-2 cursor-pointer text-primary center"
          />
        </template>
        {{ $tc('product_catalog') }}
      </MTooltip>
    </slot>
    <FlotoDrawer
      v-if="isDrawerOpen"
      :open="isDrawerOpen"
      width="75%"
      @hide="handleHide"
    >
      <template v-slot:title>
        {{ $tc('product_catalog') }}
      </template>
      <ProductCatalogList disabled />
      <template v-slot:actions="{ hide }">
        <MButton variant="default" @click="hide">
          {{ $t('done') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </a>
</template>
<script>
import ProductCatalogList from '@modules/asset-management/views/product-catalog-list.vue'
export default {
  name: 'ProductCatalogListDrawer',
  components: { ProductCatalogList },
  data() {
    return {
      isDrawerOpen: false,
    }
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 400)
    },
    handleHide() {
      setTimeout(() => {
        this.isDrawerOpen = false
      }, 400)
    },
  },
}
</script>

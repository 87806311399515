import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformPackages,
  transformPackagesForServer,
} from '@data/packages.js'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// product-type
const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.displayName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName,
        'string',
        'db'
      )
    )
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'in',
        qualificationFactors.selectedName,
        'string',
        'db'
      )
    )
  }
  if ('ids' in qualificationFactors) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  if ('excludedIds' in qualificationFactors) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        qualificationFactors.excludedIds,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function packagesSearchByApi(
  filter,
  limit,
  offset,
  additionalParams = {}
) {
  return api
    .post(
      `/deployment/packages/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.ids ||
          filter.excludedIds ||
          filter.selectedName
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformPackages),
        total: data.totalCount,
      }
    })
}

export function getPackagesApi(id) {
  return api.get(`/deployment/packages/${id}`).then(transformPackages)
}
export function createPackagesApi(data) {
  return api
    .post(`/deployment/packages`, transformPackagesForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('packages'),
      }),
    })
    .then(transformPackages)
}

export function updatePackagesApi(data) {
  return api
    .patch(
      `/deployment/packages/${data.id}`,
      transformPackagesForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('packages'),
        }),
      }
    )
    .then(transformPackages)
}

export function deletePackagesApi(id) {
  return api.delete(`/deployment/packages/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('packages'),
    }),
  })
}

import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, breadcrumbKey: 'patch_management' },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "packages" */ './views/list')
          ),
        meta: {
          breadcrumbKey: 'packages',
        },
      },
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "packages" */ './views/create')
          ),
        meta: {},
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "packages" */ './views/edit')
          ),
        meta: {},
      },
    ],
  },
]

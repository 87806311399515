import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.risk-type` },
    meta: { moduleName, breadcrumbKey: 'project_management', nonLinked: true },
    children: [
      {
        path: 'risk-type',
        name: `${routeNamePrefix}.risk-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/risk-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('project_risk')}`
          },
        },
      },
      {
        path: 'project-type',
        name: `${routeNamePrefix}.project-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/project-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('project_type')}`
          },
        },
      },
    ],
  },
]

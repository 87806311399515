<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
export default {
  name: 'PrintTemplateModule',
  page() {
    return {
      title: `${this.$tc('print')} ${this.$tc('template')}`,
    }
  },
}
</script>

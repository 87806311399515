<template>
  <a @click="triggerAction">
    <slot name="trigger">
      <a class="mr-2"> {{ $tc('insert') }} {{ $tc('address') }} </a>
    </slot>
    <FlotoDrawer
      :open="isDrawerOpen"
      width="70%"
      class="flex"
      @hide="handleDrawer"
    >
      <template v-slot:title> {{ $tc('add') }} {{ $tc('address') }} </template>
      <template v-slot:trigger>
        <span />
      </template>
      <AddressSelectionList
        selectable
        :parent-module="moduleName"
        @selection-change="selectionChange"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          id="submit-btn"
          class="mx-1"
          variant="primary"
          :loading="processing"
          :disabled="!selectedItems.length"
          @click="handleAddAction"
        >
          {{ $tc('add') }}
        </MButton>

        <MButton id="cancel-btn" class="mx-1" variant="default" @click="hide">
          {{ $tc('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </a>
</template>

<script>
import AddressSelectionList from '@components/item-selection-list/address-selection-list.vue'

export default {
  name: 'AddressDrawer',
  components: { AddressSelectionList },
  props: {
    hasUpdate: { type: Boolean, default: false },
    moduleName: {
      type: String,
      default() {
        return this.$constants.PURCHASE
      },
    },
  },
  data() {
    this.portal = 'support-portal'
    return {
      loading: true,
      isDrawerOpen: false,
      processing: false,
      selectedItems: [],
    }
  },
  methods: {
    handleDrawer() {
      this.selectedItems = []
      this.isDrawerOpen = false
    },
    triggerAction() {
      this.$emit('trigger')
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 100)
    },
    handleAddAction() {
      this.$emit('selected', this.selectedItems[0], '')
      this.handleDrawer()
      this.selectedItems = []
    },
    selectionChange(items) {
      this.selectedItems = items
    },
  },
}
</script>

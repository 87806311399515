<template>
  <MRow :gutter="0" class="mt-4">
    <MCol :size="3">
      <ProjectStatusPicker
        disabled
        :value="value.projectStatus"
        :placeholder="disabled ? '---' : undefined"
        :module-name="moduleName"
        @change="handleChange({ projectStatus: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            disabled
            v-bind="slotData"
            :lable="$tc('status')"
            icon-name="status"
          />
        </template>
      </ProjectStatusPicker>
    </MCol>
    <MCol :size="3">
      <FlotoPriorityPicker
        :disabled="disabled"
        :placeholder="'---'"
        :value="value.priorityId"
        @change="handleChange({ priorityId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="disabled"
            v-bind="slotData"
            :lable="$tc('priority')"
            icon-name="priority"
          />
        </template>
      </FlotoPriorityPicker>
    </MCol>
    <MCol :size="3">
      <FlotoProjectTypePicker
        :disabled="disabled"
        :placeholder="'---'"
        :additional-options="additionalOptions"
        :value="value.projectTypeId"
        @change="handleChange({ projectTypeId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="disabled"
            v-bind="slotData"
            :lable="$tc('project_type')"
            icon-name="tasks-alt"
          />
        </template>
      </FlotoProjectTypePicker>
    </MCol>
    <MCol :size="3">
      <div>
        <FlotoTechnicianPicker
          size="default"
          :disabled="disabled"
          :value="value.technicianId"
          :group-id="value.groupId"
          @change="handleChange({ technicianId: $event })"
        >
          <template v-slot:trigger="slotData">
            <FlotoDropdownTrigger
              :disabled="disabled"
              v-bind="slotData"
              :lable="$tc('project_owner')"
              icon-name="question"
            >
              <template v-slot:icon>
                <FlotoUserAvatar
                  :size="34"
                  :avatar="(slotData.currentItem || {}).avatar"
                  :style="{
                    minWidth: `${24}px`,
                  }"
                />
              </template>
            </FlotoDropdownTrigger>
          </template>
        </FlotoTechnicianPicker>
      </div>
    </MCol>
  </MRow>
</template>

<script>
import ProjectStatusPicker from '@components/data-picker/project-status-picker'
export default {
  name: 'PrimaryRow',
  components: { ProjectStatusPicker },
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    this.additionalOptions = [
      {
        key: 0,
        id: 0,
        value: 0,
        label: this.$tc('none'),
        text: this.$tc('none'),
        archived: false,
      },
    ]
    return {}
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformRegistryTemplate,
  transformRegistryTemplateForServer,
  transformRegistryDynamicVariable,
} from '@data/registry-template.js'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// product-type
const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(buildNameFilterQuery(qualificationFactors.selectedName, 'in'))
  }
  if ('ids' in qualificationFactors) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  if ('excludedIds' in qualificationFactors) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        qualificationFactors.excludedIds,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function registryTemplateSearchByApi(
  filter,
  limit,
  offset,
  additionalParams = {}
) {
  return api
    .post(
      `/deployment/registry/search/byqual`,
      {
        qualDetails: Object.keys(filter).length
          ? buildQualifications(filter)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformRegistryTemplate),
        total: data.totalCount,
      }
    })
}

export function createRegistryTemplateApi(data) {
  return api
    .post(`/deployment/registry`, transformRegistryTemplateForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('registry_template'),
      }),
    })
    .then(transformRegistryTemplate)
}

export function updateRegistryTemplateApi(data) {
  return api
    .patch(
      `/deployment/registry/${data.id}`,
      transformRegistryTemplateForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('registry_template'),
        }),
      }
    )
    .then(transformRegistryTemplate)
}

export function getRegistryTemplateApi(id) {
  return api.get(`/deployment/registry/${id}`).then(transformRegistryTemplate)
}

export function deleteRegistryTemplateApi(id) {
  return api.delete(`/deployment/registry/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('registry_template'),
    }),
  })
}

export function getRegistryDynamicVariableApi() {
  return api.get(`/deployment/registry/dynamicvariable/list`).then((data) => {
    return {
      items: (data.objectList || []).map(transformRegistryDynamicVariable),
      total: data.totalCount,
    }
  })
}

import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformSupportPortalForList,
  transformSupportPortalForServer,
} from '@data/support-portal'
import {
  transformAttachment,
  buildImageUrlFromRefFileName,
} from '@data/attachment'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const transformBranding = (branding) => {
  return {
    id: branding.id,
    logo: branding.logo ? [transformAttachment(branding.logo)] : undefined,
    name: branding.name,
    email: branding.email,
    darkThemeLogo: branding.darkThemeLogo
      ? [transformAttachment(branding.darkThemeLogo)]
      : undefined,
    contactNumber: branding.contactNumber,
    customerPortalTitle: branding.customerPortalTitle,
    guestUserLandingPage: branding.guestUserLandingPage,
    ...(branding.logo
      ? {
          logoSrc: `/api/public/download/branding/${branding.logo.refFileName}`,
        }
      : {}),
    favicon: branding.favicon
      ? [transformAttachment(branding.favicon)]
      : undefined,
    ...(branding.favicon
      ? {
          faviconSrc: `/api/public/download/branding/${branding.favicon.refFileName}`,
        }
      : {}),
    ...(branding.darkThemeLogo
      ? {
          darkThemeLogoSrc: `/api/public/download/branding/${branding.darkThemeLogo.refFileName}`,
        }
      : {}),
    themeColorPalette: branding.themeColorPalette
      ? JSON.parse(branding.themeColorPalette)
      : '',
    pagesCustomization: branding.pagesCustomization,
    greetingMessage: branding.greetingMessage,
    linkbackUrl: branding.linkbackUrl,
    supportPortalBackground: branding.supportPortalBackground
      ? [transformAttachment(branding.supportPortalBackground)]
      : undefined,
    ...(branding.supportPortalBackground
      ? {
          supportPortalBackgroundSrc: buildImageUrlFromRefFileName(
            branding.supportPortalBackground.refFileName
          ),
        }
      : {}),
    productSetupGuideBackground: branding.productSetupGuide
      ? [transformAttachment(branding.productSetupGuide)]
      : undefined,
    helpViewEnable: branding.helpViewEnable,
    helpViewType: branding.helpViewType || 'url',
    helpViewUrlLink: branding.helpViewUrlLink || '',
    helpViewAttachment: branding.helpViewAttachment
      ? [transformAttachment(branding.helpViewAttachment)]
      : undefined,
  }
}

const transformBrandingForServer = (branding) => {
  return {
    id: branding.id,
    name: branding.name,
    email: branding.email,
    contactNumber: branding.contactNumber,
    customerPortalTitle: branding.customerPortalTitle,
    guestUserLandingPage: branding.guestUserLandingPage,
    logo: branding.logo && branding.logo.length ? branding.logo[0] : undefined,
    favicon:
      branding.favicon && branding.favicon.length
        ? branding.favicon[0]
        : undefined,
    darkThemeLogo:
      branding.darkThemeLogo && branding.darkThemeLogo.length
        ? branding.darkThemeLogo[0]
        : undefined,
    themeColorPalette: branding.themeColorPalette
      ? JSON.stringify(branding.themeColorPalette)
      : '',
    pagesCustomization: branding.pagesCustomization,
    greetingMessage: branding.greetingMessage,
    linkbackUrl: branding.linkbackUrl,
    supportPortalBackground:
      branding.supportPortalBackground &&
      branding.supportPortalBackground.length
        ? branding.supportPortalBackground[0]
        : {},
    helpViewEnable: branding.helpViewEnable,
    helpViewType: branding.helpViewType,
    helpViewUrlLink:
      branding.helpViewType === 'url' ? branding.helpViewUrlLink : '',
    helpViewAttachment:
      branding.helpViewType === 'attachment'
        ? branding.helpViewAttachment[0]
        : {},
  }
}

const transformSupportPortalSettings = (supportPortalSettings) => {
  return {
    allowGuestToReportRequest: supportPortalSettings.allowGuestToReportRequest,
    allowGuestRequestersToRequestForService:
      supportPortalSettings.allowGuestRequestersToRequestForService,
    allowSelfRegistration: supportPortalSettings.allowSelfRegistration,
    selfRegistrationType: supportPortalSettings.selfRegistrationType,
    selfServiceSetting: supportPortalSettings.selfServiceSetting,
    domains: supportPortalSettings.domainNames,
    showRelatedKnowledge: supportPortalSettings.showRelatedKnowledge,
    allowToLinkAsset: supportPortalSettings.allowToLinkAsset,
    allowToLinkCi: supportPortalSettings.allowToLinkCi,
    allowToReopenClosedReq: supportPortalSettings.allowToReopenClosedReq,
    allowToCloseReq: supportPortalSettings.allowToCloseReq,
    closedGraceTimeType: supportPortalSettings.closedGraceTimeType,
    closedGraceTime: supportPortalSettings.closedGraceTime,
    allowToReopenResolvedReq: supportPortalSettings.allowToReopenResolvedReq,
    resolvedGraceTimeType: supportPortalSettings.resolvedGraceTimeType,
    resolvedGraceTime: supportPortalSettings.resolvedGraceTime,
    allowReqToAccessMyAsset: supportPortalSettings.allowReqToAccessMyAsset,
    allowReqToAccessMyCi: supportPortalSettings.allowReqToAccessMyCi,
    allowRequesterToViewBarcodeAndQRCode:
      supportPortalSettings.allowRequesterToViewBarcodeAndQRCode,
    allowRequesterToAccessKB: supportPortalSettings.allowRequesterToAccessKB,
    allowRequesterToAccessServiceRequest:
      supportPortalSettings.allowRequesterToAccessServiceRequest,
    allowRequesterToAccessMyApprovals:
      supportPortalSettings.allowRequesterToAccessMyApprovals,
    allowRequesterToSubmitFeedback:
      supportPortalSettings.allowRequesterToSubmitFeedback,
    allowRequesterToAccestTicketApprovalTab:
      supportPortalSettings.showApprovalTab,
    allowReqToAccessMyChanges: supportPortalSettings.allowReqToAccessMyChanges,
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      supportPortalSettings.allowRequesterToCreateRequestBehalfOfOtherRequester,
    allowRequesterToViewAuditTrail:
      supportPortalSettings.allowRequesterToViewAuditTrail,
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      supportPortalSettings.allowRequesterToCreateIncidentBehalfOfOtherRequester,
    allowRequesterToCreateIncidents:
      supportPortalSettings.allowRequesterToCreateIncidents,
    mandateCommentToReopenRequest:
      supportPortalSettings.mandateCommentToReopenRequest,
  }
}

const transformSupportPortalSettingsForServer = (supportPortalSettings) => {
  return {
    allowGuestToReportRequest: supportPortalSettings.allowGuestToReportRequest,
    allowGuestRequestersToRequestForService:
      supportPortalSettings.allowGuestRequestersToRequestForService,
    allowSelfRegistration: supportPortalSettings.allowSelfRegistration,
    selfRegistrationType: supportPortalSettings.selfRegistrationType,
    domainNames: supportPortalSettings.domains,
    showRelatedKnowledge: supportPortalSettings.showRelatedKnowledge,
    allowToLinkAsset: supportPortalSettings.allowToLinkAsset,
    allowToLinkCi: supportPortalSettings.allowToLinkCi,
    allowToReopenClosedReq: supportPortalSettings.allowToReopenClosedReq,
    allowToCloseReq: supportPortalSettings.allowToCloseReq,
    closedGraceTimeType: supportPortalSettings.closedGraceTimeType,
    closedGraceTime: supportPortalSettings.closedGraceTime,
    allowToReopenResolvedReq: supportPortalSettings.allowToReopenResolvedReq,
    resolvedGraceTimeType: supportPortalSettings.resolvedGraceTimeType,
    resolvedGraceTime: supportPortalSettings.resolvedGraceTime,
    allowReqToAccessMyAsset: supportPortalSettings.allowReqToAccessMyAsset,
    allowReqToAccessMyCi: supportPortalSettings.allowReqToAccessMyCi,
    allowRequesterToViewBarcodeAndQRCode:
      supportPortalSettings.allowRequesterToViewBarcodeAndQRCode,
    allowRequesterToAccessKB: supportPortalSettings.allowRequesterToAccessKB,
    allowRequesterToAccessServiceRequest:
      supportPortalSettings.allowRequesterToAccessServiceRequest,
    allowRequesterToAccessMyApprovals:
      supportPortalSettings.allowRequesterToAccessMyApprovals,
    allowRequesterToSubmitFeedback:
      supportPortalSettings.allowRequesterToSubmitFeedback,
    showApprovalTab:
      supportPortalSettings.allowRequesterToAccestTicketApprovalTab,
    allowReqToAccessMyChanges: supportPortalSettings.allowReqToAccessMyChanges,
    allowRequesterToCreateRequestBehalfOfOtherRequester:
      supportPortalSettings.allowRequesterToCreateRequestBehalfOfOtherRequester,
    allowRequesterToViewAuditTrail:
      supportPortalSettings.allowRequesterToViewAuditTrail,
    allowRequesterToCreateIncidentBehalfOfOtherRequester:
      supportPortalSettings.allowRequesterToCreateIncidentBehalfOfOtherRequester,
    allowRequesterToCreateIncidents:
      supportPortalSettings.allowRequesterToCreateIncidents,
    mandateCommentToReopenRequest:
      supportPortalSettings.mandateCommentToReopenRequest,
  }
}

export function getSupportPortalsApi(limit, offset) {
  return api
    .post(
      '/supportportal/search/byqual',
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformSupportPortalForList) || [],
        total: data.totalCount,
      }
    })
}

export function createSupportPortalApi(data) {
  return api
    .post('/supportportal', transformSupportPortalForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('support_portal'),
      }),
    })
    .then(transformSupportPortalForList)
}

export function updateSupportPortalApi(data) {
  return api
    .patch(`/supportportal/${data.id}`, transformSupportPortalForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('support_portal'),
      }),
    })
    .then(transformSupportPortalForList)
}

export function deleteSupportPortalApi(id) {
  return api.delete(`/supportportal/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('support_portal'),
    }),
  })
}

export function getBrandingByIdApi(id) {
  return api.get(`/branding/${id}`).then(transformBranding)
}

export function updateBrandingByIdApi(data, brandingId) {
  return api
    .patch(`/branding/${brandingId}`, transformBrandingForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('branding'),
      }),
    })
    .then(transformBranding)
}

export function getSupportPortalSettingsByIdApi(id) {
  return api
    .get(`/supportPortalConfig/${id}`)
    .then(transformSupportPortalSettings)
}

export function updateSupportPortalSettingsByIdApi(
  data,
  supportPortalSettingsId
) {
  return api
    .patch(
      `/supportPortalConfig/${supportPortalSettingsId}`,
      transformSupportPortalSettingsForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc('support_portal')} ${__rootTc('setting')}`,
        }),
      }
    )
    .then(transformSupportPortalSettings)
}

import api from '@api'

import {
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformAddressForServer,
  transformAddressForClient,
} from '@data/purchase-management'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getAddressApi(filter, limit, offset, sortedColumn) {
  return api
    .post(
      `/address/template/search/byqual`,
      {
        qualDetails: filter.name
          ? buildFlatQualificationStructure([
              buildRelationalQualificationStructure(
                'name',
                'contains',
                filter.name,
                'string',
                'db'
              ),
            ])
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformAddressForClient),
        total: data.totalCount,
      }
    })
}

export function createAddressApi(address) {
  return api
    .post(`/address/template`, transformAddressForServer(address), {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('address')}`,
      }),
    })
    .then(transformAddressForClient)
}

export function deleteAddressApi(id) {
  return api.delete(`/address/template/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('address')}`,
    }),
  })
}

export function updateAddressApi(address) {
  return api
    .patch(
      `/address/template/${address.id}`,
      transformAddressForServer(address),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc('address')}`,
        }),
      }
    )
    .then(transformAddressForClient)
}

<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { CategoryMethods } from '@state/modules/category'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'ServiceCatalogModule',
  page() {
    return {
      title: this.$t('service_catalog'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.SERVICE_CATALOG) ===
        -1
      ) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    this.serviceCatalogCategoriesFetch()
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  created() {
    const excludedRoutes = [
      'service-catalog.request',
      'service-catalog.request-service',
    ]
    if (
      excludedRoutes.indexOf(this.$route.name) === -1 &&
      this.myAllowedModules.indexOf('admin.service_catalog') === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    // if user doesn't have access to request then we don't allow to request any service
    if (
      excludedRoutes.indexOf(this.$route.name) >= 0 &&
      this.myAllowedModules.indexOf(this.$constants.REQUEST) === -1
    ) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  methods: {
    ...CategoryMethods,
  },
}
</script>

import SortBy from 'lodash/sortBy'
import Moment from 'moment'
import Constants from '@constants'
import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
  transformBinaryQualForServer,
} from '@/src/data/qualification'

import {
  transformAttachment,
  transformAttachmentForServer,
} from '@data/attachment'

import {
  transformRecursive,
  flattenRecursive,
  buildHierarchy,
} from '@data/recursive'

import { generateId } from '@utils/id'
const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

export function transformPlanningTaskForList(task) {
  return {
    children: [],
    id: generateId(),
    taskId: task.id,
    name: task.name,
    subject: task.subject,
    type: task.model,
    ...(task.refId ? { parentId: task.refId } : {}),
    ...(task.reference ? { reference: task.reference } : {}),
    statusId: task.statusId,
    technicianId: task.technicianId,
    groupId: task.groupId,
    description: task.description,
    fileAttachments: (task.fileAttachments || []).map(transformAttachment),
    taskTypeId: task.taskTypeId,
    priorityId: task.priorityId,
    timeRange: {
      startTime: task.startTime,
      endTime: task.endTime,
    },
    startTime: task.startTime,
    endTime: task.endTime,
    order: task.objOrder,
    notifyBeforeHours: task.notifyBeforeHours,
    notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit,
    createdAt: task.createdTime,
    owner: task.createdById,
    duration: task.duration,
    completion: (task.completion || 0).toFixed(0),
    predecessors: task.predecessors || [],
    successors: task.successors || [],
    expanded: task.parentModel !== 'task', // task.model === 'summary_task',
    // summaryTaskId: task.summaryTaskId || 0,
    taskParentId: task.parentId || 0,
    taskParentModel: task.parentModel,
    color: task.model === 'summary_task' ? '#565656' : task.colourCode,
    estimatedTime: task.estimatedTime,
    estimatedTimeUnit: task.estimatedTimeUnit,
    moduleName: Constants.PROJECT,
    fieldValueDetails: task.fieldValueDetails,
    ...(task.fieldValueDetails || {}),
  }
}

export function transformPlanningTaskForRecursive(task) {
  return {
    ...transformPlanningTaskForList(task),
    id: task.taskId || generateId(),
  }
}

export function transformPlanningTaskRecursive(task) {
  const flatTasks = flattenRecursive(task, 'objectList')
  const hierarchyTasks = buildHierarchy(flatTasks)
  return transformRecursive(
    hierarchyTasks,
    transformPlanningTaskForRecursive,
    'children',
    'objOrder',
    null
  )
}

export function transformPlanningTask(task) {
  return {
    children: [],
    id: task.id,
    name: task.name,
    subject: task.subject,
    moduleName: task.refModel,
    ...(task.refId ? { parentId: task.refId } : {}),
    ...(task.reference ? { reference: task.reference } : {}),
    statusId: task.statusId,
    technicianId: task.technicianId,
    groupId: task.groupId,
    description: task.description,
    fileAttachments: (task.fileAttachments || []).map(transformAttachment),
    type: task.model,
    taskTypeId: task.taskTypeId,
    priorityId: task.priorityId,
    timeRange: {
      startTime: task.startTime,
      endTime: task.endTime,
    },
    startTime: task.startTime,
    endTime: task.endTime,
    order: task.objOrder,
    notifyBeforeHours: task.notifyBeforeHours,
    notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit,
    createdAt: task.createdTime,
    owner: task.createdById,
    duration: task.duration,
    completion: (task.completion || 0).toFixed(0),
    predecessors: task.predecessors || [],
    successors: task.successors || [],
    // summaryTaskId: task.summaryTaskId || 0,
    taskParentId: task.parentId || 0,
    taskParentModel: task.parentModel,
    color: task.colourCode,
    estimatedTime: task.estimatedTime,
    estimatedTimeUnit: task.estimatedTimeUnit,
    fieldValueDetails: task.fieldValueDetails,
    ...(task.fieldValueDetails || {}),
  }
}

export function transformPlanningTaskForServer(task) {
  const obj = {
    ...(task.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...task.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(task).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = task[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: task[paramName],
        }
      }
    }
  })
  return {
    subject: task.subject,
    groupId: task.groupId,
    statusId: task.statusId,
    technicianId: task.technicianId,
    ...('description' in task ? { description: task.description || '' } : {}),
    ...(task.fileAttachments
      ? {
          fileAttachments: (task.fileAttachments || []).map(
            transformAttachmentForServer
          ),
        }
      : {}),
    taskDependencyStage: task.stage,
    // objOrder: task.order, // removed in new fixed
    taskTypeId: task.taskTypeId,
    priorityId: task.priorityId,
    startTime: task.startTime || (task.timeRange || {}).startTime,
    endTime: task.endTime || (task.timeRange || {}).endTime,
    notifyBeforeHours: task.notifyBeforeHours,
    notifyBeforeHoursTimeUnit: task.notifyBeforeHoursTimeUnit,
    ...(task.duration ? { duration: +task.duration } : {}),
    ...(task.completion ? { completion: +task.completion } : {}),
    predecessors: task.predecessors,
    successors: task.successors,
    // summaryTaskId: task.summaryTaskId || 0,
    parentId: task.taskParentId || 0,
    parentModel: task.taskParentModel,
    colourCode: task.color,
    ...obj,
  }
}
export function transformPlanningTaskForBulkUpdate(task) {
  return {
    id: task.taskId,
    name: task.name,
    subject: task.subject,
    parentId: task.taskParentId || 0,
    // objOrder: task.order,
    parentModel: task.taskParentModel,
    model: task.type,
  }
}

const milestoneQuery = (value) =>
  buildRelationalQualificationStructure(
    'taskTypeId',
    'equal',
    value,
    'long',
    'db'
  )

const taskQuery = (value) =>
  buildRelationalQualificationStructure(
    'taskTypeId',
    'not_equal',
    value,
    'long',
    'db'
  )

const unassignedQuery = () =>
  buildRelationalQualificationStructure(
    'technicianId',
    'equal',
    0,
    'long',
    'db'
  )

const overdueQuery = () => {
  const qual = [
    transformBinaryQualForServer(
      buildRelationalQualificationStructure(
        'endTime',
        'not_equal',
        0,
        'long',
        'db'
      ),
      buildRelationalQualificationStructure(
        'endTime',
        'less_than',
        Moment().unix() * 1000,
        'long',
        'db'
      ),
      'and'
    ),
  ]
  return qual
}

const buildQualifications = (filters) => {
  let quals = []
  if (filters.filter) {
    quals.push(
      buildRelationalQualificationStructure(
        `${filters.moduleName}.task.subject`,
        'contains',
        filters.filter
      )
    )
  }
  if (filters.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('milestone' in filters && filters.milestoneId) {
    quals.push(milestoneQuery(filters.milestoneId))
  }
  if ('task' in filters && filters.milestoneId) {
    quals.push(taskQuery(filters.milestoneId))
  }
  if ('unassigned' in filters) {
    quals.push(unassignedQuery())
  }
  if ('overdue' in filters) {
    quals = quals.concat(overdueQuery())
  }
  if (quals.length) {
    return {
      qualDetails: buildFlatQualificationStructure(quals),
    }
  }
  return {}
}

export function getPlanningTasksApi(
  { moduleName, ...rest },
  id,
  pageSize,
  offset
) {
  return api
    .post(
      `${moduleName}/${id}/plannigtasks`,
      buildQualifications({ moduleName, ...rest }),
      {
        params: {
          offset: offset || 0,
          size: pageSize || 1000,
          ...(rest.sortBy ? { sort_by: rest.sortBy } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      const hierarchy = transformPlanningTaskRecursive(
        SortBy(data || [], 'objOrder')
      )
      return {
        hierarchy: rest.serchable
          ? hierarchy.filter(
              (h) => h.type === 'task' || (h.children || []).length
            )
          : hierarchy,
        items: flattenRecursive(hierarchy),
      }
    })
}

export function createTaskApi(moduleName, refId, task) {
  return api
    .post(
      `/${moduleName}/${refId}/task`,
      transformPlanningTaskForServer(task),
      {
        message: __t('created_successfully', { resource: __tc('task') }),
      }
    )
    .then((data) => transformPlanningTask(data))
}

export function updateTaskApi(moduleName, refId, { id, ...task }) {
  return api
    .patch(
      `${moduleName}/${refId}/task/${id}`,
      transformPlanningTaskForServer(task),
      {
        message: __t('updated_successfully', { resource: __tc('task') }),
      }
    )
    .then((data) => transformPlanningTask(data))
}

export function getTaskApi(moduleName, refId, id) {
  return api
    .get(`${moduleName}/${refId}/task/${id}`)
    .then((data) => transformPlanningTask(data))
}

export function deleteTaskApi(moduleName, refId, { id }) {
  return api.delete(`${moduleName}/${refId}/task/${id}`, {
    message: __t('deleted_successfully', { resource: __tc('task') }),
  })
}

export function updateTasksApi(moduleName, refId, data) {
  const payload = {}
  data.forEach((i, index) => {
    payload[i.taskId] = {
      objOrder: index + 1,
      // summaryTaskId: i.summaryTaskId,
      parentId: i.taskParentId || 0,
      parentModel: i.taskParentModel,
    }
  })
  return api.patch(`${moduleName}/${refId}/task/bulk/update`, payload, {
    message: __t('updated_successfully', {
      resource: __tc('task', Object.keys(data).length),
    }),
  })
}

export function createSummaryTaskApi(moduleName, refId, task) {
  return api
    .post(
      `${moduleName}/${refId}/summarytask`,
      { name: task.name },
      {
        message: __t('created_successfully', {
          resource: `${__tc('summary')} ${__tc('task')}`,
        }),
      }
    )
    .then((data) => transformPlanningTask(data))
}

export function updateSummaryTaskApi(moduleName, refId, { id, ...task }) {
  return api
    .patch(
      `${moduleName}/${refId}/summarytask/${id}`,
      { name: task.name },
      {
        message: __t('updated_successfully', {
          resource: `${__tc('summary')} ${__tc('task')}`,
        }),
      }
    )
    .then((data) => transformPlanningTask(data))
}

export function deleteSummaryTaskApi(moduleName, refId, { id }) {
  return api.delete(`${moduleName}/${refId}/summarytask/${id}`, {
    message: __t('deleted_successfully', {
      resource: `${__tc('summary')} ${__tc('task')}`,
    }),
  })
}

export function getTasksApi({ moduleName, ...rest }, id, pageSize, offset) {
  return api
    .post(
      `${moduleName}/${id}/task/search/byqual`,
      buildQualifications({ moduleName, ...rest }),
      {
        params: {
          offset: offset || 0,
          size: pageSize || 1000,
          ...(rest.sortBy ? { sort_by: rest.sortBy } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformPlanningTaskForList),
        total: data.totalCount,
      }
    })
}

export function projectTasksBulkUpdateApi(id, tasks, notify = true) {
  const data = transformRecursive(
    tasks,
    transformPlanningTaskForBulkUpdate,
    'children',
    'order',
    null,
    0,
    'objectList'
  )
  // return Promise.resolve(data)
  return api.patch(`project/${id}/planningtasks/bulk/update`, data, {
    notify,
    message: __t('updated_successfully', {
      resource: __tc('task', 1),
    }),
  })
}

import api from '@api'

import {
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformCostCenterForServer,
  transformCostCenterForClient,
} from '@data/purchase-management'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getCostCentersApi(filter, limit, offset, sortedColumn) {
  return api
    .post(
      `/purchase/costcenter/search/byqual`,
      {
        qualDetails: filter.name
          ? buildFlatQualificationStructure([
              buildRelationalQualificationStructure(
                'name',
                'contains',
                filter.name,
                'string',
                'db'
              ),
            ])
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformCostCenterForClient),
        total: data.totalCount,
      }
    })
}

export function createCostCenterApi(costcenter) {
  return api
    .post(`/purchase/costcenter`, transformCostCenterForServer(costcenter), {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('cost_center')}`,
      }),
    })
    .then(transformCostCenterForClient)
}

export function deleteCostCenterApi(id) {
  return api.delete(`/purchase/costcenter/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('cost_center')}`,
    }),
  })
}

export function updateCostCenterApi(costcenter) {
  return api
    .patch(
      `/purchase/costcenter/${costcenter.id}`,
      transformCostCenterForServer(costcenter),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc('cost_center')}`,
        }),
      }
    )
    .then(transformCostCenterForClient)
}

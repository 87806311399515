<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { ProjectRiskTypeMethods } from '@state/modules/project-risk-type'
import { ProjectTypeMethods } from '@state/modules/project-type'
import { PreferenceComputed } from '@state/modules/preference'
// import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'ProjectManagementModule',
  page() {
    return {
      title: this.$t('Project Management'),
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name && from.name.indexOf('risk-type') >= 0) {
      this.fetchProjectRiskTypes()
    }
    if (from.name && from.name.indexOf('project-type') >= 0) {
      this.fetchProjectTypes()
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    // ...LicenseComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.project_management') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  // TO DO: Will do in pre-7.5
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (vm.availableModulesInLicense.indexOf(vm.$constants.PROJECT) === -1) {
  //       return vm.$router.replace({ name: 'upgrade-plan' })
  //     }
  //     if (vm.myAllowedModules.indexOf(vm.$constants.PROJECT) === -1) {
  //       return vm.$router.replace({ name: '404' })
  //     }
  //   })
  // },
  methods: {
    ...ProjectRiskTypeMethods,
    ...ProjectTypeMethods,
  },
}
</script>

import Moment from 'moment'
import Highcharts from 'highcharts'
// Utility functions
const dateFormat = Highcharts.dateFormat
const defined = Highcharts.defined
const isObject = Highcharts.isObject
const reduce = Highcharts.reduce

function isSummaryTask(item) {
  return item.type === 'summary_task'
}

/**
 * @description
 * Process a task item and return the data for the gantt chart.
 *
 * @param {Object} item - The task item.
 * @param {Array} technicianOptions - The options for the technician picker.
 * @param {Number} milestoneId - The ID of the milestone task type.
 * @param {String} [parentId] - The parent ID of the task.
 * @return {Array} The data for the gantt chart.
 */
function processTask(item, technicianOptions, milestoneId, parentId = null) {
  const isMilestone = item.taskTypeId === milestoneId
  const taskData = {
    name: isSummaryTask(item)
      ? item.name
      : `${item.name}: ${item.subject} (${
          (technicianOptions.find((o) => o.key === item.technicianId) || {})
            .name
        })`,
    assignee: (technicianOptions.find((o) => o.key === item.technicianId) || {})
      .name,
    id: isSummaryTask(item) ? `summary-${item.id}` : `task-${item.taskId}`,
    taskId: item.taskId,
    type: item.type,
    parent: parentId,
    start: (item.timeRange || {}).startTime,
    end: (item.timeRange || {}).endTime,
    completed: !isMilestone
      ? {
          amount: item.completion / 100,
        }
      : undefined,
    color:
      Moment().unix() * 1000 - (item.timeRange || {}).endTime > 0
        ? '#ff0000'
        : item.color,
    milestone: isMilestone,
    dependency: (item.predecessors || []).map((i) => `task-${i}`),
    task: {
      ...item,
    },
    overdue: Moment().unix() * 1000 - (item.timeRange || {}).endTime > 0,
  }

  let seriesData = [taskData]

  if (item.children && item.children.length > 0) {
    item.children.forEach((child) => {
      const childData = processTask(
        child,
        technicianOptions,
        milestoneId,
        taskData.id
      )
      seriesData = [...seriesData, ...childData]
    })
  }

  return seriesData
}

export default function transformDataToGanttChart(
  data,
  resource,
  milestoneId,
  technicianOptions,
  callBackFn
) {
  let seriesData = []
  data.forEach((item) => {
    const taskWithChildren = processTask(item, technicianOptions, milestoneId)
    seriesData = [...seriesData, ...taskWithChildren]
  })
  const config = {
    // title: {
    //   text: `Gantt View - ${resource.name}: ${resource.displayName}`,
    // },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: true,
      liveRedraw: true,
      series: {
        type: 'gantt',
        pointPlacement: 0.5,
        pointPadding: 0.25,
      },
      yAxis: {
        min: 0,
        max: 3,
        reversed: true,
        categories: [],
      },
    },
    scrollbar: {
      enabled: true,
    },
    rangeSelector: {
      enabled: true,
      selected: 5,
    },
    xAxis: {
      currentDateIndicator: true,
      min: resource.startDate,
      max: resource.endDate,
    },
    series: [
      {
        name: `${resource.name}: ${resource.displayName}`,
        enableMouseTracking: true,
        events: {
          click: (event) => {
            callBackFn(event)
          },
        },
        className: 'cursor-pointer',
        data: seriesData,
        tooltip: {
          pointFormatter: function () {
            var point = this
            var format = '%d/%m/%Y %H:%M:%S'
            var options = point.options
            var completed = options.completed
            var amount = isObject(completed) ? completed.amount : completed
            var status = ((amount || 0) * 100).toFixed(0) + '%'
            var lines

            lines = [
              {
                value: point.name,
                style: 'font-weight: bold;',
              },
              {
                title: 'Start',
                value: dateFormat(format, point.start),
              },
              {
                title: 'End',
                value: dateFormat(format, point.end),
              },
              {
                visible: !options.milestone,
                title: 'Completed',
                value: status,
              },
              {
                title: 'Assignee',
                value: options.assignee || 'unassigned',
              },
              {
                visible: point.overdue,
                title: 'Overdue',
                value: 'True',
                style: 'color: red',
              },
            ]

            return reduce(
              lines,
              function (str, line) {
                var s = ''
                var style = defined(line.style)
                  ? line.style
                  : 'font-size: 0.8em;'
                if (line.visible !== false) {
                  s =
                    '<span style="' +
                    style +
                    '">' +
                    (defined(line.title) ? line.title + ': ' : '') +
                    (defined(line.value) ? line.value : '') +
                    '</span><br/>'
                }
                return str + s
              },
              ''
            )
          },
        },
      },
    ],
  }
  return config
}

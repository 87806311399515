<template>
  <a @click="triggerAction">
    <MIcon name="file-archive" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $t('archive') }}
    </span>
    <FlotoConfirmModal
      :open="showModal"
      prevent-auto-close-on-confirm
      :processing="processing"
      @hide="showModal = false"
      @confirm="handleConfirmAction"
    >
      <template v-slot:icon>
        <MIcon name="file-archive" class="w-full text-primary" size="2x" />
      </template>
      <template v-slot:message>
        {{
          $t('confirm_archive', {
            resource: $tc(moduleName),
          })
        }}
      </template>
    </FlotoConfirmModal>
  </a>
</template>

<script>
import Bus from '@utils/emitter'

export default {
  name: 'Archive',
  props: {
    moduleName: { type: String, required: true },
    bindShortcut: { type: Boolean, default: false },
    handleArchiveItems: { type: Function, default: undefined },
  },
  data() {
    return {
      showModal: false,
      processing: false,
    }
  },
  created() {
    if (this.bindShortcut) {
      const archiveHandler = (e) => {
        this.triggerAction()
      }
      Bus.$on('archive', archiveHandler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('archive', archiveHandler)
      })
    }
  },
  methods: {
    handleConfirmAction() {
      this.$emit('done')
      if (this.handleArchiveItems) {
        this.processing = true
        this.handleArchiveItems()
          .then(() => {
            this.showModal = false
          })
          .finally(() => {
            this.showModal = false
            this.processing = false
          })
      } else {
        this.showModal = false
      }
    },
    triggerAction() {
      this.$emit('trigger')
      // show modal after dropdown is hide
      setTimeout(() => {
        this.showModal = true
      }, 400)
    },
  },
}
</script>

import api from '@api'

import {
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformGlCodeForServer,
  transformGlCodeForClient,
} from '@data/purchase-management'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getGlCodesApi(filter, limit, offset, sortedColumn) {
  return api
    .post(
      `/purchase/glcode/search/byqual`,
      {
        qualDetails: filter.glCode
          ? buildFlatQualificationStructure([
              buildRelationalQualificationStructure(
                'glCode',
                'contains',
                filter.glCode,
                'string',
                'db'
              ),
            ])
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformGlCodeForClient),
        total: data.totalCount,
      }
    })
}

export function createGlCodeApi(glCode) {
  return api
    .post(`/purchase/glcode`, transformGlCodeForServer(glCode), {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('gl_code')}`,
      }),
    })
    .then(transformGlCodeForClient)
}

export function deleteGlCodeApi(id) {
  return api.delete(`/purchase/glcode/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('gl_code')}`,
    }),
  })
}

export function updateGlCodeApi(glCode) {
  return api
    .patch(`/purchase/glcode/${glCode.id}`, transformGlCodeForServer(glCode), {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('gl_code')}`,
      }),
    })
    .then(transformGlCodeForClient)
}

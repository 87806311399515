<template>
  <div class="flex flex-col">
    <Component
      :is="stickySearch ? 'MAffix' : 'div'"
      v-if="canManagePlanning"
      :offset-top="offsetTop"
      @change="searchAffixed = $event"
    >
      <div :class="{ 'pt-2': searchAffixed }">
        <div class="text-right">
          <FlotoLink
            :to="
              $modules.getModuleRoute('project', 'edit', {
                params: { id: resourceId },
              })
            "
          >
            <MButton id="edit-planning-btn"
              >{{ $tc('edit') }} {{ $tc('planning') }}</MButton
            >
          </FlotoLink>
          <MDivider />
        </div>
      </div>
    </Component>
    <MTab v-model="currentTab" position="left" class="flex-tab">
      <MTabPane
        key="list"
        :tab="`${$tc('list')} ${$tc('view')}`"
        class="flex flex-col h-100"
      >
        <div
          v-if="currentTab === 'list'"
          class="w-full flex-1 min-h-full flex flex-col"
        >
          <div class="flex flex-col flex-1">
            <ProjectTaskContainer
              :key="currentTab"
              disabled
              :resource-id="resourceId"
              :resource="resource"
            />
          </div>
        </div>
      </MTabPane>
      <MTabPane
        key="gantt"
        :tab="`${$tc('gantt')} ${$tc('view')}`"
        class="flex flex-col h-100"
      >
        <div
          v-if="currentTab === 'gantt'"
          class="w-full flex-1 min-h-full flex flex-col"
        >
          <FlotoScrollView>
            <div class="flex flex-col flex-1">
              <GanttView :resource-id="resourceId" :resource="resource" />
            </div>
          </FlotoScrollView>
        </div>
      </MTabPane>
    </MTab>
  </div>
</template>

<script>
import ProjectTaskContainer from '../project-task/project-task-container'
import { PreferenceComputed } from '@state/modules/preference'
import GanttView from './gantt-view'
export default {
  name: 'PlanningTab',
  components: { ProjectTaskContainer, GanttView },
  props: {
    resourceId: { type: [String, Number], required: true },
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line
    stickySearch: { type: Boolean, default: true },
    offsetTop: { type: Number, default: 0 },
  },
  data() {
    return {
      currentTab: 'list',
      searchAffixed: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    canManagePlanning() {
      return (
        this.myAllowedModules.indexOf('project.manage_planning') >= 0 &&
        this.resource.projectStatus !== 'cancelled' &&
        this.resource.projectStatus !== 'closed'
      )
    },
  },
}
</script>

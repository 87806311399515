import { getRootPluaralTranslator } from '@utils/get-module-translator'
import { colors } from '@utils/color'
const $tc = getRootPluaralTranslator()

export default function projectStatus() {
  return [
    {
      id: 'open',
      key: 'open',
      text: $tc('open'),
      color: colors['secondary-yellow'],
    },
    {
      id: 'planning',
      key: 'planning',
      text: $tc('planning'),
      color: colors.primary,
    },
    {
      id: 'implementation',
      key: 'implementation',
      text: $tc('implementation'),
      color: colors.success,
    },
    {
      id: 'cancelled',
      key: 'cancelled',
      text: $tc('cancelled'),
      color: colors.error,
    },
    { id: 'closed', key: 'closed', text: $tc('closed'), color: colors.success },
  ]
}

<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'

export default {
  name: 'PurchaseManagementModule',
  page() {
    return {
      title: this.$t('Purchase Management'),
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  computed: {
    ...PreferenceComputed,
    ...LicenseComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.purchase_management') === -1) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.availableModulesInLicense.indexOf(this.$constants.PURCHASE) === -1
    ) {
      return this.$router.replace({ name: 'upgrade-plan' })
    }
    this.hasPermission = true
  },
}
</script>

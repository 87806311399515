<template>
  <div
    class="flex flex-1 items-center min-w-0"
    :class="{ 'cursor-pointer': $listeners.click }"
    style="user-select: none"
    @click="$emit('click')"
  >
    <div class="flex flex-1 justify-between">
      <div class="flex flex-1 items-center">
        <ColorPicker
          v-if="item.type !== 'summary_task' && !isMilestone"
          :disabled="disabled || isMilestone"
          :value="item.color"
          class="mx-1"
          @change="
            $emit('update', {
              type: item.type,
              taskId: item.taskId,
              taskParentId: item.taskParentId,
              taskParentModel: item.taskParentModel,
              color: $event,
            })
          "
        />
        <MTooltip v-if="isMilestone">
          <template v-slot:trigger>
            <MIcon
              name="diamond"
              size="2x"
              class="mx-1"
              style="color: #ffbf00"
            />
          </template>
          {{ $tc('milestone') }}
        </MTooltip>
        <FlotoUserAvatar
          v-if="item.type !== 'summary_task'"
          show-tooltip
          :size="35"
          class="mx-1 mt-1"
          style="min-width: 35px"
          :user-id="item.technicianId"
          :default-text="$tc('unassigned')"
        />
        <div class="flex flex-1 flex-col">
          <div class="flex items-center mb-1 text-black">
            <template v-if="item.type === 'summary_task'">
              <b class="mr-2">Summary Task: </b> {{ item.name }}
            </template>
            <template v-else>
              <FlotoTaskDrawer
                :disabled="disabled"
                :default-task="{ ...item, id: item.taskId }"
                @update="($event) => handleTaskDetailUpdate($event, item)"
              >
                <template v-slot:trigger="{ open }">
                  <a @click="open"> {{ item.name }}: {{ item.subject }} </a>
                </template>
              </FlotoTaskDrawer>
            </template>
          </div>
          <div class="pl-1">
            <small class="text-neutral">
              <MTooltip class="mr-2">
                <template v-slot:trigger>
                  <MIcon
                    name="calendar-alt"
                    class="mr-1 text-neutral-light md-text-base"
                  />
                  <template
                    v-if="
                      (item.timeRange || {}).startTime ||
                      (item.timeRange || {}).endTime
                    "
                  >
                    <span>{{
                      (item.timeRange || {}).startTime
                        | datetime('DD/MM/YYYY hh:mm A')
                    }}</span>
                    <span
                      v-if="
                        (item.timeRange || {}).startTime &&
                        (item.timeRange || {}).endTime
                      "
                      class="text-center my-1"
                    >
                      -
                    </span>
                    <span>{{
                      item.timeRange.endTime | datetime('DD/MM/YYYY hh:mm A')
                    }}</span>
                  </template>
                  <template v-else> --- </template>
                </template>
                {{ $t('start') }} {{ $t('date') }} - {{ $t('end') }}
                {{ $t('date') }}
              </MTooltip>
              <MTooltip class="mr-2">
                <template v-slot:trigger>
                  <MIcon
                    name="clock"
                    class="mr-1 text-neutral-light md-text-base"
                  />
                  <template v-if="item.duration">
                    <span>
                      {{ item.duration }} {{ $tc('days', item.duration) }}
                    </span>
                  </template>
                  <template v-else> --- </template>
                </template>
                {{ $t('duration') }} ({{ $tc('days', 2) }})
              </MTooltip>
              <MTooltip v-if="item.type !== 'summary_task'" class="mr-2">
                <template v-slot:trigger>
                  <MIcon
                    name="hourglass"
                    class="mr-1 text-neutral-light md-text-base"
                  />
                  <template v-if="item.estimatedTime">
                    <span>
                      {{ item.estimatedTime }} {{ item.estimatedTimeUnit }}
                    </span>
                  </template>
                  <template v-else> --- </template>
                </template>
                {{ $t('estimates') }}
              </MTooltip>
              <MTooltip
                v-if="
                  item.type !== 'summary_task' &&
                  (overDue !== false || dueIn !== false)
                "
              >
                <template v-slot:trigger>
                  <MIcon
                    size="lg"
                    name="clock"
                    class="mr-2"
                    :class="{
                      'text-secondary-red': overDue,
                      'text-secondary-green': !overDue,
                    }"
                  />
                  <span class="text-secondary-red">{{
                    overDue ? $tc('overdue') : ''
                  }}</span>
                </template>
                {{ overDue || dueIn }} <br />
                ({{ item.timeRange.endTime | datetime }})
              </MTooltip>
            </small>
          </div>
        </div>
      </div>
      <div class="actions flex items-center">
        <div class="flex-1 items-center">
          <DependencyViewDrawer
            v-if="item.predecessors.length || item.successors.length"
            class="mr-1"
            :task="item"
            :tasks="tasks"
            :resource-id="resourceId"
            @change="handleDependencyGraphUpdate"
          />
          <MTooltip v-if="!disabled">
            <template v-slot:trigger>
              <MIcon
                name="pencil"
                class="mr-2 cursor-pointer text-neutral-light"
                @click="$emit('edit', item)"
              />
            </template>
            {{ $t('edit') }}
          </MTooltip>
          <FlotoDeleteBtn
            v-if="!disabled && allowUserToDeleteTasks"
            :message="
              item.type === 'summary_task' || (item.children || []).length > 0
                ? $t('confirm_remove_task', {
                    type: `${$tc(isMilestone ? 'milestone' : item.type)}`,
                  })
                : $t('confirm_remove_item', {
                    item: `${$tc(isMilestone ? 'milestone' : item.type)}`,
                  })
            "
            @confirm="$emit('remove', item)"
          >
            <MIcon name="trash-alt" />
          </FlotoDeleteBtn>
        </div>
        <MDropdown v-if="!disabled" ref="dropdownMenu" class="ml-3">
          <template v-slot:trigger>
            <MButton
              style="width: 10px"
              variant="transparent"
              class="text-neutral mr-3"
              :shadow="false"
              shape="circle"
            >
              <MIcon name="ellipsis-v" size="2x" />
            </MButton>
          </template>
          <template v-slot:menu>
            <MMenu class="action-menu">
              <MMenuItem
                v-if="
                  !disabled &&
                  (item.type === 'summary_task' ||
                    item.taskParentModel !== 'task')
                "
              >
                <a
                  @click="
                    () => {
                      $emit('add-action', 'task', item)
                      hide()
                    }
                  "
                >
                  <MIcon name="tasks" class="action-menu-icon" />
                  <span class="ml-1 action-menu-text">
                    {{ $t('add') }}
                    {{ item.type === 'task' ? $tc('sub') : '' }}
                    {{ $tc('task') }}
                  </span>
                </a>
              </MMenuItem>
              <MMenuItem v-if="!disabled && item.type === 'summary_task'">
                <a
                  @click="
                    () => {
                      $emit('add-action', 'milestone', item)
                      hide()
                    }
                  "
                >
                  <MIcon name="diamond" class="action-menu-icon" />
                  <span class="ml-1 action-menu-text">
                    {{ $t('add') }} {{ $tc('milestone') }}
                  </span>
                </a>
              </MMenuItem>
              <MMenuItem v-if="!disabled && item.type !== 'summary_task'">
                <a
                  @click="
                    () => {
                      handleAddDependency('predecessors')
                    }
                  "
                >
                  <MIcon name="stream" class="action-menu-icon" />
                  <span class="ml-1 action-menu-text">
                    {{ $t('add') }} {{ $tc('predecessors') }}
                  </span>
                </a>
              </MMenuItem>
              <MMenuItem v-if="!disabled && item.type !== 'summary_task'">
                <a
                  @click="
                    () => {
                      handleAddDependency('successors')
                    }
                  "
                >
                  <MIcon name="stream" class="action-menu-icon" />
                  <span class="ml-1 action-menu-text">
                    {{ $t('add') }} {{ $tc('successors') }}
                  </span>
                </a>
              </MMenuItem>
            </MMenu>
          </template>
        </MDropdown>
        <div class="flex items-center">
          <MTooltip>
            <template v-slot:trigger>
              <div class="task-item p-1 rounded-full">
                <ProgressViewer :value="item.completion" />
              </div>
            </template>
            {{ $tc('completion') }}
          </MTooltip>
        </div>
      </div>
    </div>
    <FlotoDrawer :open="Boolean(dependency)" @hide="hideDependencyDrawer">
      <template v-slot:title>
        {{ $tc('add') }} {{ $tc('dependency') }} - {{ $tc(dependency) }}
      </template>
      <DependencySelectionList
        v-model="selectedItems"
        :tasks="availableTaskList"
      />
      <template v-slot:actions="{ hide: hideDrawer }">
        <MButton
          id="add-btn"
          outline
          class="mr-2"
          :loading="processing"
          @click="handleSubmitDependency"
        >
          {{ $t('add') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hideDrawer">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </div>
</template>

<script>
import Pick from 'lodash/pick'
import Moment from 'moment'
import { StatusComputed } from '@state/modules/status'
import ColorPicker from '@components/color-picker'
import ProgressViewer from '@components/numeric-slider/progress-viewver'
import DependencySelectionList from './dependency-selection-list'
import DependencyViewDrawer from './dependency-view-drawer'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'ProjectTaskItem',
  components: {
    ProgressViewer,
    ColorPicker,
    DependencySelectionList,
    DependencyViewDrawer,
  },
  model: {
    event: 'change',
  },
  props: {
    item: { type: Object, required: true },
    tasks: { type: Array, required: true },
    canAdd: { type: Boolean, required: true },
    toggleExpand: { type: Function, default: undefined },
    disabled: { type: Boolean, default: false },
    taskTypeOptions: {
      type: Array,
      default() {
        return []
      },
    },
    resourceId: { type: Number, default: undefined },
  },
  data() {
    return {
      dependency: null,
      processing: false,
      selectedItems: [],
    }
  },
  computed: {
    ...StatusComputed,
    ...Pick(PreferenceComputed, 'allowUserToDeleteTasks'),
    summaryTaskIsClosed() {
      if (this.item.type === 'summary_task' && this.item.children.length) {
        let boolean = true
        const closedStatus = this.taskFindStatus('closed')
        if (closedStatus) {
          this.item.children.forEach((i) => {
            boolean = boolean && closedStatus.id === i.statusId
          })
        }
        return boolean
      }
      return false
    },
    isMilestone() {
      const taskTypeId = (
        this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
      ).id
      return taskTypeId === this.item.taskTypeId
    },
    taskList() {
      return this.tasks
        .filter((t) => t.type !== 'summary_task')
        .filter((t) => t.taskId !== this.item.taskId)
    },
    taskListForPredecessors() {
      let successors = []
      this.taskList.forEach((t) => {
        successors = [...successors, ...(t.successors || [])]
      })
      return this.taskList
        .filter(
          (t) =>
            (t.timeRange || {}).endTime <= (this.item.timeRange || {}).startTime
        )
        .filter((t) => successors.indexOf(t.taskId) === -1)
    },
    taskListForSuccessors() {
      let predecessors = []
      this.taskList.forEach((t) => {
        predecessors = [...predecessors, ...(t.predecessors || [])]
      })
      return this.taskList
        .filter(
          (t) =>
            (t.timeRange || {}).startTime >= (this.item.timeRange || {}).endTime
        )
        .filter((t) => predecessors.indexOf(t.taskId) === -1)
    },
    availableTaskList() {
      let tasks = []
      if (this.dependency === 'predecessors') {
        tasks = this.taskListForPredecessors
      }
      if (this.dependency === 'successors') {
        tasks = this.taskListForSuccessors
      }
      return tasks
    },
    isDone() {
      const closedStatus = this.taskFindStatus('closed')
      if (closedStatus) {
        return closedStatus.id === this.item.statusId
      }
      return false
    },
    overDue() {
      if (this.isDone) {
        return false
      }
      if (!(this.item.timeRange || {}).endTime) {
        return false
      }
      const diff = Moment().unix() * 1000 - this.item.timeRange.endTime
      if (diff > 0) {
        return `${this.$t('overdue')} ${this.$t(
          'by'
        )} ${this.$options.filters.duration(diff)}`
      }
      return false
    },
    dueIn() {
      if (this.isDone) {
        return false
      }
      if (!(this.item.timeRange || {}).endTime) {
        return false
      }
      const diff = Math.abs(
        Moment().unix() * 1000 - this.item.timeRange.endTime
      )
      return `${this.$t('due_in')} ${this.$options.filters.duration(diff)}`
    },
  },
  methods: {
    hide() {
      this.$refs.dropdownMenu.hide()
    },
    handleAddDependency(dependency) {
      this.dependency = dependency
      this.selectedItems = this.item[dependency] || []
      this.hide()
    },
    handleSubmitDependency() {
      if (this.dependency === 'predecessors') {
        this.$emit('update', {
          ...this.item,
          type: this.item.type,
          taskId: this.item.taskId,
          predecessors: this.selectedItems,
        })
      }
      if (this.dependency === 'successors') {
        this.$emit('update', {
          ...this.item,
          type: this.item.type,
          taskId: this.item.taskId,
          successors: this.selectedItems,
        })
      }
    },
    hideDependencyDrawer() {
      this.dependency = null
    },
    handleTaskDetailUpdate(event) {
      this.$emit('update', event)
    },
    handleDependencyGraphUpdate(event) {
      this.$emit('update', event)
    },
  },
}
</script>
<style lang="less" scoped>
.task-item {
  color: var(--white-regular);
}
</style>

<template>
  <FlotoFormItem :label="$tc('signing_authority')">
    <div class="flex flex-col flex-1 p-2">
      <MultipleFormItems
        v-model="signingAuthorityMapping"
        button-size="small"
        :show-icon="false"
        as-table
        :max-items="5"
        :disabled="disabled"
        :add-btn-text="`${$tc('add')} ${$tc('signing_authority')}`"
      >
        <template v-slot:add-item="{ add }">
          <div class="mt-2">
            <a class="flex items-center" @click="add">
              <MIcon name="plus-circle" class="mx-1" />
              {{ `${$tc('add')} ${$tc('signing_authority')}` }}
            </a>
          </div>
        </template>
        <template v-slot="{ item, remove }">
          <MRow :gutter="0">
            <MCol :size="6">
              <FlotoFormItemReadOnly
                v-if="disabled"
                :value="item.signingPlace"
                :label="`${$tc('place')}`"
              >
                {{ item.signingPlace || '---' }}
              </FlotoFormItemReadOnly>
              <FlotoFormItem
                v-else
                v-model="item.signingPlace"
                :label="$tc('place')"
                :placeholder="$tc('place')"
                :vid="`${item.guid}-key`"
                :disabled="disabled"
              />
            </MCol>
            <MCol :size="6">
              <FlotoFormItemReadOnly
                v-if="disabled"
                :value="item.signingAuthority"
                :label="`${$tc('signing_authority')}`"
              >
                {{ item.signingAuthority || '---' }}
              </FlotoFormItemReadOnly>
              <div v-else class="flex items-center">
                <FlotoFormItem
                  v-model="item.signingAuthority"
                  class="flex-1 ml-2"
                  :disabled="disabled"
                  :label="$tc('signing_authority')"
                  :vid="`${item.guid}-value`"
                  :placeholder="$tc('signing_authority')"
                />
                <div class="ml-2 mt-2">
                  <a
                    v-if="!disabled && (value || []).length > 1"
                    @click.prevent="remove"
                  >
                    <MTooltip>
                      <template v-slot:trigger>
                        <MIcon name="trash-alt" class="text-secondary-red" />
                      </template>
                      {{ $t('delete') }}
                    </MTooltip>
                  </a>
                </div>
              </div>
            </MCol>
          </MRow>
        </template>
      </MultipleFormItems>
    </div>
  </FlotoFormItem>
</template>

<script>
import MultipleFormItems from '@components/multiple-form-item'

export default {
  name: 'SigningAuthorityMapping',
  components: { MultipleFormItems },
  model: {
    event: 'change',
  },
  props: {
    moduleName: { type: String, default: undefined },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    signingAuthorityMapping: {
      get() {
        return this.value || []
      },
      set(signingAuthorityMapping) {
        return this.$emit('change', signingAuthorityMapping || [])
      },
    },
  },
}
</script>

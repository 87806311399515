<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { authMethods } from '@state/modules/auth'
export default {
  name: 'MyTeamModule',
  page() {
    return {
      title: this.$t('my_team'),
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    this.refreshUser()
    next()
  },
  methods: {
    ...authMethods,
  },
}
</script>

<template>
  <FlotoModule v-if="!loading">
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { authComputed } from '@state/modules/auth'

export default {
  name: 'ProductSetupModule',
  page() {
    return {
      title: 'Setup',
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...authComputed,
  },
  created() {
    setTimeout(() => {
      // const userRole = this.user.roleName
      // if (userRole !== 'admin') {
      //   this.$router.replace({ name: '404' })
      // } else {
      //   this.loading = false
      // }
      this.loading = false
    }, 400)
  },
}
</script>

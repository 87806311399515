import SortBy from 'lodash/sortBy'
import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { transformFolderForServer, transformFolder } from '@data/knowledge'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function searchFoldersApi(
  moduleName,
  { name, selectedName, ids, publicOnly },
  limit,
  offset,
  additionalParams = {}
) {
  return api
    .post(
      `${publicOnly ? '/public' : ''}/${moduleName}/objectGroup/search/byqual`,
      {
        ...(name || selectedName || ids
          ? {
              qualDetails: buildFlatQualificationStructure(
                [
                  name && buildNameFilterQuery(name),
                  selectedName &&
                    selectedName.length &&
                    buildNameFilterQuery(selectedName, 'in'),
                  ids &&
                    ids.length &&
                    buildRelationalQualificationStructure(
                      'id',
                      'in',
                      ids,
                      'long',
                      'db'
                    ),
                ].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: SortBy(data.objectList || [], 'objOrder').map(transformFolder),
        total: data.totalCount,
      }
    })
}

export function addFolderApi(moduleName, data) {
  return api
    .post(`/${moduleName}/objectGroup`, transformFolderForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('folder'),
      }),
    })
    .then(transformFolder)
}

export function removeFolderApi(moduleName, id) {
  return api.delete(`/${moduleName}/objectGroup/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('folder'),
    }),
  })
}

export function updateFolderApi(moduleName, data) {
  return api
    .patch(
      `/${moduleName}/objectGroup/${data.id}`,
      transformFolderForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('folder'),
        }),
      }
    )
    .then(transformFolder)
}

export function updateFoldersApi(moduleName, data) {
  const patchData = {}
  data.map((c) => {
    patchData[c.id] = { objOrder: c.order }
  })
  return api.patch(`${moduleName}/objectGroup/bulk/update`, patchData, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('folder', 2),
    }),
  })
}

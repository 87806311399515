<template>
  <FlotoDropdownPicker
    v-bind="$attrs"
    :value="value"
    :search-fn="searchFolders"
    :disabled="disabled"
    :multiple="multiple"
    as-input
    immediate-search
    :transform-options-fn="transformFolder"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { searchFoldersApi } from './folder/folder-api'
export default {
  name: 'FolderPicker',
  model: { event: 'change' },
  props: {
    value: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    return {
      isDirty: false,
    }
  },
  methods: {
    transformFolder({ items }) {
      return items
        .filter((f) => !f.isTrash)
        .map((f) => ({ text: f.name, key: f.id }))
    },
    searchFolders(name, selectedItems) {
      let selectedItemNames = selectedItems.map((i) => i.text)
      if (this.value && !this.isDirty && !this.immediateSearch) {
        let ids = Array.isArray(this.value) ? this.value : [this.value] || []
        return searchFoldersApi(
          this.$constants.KNOWLEDGE,
          { ids },
          ids.length || 100,
          0,
          { archived: true }
        ).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return searchFoldersApi(this.$constants.KNOWLEDGE, {
        name,
        selectedName: selectedItemNames,
      }).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>

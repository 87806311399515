import api from '@api'
import {
  transformPurchaseForDetail,
  transformPurchaseForServer,
  transformPurchaseForList,
} from '@/src/data/purchase'
import { successToast } from '@motadata/ui'

import {
  getRootTranslator,
  getRootPluaralTranslator,
  // getModuleTranslator,
  // getModulePluaralTranslator,
} from '@/src/utils/get-module-translator'
// import moduleConfig from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
// const __mtc = getModuleTranslator(moduleConfig.translationKey)
// const __mtcp = getModulePluaralTranslator(moduleConfig.translationKey)

export function searchPurchasesApi(
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/purchase/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformPurchaseForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getApi(moduleName, id) {
  return api.get(`${moduleName}/${id}`).then(transformPurchaseForDetail)
}

export function updateApi({ id, ...change }) {
  return api
    .patch(`/purchase/${id}`, transformPurchaseForServer(change), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('purchase', 1),
      }),
    })
    .then(transformPurchaseForDetail)
}

export function createApi(data) {
  return api.post('/purchase', transformPurchaseForServer(data), {
    message: __rootT('added_successfully', {
      resource: __rootTc('purchase', 1),
    }),
  })
}

export function createOrderPOApi(data, id) {
  return api.post(`/purchase/order/${id}`, data, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('purchase', 1),
    }),
  })
}

export function bulkUpdateApi(ids, data) {
  const payload = {
    objectIds: ids,
    updateMap: transformPurchaseForServer(data),
  }
  return api
    .patch(`purchase/bulk/update`, payload, {
      notify: true,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'updated_successfully_multiple'
              : 'updated_successfully',
            {
              resource: __rootTc('purchase', data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function deleteApi(id) {
  return api.delete(`/purchase/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc('purchase'),
    }),
  })
}

export function bulkDeleteApi(ids) {
  return api.delete(`/purchase/bulk/delete`, {
    data: { objectIds: ids },
    message: __rootT('archived_successfully', {
      resource: __rootTc('purchase', ids.length),
    }),
  })
}

export function bulkUnarchiveApi(ids) {
  return api.post(
    `/purchase/bulk/restore`,
    { objectIds: ids },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('purchase', ids.length),
      }),
    }
  )
}

export function updateReceivedItemApi({ id, ...change }) {
  return api
    .patch(`/purchase/item/${id}`, transformPurchaseForServer(change), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('purchase', 1),
      }),
    })
    .then(transformPurchaseForDetail)
}

import api from '@api'
import SortBy from 'lodash/sortBy'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformReasonType,
  transformReasonTypeForServer,
} from '@data/release-reason-type'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualification = ({ name, selectedName, quals }) => {
  return {
    ...(name || selectedName || quals
      ? {
          qualDetails: buildFlatQualificationStructure(
            [
              name && buildNameFilterQuery(name),
              selectedName && buildNameFilterQuery(selectedName),
              ...(quals || []),
            ].filter(Boolean)
          ),
        }
      : {}),
  }
}

// reason type apis

export function getReasonTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset
) {
  return api
    .post(
      '/release/reasontype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformReasonType), total: data.totalCount }
    })
}

export function createReasonTypeApi(data) {
  const updateData = transformReasonTypeForServer(data)
  return api
    .post('/release/reasontype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('release_reason'),
      }),
    })
    .then(transformReasonType)
}

export function removeReasonTypeApi(data) {
  return api.delete(`/release/reasontype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('release_reason'),
    }),
  })
}

export function updateReasonTypeApi(data) {
  const updateData = transformReasonTypeForServer(data)
  return api
    .patch(`/release/reasontype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('release_reason'),
      }),
    })
    .then(transformReasonType)
}

import { Module } from '@plugins/modular'
import Routes from './release-management-routes'
import configs from './config'

class ReleaseManagementModule extends Module {
  /**
   * @constructor
   * @param {[key: string]: string} config
   */
  constructor(config = configs) {
    /** string name this name is used to get module from module manager */
    super(config.name, config)
    this.translationKey = config.translationKey || name
  }
  routes = Routes
}

export default ReleaseManagementModule

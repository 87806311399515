import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      nonLinked: true,
      titleFn(route, translator, pluralTranslator) {
        return `${translator(
          route.params.moduleName || 'request'
        )} ${translator('management')}`
      },
    },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/response-template-container'
            )
          ),
        meta: {
          breadcrumbKey: 'response_template',
        },
      },
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/create')
          ),
        meta: {},
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "admin" */ './views/edit')),
        meta: {},
      },
    ],
  },
]

<template>
  <MRow class="my-5 items-center" :gutter="0">
    <MCol :size="3">
      <FlotoDueDatePicker
        :value="value.endDate"
        disabled
        is-humanize
        :module-name="moduleName"
        :status-id="resource.statusId"
        icon-size="2x"
        as-dropdown
        @change="handleChange({ endDate: $event })"
      >
        <template v-slot:after-text>
          <div v-if="value.startDate && value.endDate" class="w-full">
            <small class="text-neutral-light">
              {{ value.startDate | datetime }} -
              {{ value.endDate | datetime }}
            </small>
          </div>
        </template>
      </FlotoDueDatePicker>
    </MCol>
    <MCol :size="3">
      <FlotoTriggerView
        icon-name="file-contract"
        :lable="`${$tc('milestone', 2)}`"
        :value="value.completedMilestone || 0"
      />
    </MCol>
    <MCol :size="3">
      <FlotoTriggerView
        icon-name="tasks"
        :lable="`${$tc('task', 2)}`"
        :value="value.completedTask || 0"
      />
    </MCol>
    <MCol :size="3">
      <FlotoTriggerView
        icon-name="check-circle"
        :lable="`${$tc('completion', 2)}`"
        :value="value.completedProject || 0"
      />
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'SecondRow',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
  },
  computed: {
    dueByComputed() {
      return Math.abs(this.resource.endDate)
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

<template>
  <FlotoFixedView>
    <FlotoPageHeader
      :title="`${resource.name}: ${resource.displayName}`"
      class="px-4"
      use-divider
    >
      <template v-slot:back-button>
        <slot name="before-title"></slot>
      </template>
      <template v-slot:title-append>
        <MTooltip v-if="!disabled">
          <template v-slot:trigger>
            <MButton
              class="mx-1"
              variant="neutral-light"
              :shadow="false"
              shape="circle"
              @click="handleEditSubject"
            >
              <MIcon name="pencil" />
            </MButton>
          </template>
          {{ $t('edit') }}
        </MTooltip>
      </template>
      <div class="flex justify-between">
        <small class="text-neutral">
          {{ $t('by') }}
          <FlotoUserDrawer :user-id="resource.requesterId" />
          {{ $t('at') }}
          {{ resource.createdAt | datetime }}
          ({{ resource.createdAt | timeago }})
        </small>
      </div>
      <slot name="additional-header-content" />
      <template v-slot:after-title>
        <slot name="actions" />
      </template>
    </FlotoPageHeader>
    <FlotoScrollView>
      <MCol :size="12" class="px-4" :style="defaultStyle">
        <MRow class="flex-no-wrap">
          <MCol
            class="transition-width main-detail-container"
            v-bind="detailSize"
          >
            <slot
              v-if="
                resource.description &&
                (!allowedFields || allowedFields.indexOf('description') >= 0)
              "
              name="description"
            >
              <div>
                <small class="text-neutral-light">
                  {{ $t('description') }}
                </small>
              </div>
              <div class="description my-2 mr-4">
                <FlotoReadMore :height="20 * 3">
                  <FlotoRichEditor :value="resource.description" disabled />
                </FlotoReadMore>
              </div>
            </slot>
            <slot name="primary-row">
              <PrimaryRow
                v-model="primaryInfo"
                :disabled="disabled"
                :module-name="moduleName"
              />
            </slot>
            <slot name="secondary-row">
              <SecondaryRow
                v-model="secondaryInfo"
                :disabled="disabled"
                :module-name="moduleName"
                :resource="resource"
              />
            </slot>
            <slot name="tags-row">
              <TagsRow
                v-model="tagsInfo"
                :disabled="disabled"
                :module-name="moduleName"
                :resource="resource"
                @identifiedAsProblem="$emit('identifiedAsProblem')"
              />
            </slot>
            <slot v-if="allowAttachments" name="attachments">
              <FlotoAttachment
                :disabled="disabled"
                :value="resource.fileAttachments"
                only-list
                @change="updateResource({ fileAttachments: $event })"
              />
            </slot>
            <slot name="additional-fields">
              <AdditionalInfoWithExpand
                :resource="resource"
                :module-name="moduleName"
                :show-no-data="false"
                :disabled="disabled"
                :col-size="additionalInfoColSize"
                :update-fn="handleUpdateMoreDetails"
              />
            </slot>
            <slot />
          </MCol>
          <MCol
            v-if="hasSidebar"
            v-bind="sidebarSize"
            class="border border-neutral-lighter sidebar-border border-b-0 border-t-0 border-solid transition-width py-6 relative pages-inner-right-slide-panel"
            style="min-height: 120px"
          >
            <slot v-if="sidebarVisible" name="sidebar">
              <div class="overflow-x-hidden">
                <Sidebar
                  v-model="sidebarInfo"
                  :disabled="disabled"
                  :module-name="moduleName"
                />
              </div>
            </slot>
            <div class="toggle">
              <a
                href="javascript:;"
                class="text-neutral hover:text-neutral"
                @click="sidebarVisible = !sidebarVisible"
              >
                <MIcon
                  class="toggle-icon"
                  :name="`chevron-${isRtl ? sidebarRtl : sidebarMain}`"
                />
              </a>
            </div>
          </MCol>
        </MRow>
      </MCol>
    </FlotoScrollView>
    <FlotoDrawerForm
      v-if="!disabled"
      :open="isEditingSubject"
      @cancel="isEditingSubject = false"
      @submit="handleUpdateSubject"
    >
      <template v-slot:header> {{ $t('edit') }}: {{ resource.name }} </template>
      <FlotoFormItem
        id="display-name-input"
        v-model="internalValueModel.displayName"
        :placeholder="$t('name')"
        :label="$t('name')"
        rules="required|max:100"
      />
      <FlotoFormItem
        v-if="!allowedFields || allowedFields.indexOf('description') >= 0"
        id="description-input"
        :label="descriptionLabel"
        rules="description"
      >
        <FlotoRichEditor
          v-model="internalValueModel.description"
          :placeholder="descriptionLabel"
          :affix-toolbar="false"
        />
      </FlotoFormItem>
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            id="start-date-picker"
            :label="`${$tc('project')} ${$t('start')} ${$t('date')}`"
          >
            <FlotoDatePicker
              v-model="internalValueModel.startDate"
              :disabled="isProjectPlanning || isProjectImplementation"
              :max-date="internalValueModel.endDate"
              :show-time="true"
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="6">
          <FlotoFormItem
            id="end-date-picker"
            :label="`${$tc('project')} ${$t('end')} ${$t('date')}`"
          >
            <FlotoDatePicker
              v-model="internalValueModel.endDate"
              :disabled="isProjectPlanning || isProjectImplementation"
              :min-date="internalValueModel.startDate"
              :show-time="true"
              end-of-day
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <FlotoFormItem
        v-if="allowAttachments"
        id="file-attachments-input"
        :label="$tc('attachment', 2)"
      >
        <FlotoAttachment
          v-model="internalValueModel.fileAttachments"
          @upload-start="updatingSubject = true"
          @upload-done="updatingSubject = false"
        />
      </FlotoFormItem>
      <template v-slot:actions="{ submit, hide }">
        <MButton id="update-btn" :loading="updatingSubject" @click="submit">
          {{ $t('update') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" class="ml-2" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </FlotoFixedView>
</template>

<script>
import IsEqual from 'lodash/isEqual'
import Pick from 'lodash/pick'
import { authComputed } from '@state/modules/auth'
import AdditionalInfoWithExpand from '@components/additional-information/additional-info-expandable'
import { OrganizationComputed } from '@state/modules/organization'
import PrimaryRow from './primary-row'
import SecondaryRow from './secondary-row'
import TagsRow from './tags-row'
import Sidebar from './sidebar'

export default {
  name: 'ProjectDetail',
  components: {
    PrimaryRow,
    SecondaryRow,
    TagsRow,
    Sidebar,
    AdditionalInfoWithExpand,
  },
  props: {
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    // eslint-disable-next-line
    allowAttachments: { type: Boolean, default: true },
    // eslint-disable-next-line
    hasSidebar: { type: Boolean, default: true },
    // eslint-disable-next-line
    moduleName: { type: String, default: 'purchase' },
    updateApi: { type: Function, required: true },
    sidebarWidth: { type: Object, default: () => ({ size: 3 }) },
    detailWidth: { type: Object, default: () => ({ size: 9 }) },
    defaultSidebarVisible: { type: Boolean, default: false },
    allowedFields: { type: Array, default: undefined },
    titleLabel: {
      type: String,
      default: function () {
        return this.$t('name')
      },
    },
    descriptionLabel: {
      type: String,
      default: function () {
        return this.$t('description')
      },
    },
    additionalInfoColSize: { type: Number, default: undefined },
  },
  data() {
    this.defaultStyle = {
      height: `${window.outerHeight}px`,
    }
    this.moduleName = this.$constants.PROJECT
    return {
      isEditingSubject: false,
      sidebarVisible: this.defaultSidebarVisible,
      updatingSubject: false,
      internalValueModel: {},
    }
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    sidebarRtl() {
      return this.sidebarVisible ? 'left' : 'right'
    },
    sidebarMain() {
      return this.sidebarVisible ? 'right' : 'left'
    },
    isProjectPlanning() {
      return this.resource.projectStatus === 'planning'
    },
    isProjectImplementation() {
      return this.resource.projectStatus === 'implementation'
    },
    detailSize() {
      if (this.hasSidebar && this.sidebarVisible) {
        return this.detailWidth
      }
      return { size: 12 }
    },
    sidebarSize() {
      if (this.sidebarVisible) {
        return this.sidebarWidth
      }
      return { size: undefined }
    },
    primaryInfo: {
      get() {
        return Pick(this.resource, [
          'projectStatus',
          'priorityId',
          'projectTypeId',
          'technicianId',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    secondaryInfo: {
      get() {
        return Pick(this.resource, [
          'dueBy',
          'completedMilestone',
          'completedTask',
          'completedProject',
          'endDate',
          'startDate',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    tagsInfo: {
      get() {
        return Pick(this.resource, ['tags'])
      },
      set(value) {
        this.updateResource(value)
      },
    },
    sidebarInfo: {
      get() {
        return Pick(this.resource, [
          'riskTypeId',
          'locationId',
          'vendorId',
          'planningStartDate',
          'implementationStartDate',
          'closedTime',
          'updatedTime',
          'projectSource',
        ])
      },
      set(value) {
        this.updateResource(value)
      },
    },
  },
  methods: {
    handleUpdateSubject() {
      this.updatingSubject = true
      this.updateApi({ id: this.resource.id, ...this.internalValueModel })
        .then(() => {
          this.internalValueModel = {}
          this.isEditingSubject = false
        })
        .finally(() => (this.updatingSubject = false))
    },
    handleEditSubject() {
      this.internalValueModel = Pick(
        this.resource,
        'id',
        'displayName',
        'description',
        'startDate',
        'endDate',
        'fileAttachments'
      )
      this.isEditingSubject = true
    },
    handleUpdateMoreDetails(data) {
      const diff = Object.keys(data).filter(
        (k) => !IsEqual(this.resource.fieldValueDetails[k], data[k])
      )
      return this.updateResource({
        ...Pick(data, diff),
        fieldValueDetails: { ...this.resource.fieldValueDetails },
      })
    },
    updateResource(change) {
      return this.updateApi({ id: this.resource.id, ...change })
    },
    setInternalValue(change) {
      this.internalValueModel = {
        ...this.internalValueModel,
        ...(change || {}),
      }
    },
  },
}
</script>

<style lang="less" scoped>
.sub-heading {
  @apply flex items-center justify-between;
}

.heading {
  @apply items-start flex-wrap flex flex-col;

  .action {
    line-height: 30px;

    @apply ml-2 flex items-center justify-center absolute right-0 top-0;
  }

  .title {
    @apply overflow-hidden relative;
  }
}

.main-detail-container {
  &.@{ant-prefix}-col-12 {
    width: calc(100% - 34px);
  }
}
</style>

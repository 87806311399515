<template>
  <MRow class="px-4">
    <MCol>
      <template v-if="type === 'summary_task'">
        <FlotoFormItem
          id="subject-input"
          v-model="task.name"
          rules="required|min:1 |max:100"
          auto-focus
          :label="$t('subject')"
          :placeholder="$t('subject')"
        />
        <template v-if="task.id">
          <MRow>
            <MCol :sm="6" :size="6">
              <FlotoFormItem
                id="duration-input"
                rules="max_value|min_value:0"
                :label="`${$tc('duration')} (${$tc('days', 2)})`"
              >
                <MInputNumber
                  id="duration-days-input"
                  v-model="task.duration"
                  disabled
                  type="number"
                  :precision="0"
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
          <MRow>
            <MCol :sm="6" :size="6">
              <FlotoFormItem
                id="start-date-picker"
                :label="`${$t('start')} ${$t('date')}`"
              >
                <FlotoDatePicker
                  v-model="(task.timeRange || {}).startTime"
                  disabled
                />
              </FlotoFormItem>
            </MCol>
            <MCol :sm="6" :size="6">
              <FlotoFormItem
                id="end-date-picker"
                :label="`${$t('end')} ${$t('date')}`"
              >
                <FlotoDatePicker
                  v-model="(task.timeRange || {}).endTime"
                  d
                  disabled
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
          <MRow>
            <MCol :sm="12" :size="12">
              <FlotoFormItem
                id="completion-picker"
                rules="max_value:100|min_value:0"
                :label="`${$tc('completion')}`"
              >
                <SliderPicker
                  v-model="task.completion"
                  disabled
                  :min="0"
                  :max="100"
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
        </template>
      </template>
    </MCol>
  </MRow>
</template>

<script>
import Pick from 'lodash/pick'
import { StatusComputed } from '@state/modules/status'
import { PreferenceComputed } from '@state/modules/preference'
import SliderPicker from '@components/numeric-slider/slider-picker'
export default {
  name: 'ProjectTaskForm',
  components: { SliderPicker },
  props: {
    task: { type: Object, required: true },
    fromMyTask: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'task' },
  },
  data() {
    return {
      currentTaskStatusId: 0,
    }
  },
  computed: {
    ...StatusComputed,
    ...Pick(PreferenceComputed, 'allowUserToReopenClosedTasks'),
    isDisabledUserToReopenClosedTasks() {
      const currentTask = this.taskStatus.find(
        (f) => f.key === this.currentTaskStatusId
      )
      if (
        currentTask &&
        !this.allowUserToReopenClosedTasks &&
        (currentTask.systemName || '').toLowerCase() === 'closed'
      ) {
        return true
      }
      return false
    },
  },
  created() {
    this.currentTaskStatusId = this.task.statusId
  },
  methods: {
    resetForm(data) {
      this.$emit('change', data)
    },
  },
}
</script>

import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "patch" */ './views/list')),
        meta: {},
      },
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "patch" */ './views/create')
          ),
        meta: {},
      },
      {
        path: 'auto-patch-test',
        name: `${routeNamePrefix}.auto-patch-test`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "patch" */ './views/auto-patch-test')
          ),
        meta: {},
      },
      {
        path: 'automatic-patch-deployment',
        name: `${routeNamePrefix}.automatic-patch-deployment`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch" */ './views/automatic-patch-deployment/list'
            )
          ),
        meta: {},
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.view`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "contract" */ './views/view')
          ),
      },
    ],
  },
]

<template>
  <div class="flex">
    <div class="background-icon p-2 rounded mr-3">
      <MIcon name="tags" size="lg" class="text-white" />
    </div>
    <FlotoTagsPicker
      :value="value.tags"
      :confirm-before-remove="true"
      variant="neutral-lighter"
      :disabled="disabled"
      @change="handleChange({ tags: $event })"
    />
  </div>
</template>

<script>
export default {
  name: 'TagsRow',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    moduleName: { type: String, required: true },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{attrs:{"as-table":"","prepend-new-item":"","fetch-fn":_vm.getPurchaseItem,"delete-fn":_vm.deleteProductVendor,"update-fn":_vm.updateProductVendor,"columns":_vm.tableColumns,"selectable":_vm.selectable,"form-width":"50%"},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([(_vm.searchable)?{key:"add-controls",fn:function(ref){
var refreshList = ref.refreshList;
return [_c('MRow',{staticClass:"mb-4",attrs:{"gutter":0}},[_c('MCol',{attrs:{"size":6}},[_c('MInput',{staticClass:"search-box",attrs:{"placeholder":_vm.$t('search')},on:{"update":refreshList},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('MIcon',{attrs:{"name":"search"}})]},proxy:true}],null,true),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1)]}}:null,{key:"form",fn:function(){return undefined},proxy:true},{key:"productTypeName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.productTypeName)+" ")])]}},{key:"manufacturerDisplayName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.manufacturerDisplayName)+" ")])]}},{key:"warranty_period",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.year)+" "+_vm._s(_vm.$tc('years'))+" "+_vm._s(item.month)+" "+_vm._s(_vm.$tc('months'))+" ")])]}},(_vm.hasActions)?{key:"actions",fn:function(ref){
var item = ref.item;
var remove = ref.remove;
return [_c('td',{staticClass:"text-ellipsis"},[_c('FlotoDeleteBtn',{class:{ invisible: !item.canDelete },attrs:{"message":_vm.$t('confirm_delete_item', { item: _vm.$tc(_vm.type) })},on:{"confirm":remove}})],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
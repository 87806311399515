// import { getRootPluaralTranslator } from '@utils/get-module-translator'
// const __rootTc = getRootPluaralTranslator()
import Constants from '@constants'

// export const dummyOptions = () => [
//   { key: 'critical', value: 'critical', text: 'Critical' },
// ]
export const remoteDeployStageOptions = () => [
  { text: 'Draft', key: 'draft' },
  { text: 'Ready to Deploy', key: 'ready_to_deploy' },
  { text: 'In Progress', key: 'in_progress' },
  { text: 'Completed', key: 'completed' },
  { text: 'Expired', key: 'expired' },
  { text: 'Cancelled', key: 'cancelled' },
  { text: 'Partial Completed', key: 'partial_completed' },
]

export const configTypeOptions = () => [
  { text: 'Patch', key: 'patch' },
  { text: 'Package', key: 'package' },
]

export const configurationTypeOptions = () => [
  { text: 'Install', key: 'install', value: 'install' },
  { text: 'Uninstall', key: 'uninstall', value: 'uninstall' },
]

export const originOptions = () => [
  {
    text: 'Manual Remote Deploy Request',
    key: 'manual_remote_deploy_request',
    value: 'manual_remote_deploy_request',
  },
  { text: 'Patch Test Task', key: 'patch_test_task', value: 'patch_test_task' },
  {
    text: 'Auto Patch Deploy Task',
    key: 'auto_patch_deploy_task',
    value: 'auto_patch_deploy_task',
  },
]

export const downloadStatusOptions = () => [
  { text: 'Pending', key: 'pending' },
  {
    text: 'In Progress (File Server)',
    key: 'pending_on_fileserver',
  },
  {
    text: 'In Progress (Distribution Server)',
    key: 'pending_on_distribution_server',
  },
  {
    text: 'Success',
    key: 'available',
  },
  {
    text: 'Failed',
    key: 'failed',
  },
  {
    text: 'Cancelled',
    key: 'cancelled',
  },
  {
    text: 'Checksum Failed',
    key: 'checksum_faild',
  },
]

export const remoteDeploymentModuleMap = () => {
  return {
    [Constants.PATCH_REMOTE_DEPLOYMENT]: Constants.PATCH,
    [Constants.PACKAGES_REMOTE_DEPLOYMENT]: Constants.PACKAGES,
    [Constants.REGISTRY_REMOTE_DEPLOYMENT]: Constants.REGISTRY_TEMPLATE,
  }
}

export const remoteDeploymentConfigMap = () => {
  return {
    [Constants.PATCH_REMOTE_DEPLOYMENT]: Constants.PATCH,
    [Constants.PACKAGES_REMOTE_DEPLOYMENT]: Constants.PACKAGES,
    [Constants.REGISTRY_REMOTE_DEPLOYMENT]: Constants.REGISTRY,
  }
}

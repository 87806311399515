<template>
  <FlotoFixedView class="min-h-0 mobile-tab-panel">
    <FlotoContentLoader :loading="!localeLoaded">
      <MRow v-if="!selectedService" class="h-100 master-in-box" :gutter="0">
        <MCol class="h-100 flex flex-col" :sm="4" :size="12">
          <CategorySelector
            v-model="activeCategory"
            disabled
            @categories-loaded="categories = $event"
          />
        </MCol>
        <MCol :sm="8" :size="12" class="left-shadow-only h-100 flex flex-col">
          <div class="px-4 mb-4">
            <h5 class="text-primary">
              {{ $tc('service', 2) }}
            </h5>
            <MRow v-if="activeCategory">
              <MCol>
                <MInput
                  v-model="searchTerm"
                  class="search-box"
                  :placeholder="$t('search')"
                  @update="searchServices"
                >
                  <template v-slot:prefix>
                    <MIcon name="search" />
                  </template>
                </MInput>
              </MCol>
            </MRow>
          </div>
          <template v-if="activeCategory">
            <FlotoCrudContainer
              ref="servicesList"
              :key="activeCategory.id"
              :paging="false"
              :fetch-fn="getServices"
            >
              <template v-slot:list-items="{ items }">
                <MRow v-if="items.length" class="flex-1">
                  <MCol v-for="item in items" :key="item.id" :sm="3" :size="12">
                    <ServiceCatalogItem
                      :service="item"
                      disabled
                      avoid-redirection
                      @service-click="setActiveService"
                    />
                  </MCol>
                </MRow>
              </template>
            </FlotoCrudContainer>
          </template>
          <div v-else class="flex-1 items-center justify-center flex italic">
            {{ $tc('select_category') }}
          </div>
        </MCol>
      </MRow>
      <RequestService
        v-else
        v-model="request"
        :with-submit="false"
        :service-id="selectedService.id"
        @change="$emit('change', $event)"
      >
        <template v-slot:back-button>
          <a
            class="text-neutral-light mr-2 router-link-active cursor-pointer"
            @click="handleBackNavigation"
          >
            <MIcon name="long-arrow-left" />
          </a>
        </template>
      </RequestService>
    </FlotoContentLoader>
  </FlotoFixedView>
</template>

<script>
import { DraftPublishedStatusOptions } from '@utils/status'
import { getServiceCatalogsApi } from '../service-catalog-api'
import CategorySelector from '../components/category-selector'
import ServiceCatalogItem from '../components/service-catalog-item'
import RequestService from './request-service.vue'

export default {
  name: 'ServiceCatalogFormComponent',
  components: { CategorySelector, ServiceCatalogItem, RequestService },
  data() {
    this.categories = []
    this.filterOptions = [{ text: this.$t('all'), key: 'all' }].concat(
      DraftPublishedStatusOptions()
    )
    return {
      activeCategory: { id: 0 },
      searchTerm: null,
      filterType: undefined,
      localeLoaded: true,
      selectedService: undefined,
      request: {},
    }
  },
  // created() {
  //   const serviceCatalogModule = this.$modules.getModule('service-catalog')
  //   if (serviceCatalogModule.getLocaleMessages) {
  //     const currentMessages = this.$i18n.getLocaleMessage(this.$i18n.locale)
  //     // get module key from module
  //     const moduleI18nKey = serviceCatalogModule.getTranslationKey()
  //     // skip merging messages if key is already there
  //     if (!(moduleI18nKey in currentMessages)) {
  //       serviceCatalogModule
  //         .getLocaleMessages(this.$i18n.locale)
  //         .then((messages) => {
  //           this.$i18n.mergeLocaleMessage(this.$i18n.locale, {
  //             [moduleI18nKey]: messages,
  //           })
  //           this.localeLoaded = true
  //         })
  //     } else {
  //       this.localeLoaded = true
  //     }
  //   } else {
  //     this.localeLoaded = true
  //   }
  // },
  methods: {
    handleBackNavigation() {
      this.selectedService = undefined
      this.$emit('reset-form', false)
    },
    setActiveCategory(category) {
      this.activeCategory = category
    },
    getServices(limit, offset) {
      return getServiceCatalogsApi(
        {
          categoryId: this.activeCategory.id,
          name: this.searchTerm,
          isPublic: this.isPortalLogin,
          status: 'published',
        },
        limit,
        offset
      )
    },
    searchServices() {
      this.$refs.servicesList.refresh()
    },
    setActiveService(service) {
      this.request = {
        serviceId: service.id,
      }
      this.$emit('change', this.request)
      this.selectedService = service
    },
  },
}
</script>

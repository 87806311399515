import api from '@api'
import SortBy from 'lodash/sortBy'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformReleaseType,
  transformReleaseTypeForServer,
} from '@data/release-type'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualification = ({ name, selectedName, quals }) => {
  return {
    ...(name || selectedName || quals
      ? {
          qualDetails: buildFlatQualificationStructure(
            [
              name && buildNameFilterQuery(name),
              selectedName && buildNameFilterQuery(selectedName),
              ...(quals || []),
            ].filter(Boolean)
          ),
        }
      : {}),
  }
}

// release type apis

export function getReleaseTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset,
  params = {}
) {
  return api
    .post(
      '/release/releasetype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformReleaseType), total: data.totalCount }
    })
}

export function createReleaseTypeApi(data) {
  const updateData = transformReleaseTypeForServer(data)
  return api
    .post('/release/releasetype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('release_type'),
      }),
    })
    .then(transformReleaseType)
}

export function removeReleaseTypeApi(data) {
  return api.delete(`/release/releasetype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('release_type'),
    }),
  })
}

export function updateReleaseTypeApi(data) {
  const updateData = transformReleaseTypeForServer(data)
  return api
    .patch(`/release/releasetype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('release_type'),
      }),
    })
    .then(transformReleaseType)
}

<template>
  <MRow
    v-if="value.moduleName === $constants.PROJECT"
    class="my-5 items-center"
    :gutter="0"
  >
    <MCol :size="3">
      <FlotoTriggerView
        icon-name="check-circle"
        :lable="`${$t('completion')}`"
        :value="`${value.completion} %`"
      />
    </MCol>
    <MCol :size="3">
      <FlotoTriggerView
        icon-name="clock"
        :lable="`${$t('duration')} (${$tc('days', 2)})`"
        :value="`${value.duration} ${$tc('days', value.duration)}`"
      />
    </MCol>
    <MCol :size="3">
      <FlotoTriggerView
        icon-name="hourglass"
        :lable="`${$t('estimates')} (${$tc('hours', 2)})`"
        :value="
          value.estimatedTime
            ? `${(value.estimatedTime / 60 / 60 / 1000 || 0).toFixed(2)} ${$tc(
                'hours',
                value.duration
              )}`
            : '---'
        "
      />
    </MCol>
  </MRow>
</template>

<script>
export default {
  name: 'TagsRow',
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    isTicketClosed: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

<template>
  <a class="text-neutral-light hover:text-neutral-light" @click="trigger">
    <slot>
      <MTooltip placement="topRight">
        <template v-slot:trigger>
          <MIcon name="tasks" size="lg" class="mr-2" />
        </template>
        {{ $tc('predecessors') }} {{ $tc('and') }} {{ $tc('successors') }}
      </MTooltip>
    </slot>
    <FlotoDrawer
      ref="drawer"
      width="60%"
      :open="isDrawerOpen"
      @hide="cancelAction"
    >
      <template v-slot:title>
        {{ $tc('predecessors') }} {{ $tc('and') }} {{ $tc('successors') }} -
        {{ task.name }}: {{ task.subject }}
      </template>
      <MRow>
        <MCol class="my-2">
          <MTimeline mode="alternate" class="pl-2">
            <MTimelineItem
              v-for="item in availableTasks"
              :key="item.taskId"
              :color="item.timelineColor"
            >
              <MIcon
                v-if="item.taskId === task.taskId"
                slot="dot"
                name="clock"
                style="font-size: 16px"
              />
              <MDivider>
                <div class="text-neutral-dark">
                  <div>
                    <FlotoDot :bg="task.color" />
                    <FlotoTaskDrawer
                      disabled
                      :default-task="{ ...item, id: item.taskId }"
                    >
                      <template v-slot:trigger="{ open }">
                        <a v-if="canDelete" @click="open">
                          {{ item.name }}: {{ item.subject }}
                        </a>
                        <a v-else> {{ item.name }}: {{ item.subject }} </a>
                      </template>
                    </FlotoTaskDrawer>
                    <FlotoDeleteBtn
                      v-if="item.taskId !== task.taskId && canDelete"
                      id="delete-task"
                      :message="
                        $t('confirm_delete_item', { item: `${$tc('item')}` })
                      "
                      class="mx-2"
                      @confirm="handleDeleteItem(item.id, item)"
                    />
                  </div>
                </div>
              </MDivider>
            </MTimelineItem>
          </MTimeline>
        </MCol>
      </MRow>
      <template v-slot:actions="{ hide }">
        <MButton id="done-btn" variant="default" class="ml-2" @click="hide">
          {{ $t('done') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </a>
</template>
<script>
import { getTasksApi } from './api'
export default {
  name: 'DependencyViewDrawer',
  props: {
    task: { type: Object, required: true },
    tasks: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: { type: Boolean, default: false },
    resourceId: { type: Number, default: undefined },
    // eslint-disable-next-line
    canDelete: { type: Boolean, default: true },
  },
  data() {
    this.moduleName = this.$constants.PROJECT
    return {
      isDrawerOpen: false,
      taskList: [...this.tasks],
      loading: true,
    }
  },
  computed: {
    allTasks() {
      return this.taskList
    },
    predecessors() {
      return (this.allTasks || [])
        .filter((t) => this.task.predecessors.indexOf(t.taskId) >= 0)
        .map((i) => ({ ...i, timelineColor: 'green' }))
    },
    successors() {
      return (this.allTasks || [])
        .filter((t) => this.task.successors.indexOf(t.taskId) >= 0)
        .map((i) => ({ ...i, timelineColor: 'red' }))
    },
    availableTasks() {
      const tasks = [
        ...this.predecessors,
        { ...this.task, timelineColor: 'blue' },
        ...this.successors,
      ]
      return tasks
    },
  },
  created() {
    if (this.resourceId) {
      const moduleName = this.moduleName
      const ids = [...this.task.predecessors, ...this.task.successors]
      getTasksApi({ moduleName, ids }, this.resourceId, this.task.taskId).then(
        (data) => {
          this.taskList = (data || {}).items || []
          this.loading = false
        }
      )
    } else {
      this.loading = false
    }
  },
  methods: {
    trigger() {
      if (this.disabled) {
        return
      }
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 400)
    },
    cancelAction() {
      this.isDrawerOpen = false
    },
    handleDeleteItem(id, item) {
      const preparedTask = {
        ...this.task,
        id: this.task.taskId,
        predecessors: this.task.predecessors.filter((f) => f !== item.taskId),
        successors: this.task.successors.filter((f) => f !== item.taskId),
      }
      this.cancelAction()
      this.$emit('change', preparedTask)
    },
  },
}
</script>

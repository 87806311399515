const colors = {
  light_theme: {
    // ---------- primary colors ----------
    primary: '#009ddc',
    'primary-alt': '#3279be',
    'page-text-color': '#364658',
    'page-background-color': '#ffffff',
    // ---------- neutral colors ----------
    'neutral-darkest': '#000000',
    'neutral-darker': '#14212d',
    'neutral-dark': '#364658',
    'neutral-regular': '#7b8fa5',
    'neutral-light': '#a5bad0',
    'neutral-lighter': '#dee5ed',
    'neutral-lightest': '#eef2f6',
    // ---------- secondary colors ----------
    'secondary-green': '#89c540',
    'secondary-yellow': '#f5bc18',
    'secondary-orange': '#f58518',
    'secondary-red': '#f04e3e',
    'secondary-red-dark': '#c84235',
    'secondary-red-light': '#f17a73',
  },
  dark_theme: {
    // ---------- primary colors ----------
    primary: '#009ddc',
    'primary-alt': '#3279be',
    'page-text-color': '#ffffff',
    'page-background-color': 'rgb(30, 30, 30)',
    // ---------- netural colors ----------
    'neutral-darkest': '#000000',
    'neutral-darker': '#14212d',
    'neutral-dark': '#1e1e30',
    'neutral-regular': '#7b8fa5',
    'neutral-light': '#a5bad0',
    'neutral-lighter': '#22223c',
    'neutral-lightest': '#364658',
    // ---------- secondary colors ----------
    'secondary-green': '#89c540',
    'secondary-yellow': '#f5bc18',
    'secondary-orange': '#f58518',
    'secondary-red': '#f04e3e',
    'secondary-red-dark': '#c84235',
    'secondary-red-light': '#f17a73',
  },
}

export const defaultColorPalette = {
  general: {
    light_theme: {
      // =============== primary colors ==================
      '--primary': {
        key: '--primary',
        color: colors.light_theme.primary,
        title: 'Primary Color',
        groupName: 'Primary',
      },
      '--primary-alt': {
        key: '--primary-alt',
        color: colors.light_theme['primary-alt'],
        title: 'Primary Alt Color',
        groupName: 'Primary',
      },
      '--page-text-color': {
        key: '--page-text-color',
        color: colors.light_theme['page-text-color'],
        title: 'Page Text Color',
        groupName: 'Primary',
      },
      '--page-background-color': {
        key: '--page-background-color',
        color: colors.light_theme['page-background-color'],
        title: 'Page Background Color',
        groupName: 'Primary',
      },
      // =============== secondary colors ==================
      '--secondary-green': {
        key: '--secondary-green',
        color: colors.light_theme['secondary-green'],
        title: 'Secondary Green',
        groupName: 'Secondary',
      },
      '--secondary-yellow': {
        key: '--secondary-yellow',
        color: colors.light_theme['secondary-yellow'],
        title: 'Secondary Yellow',
        groupName: 'Secondary',
      },
      '--secondary-orange': {
        key: '--secondary-orange',
        color: colors.light_theme['secondary-orange'],
        title: 'Secondary Orange',
        groupName: 'Secondary',
      },
      '--secondary-red': {
        key: '--secondary-red',
        color: colors.light_theme['secondary-red'],
        title: 'Secondary Red',
        groupName: 'Secondary',
      },
      '--secondary-red-dark': {
        key: '--secondary-red-dark',
        color: colors.light_theme['secondary-red-dark'],
        title: 'Secondary Red Dark',
        groupName: 'Secondary',
      },
      '--secondary-red-light': {
        key: '--secondary-red-light',
        color: colors.light_theme['secondary-red-light'],
        title: 'Secondary Red Light',
        groupName: 'Secondary',
      },
      // =============== neutral colors ==================
      '--neutral-darkest': {
        key: '--neutral-darkest',
        color: colors.light_theme['neutral-darkest'],
        title: 'Neutral Darkest',
        groupName: 'Neutral',
      },
      '--neutral-darker': {
        key: '--neutral-darker',
        color: colors.light_theme['neutral-darker'],
        title: 'Neutral Darker',
        groupName: 'Neutral',
      },
      '--neutral-dark': {
        key: '--neutral-dark',
        color: colors.light_theme['neutral-dark'],
        title: 'Neutral Dark',
        groupName: 'Neutral',
      },
      '--neutral-regular': {
        key: '--neutral-regular',
        color: colors.light_theme['neutral-regular'],
        title: 'Neutral Regular',
        groupName: 'Neutral',
      },
      '--neutral-light': {
        key: '--neutral-light',
        color: colors.light_theme['neutral-light'],
        title: 'Neutral Light',
        groupName: 'Neutral',
      },
      '--neutral-lighter': {
        key: '--neutral-lighter',
        color: colors.light_theme['neutral-lighter'],
        title: 'Neutral Lighter',
        groupName: 'Neutral',
      },
      '--neutral-lightest': {
        key: '--neutral-lightest',
        color: colors.light_theme['neutral-lightest'],
        title: 'Neutral Lightest',
        groupName: 'Neutral',
      },
    },
    dark_theme: {
      // =============== primary colors ==================
      '--primary': {
        key: '--primary',
        color: colors.dark_theme.primary,
        title: 'Primary Color',
        groupName: 'Primary',
      },
      '--primary-alt': {
        key: '--primary-alt',
        color: colors.dark_theme['primary-alt'],
        title: 'Primary Alt Color',
        groupName: 'Primary',
      },
      '--page-text-color': {
        key: '--page-text-color',
        color: colors.dark_theme['page-text-color'],
        title: 'Page Text Color',
        groupName: 'Primary',
      },
      '--page-background-color': {
        key: '--page-background-color',
        color: colors.dark_theme['page-background-color'],
        title: 'Page Background Color',
        groupName: 'Primary',
      },
      // =============== secondary colors ==================
      '--secondary-green': {
        key: '--secondary-green',
        color: colors.dark_theme['secondary-green'],
        title: 'Secondary Green',
        groupName: 'Secondary',
      },
      '--secondary-yellow': {
        key: '--secondary-yellow',
        color: colors.dark_theme['secondary-yellow'],
        title: 'Secondary Yellow',
        groupName: 'Secondary',
      },
      '--secondary-orange': {
        key: '--secondary-orange',
        color: colors.dark_theme['secondary-orange'],
        title: 'Secondary Orange',
        groupName: 'Secondary',
      },
      '--secondary-red': {
        key: '--secondary-red',
        color: colors.dark_theme['secondary-red'],
        title: 'Secondary Red',
        groupName: 'Secondary',
      },
      '--secondary-red-dark': {
        key: '--secondary-red-dark',
        color: colors.dark_theme['secondary-red-dark'],
        title: 'Secondary Red Dark',
        groupName: 'Secondary',
      },
      '--secondary-red-light': {
        key: '--secondary-red-light',
        color: colors.dark_theme['secondary-red-light'],
        title: 'Secondary Red Light',
        groupName: 'Secondary',
      },
      // =============== neutral colors ==================
      // '--neutral-darkest': {
      //   key: '--neutral-darkest',
      //   color: colors.dark_theme['neutral-darkest'],
      //   title: 'Neutral Darkest',
      //   groupName: 'Neutral',
      // },
      // '--neutral-darker': {
      //   key: '--neutral-darker',
      //   color: colors.dark_theme['neutral-darker'],
      //   title: 'Neutral Darker',
      //   groupName: 'Neutral',
      // },
      // '--neutral-dark': {
      //   key: '--neutral-dark',
      //   color: colors.dark_theme['neutral-dark'],
      //   title: 'Neutral Dark',
      //   groupName: 'Neutral',
      // },
      // '--neutral-regular': {
      //   key: '--neutral-regular',
      //   color: colors.dark_theme['neutral-regular'],
      //   title: 'Neutral Regular',
      //   groupName: 'Neutral',
      // },
      // '--neutral-light': {
      //   key: '--neutral-light',
      //   color: colors.dark_theme['neutral-light'],
      //   title: 'Neutral Light',
      //   groupName: 'Neutral',
      // },
      // '--neutral-lighter': {
      //   key: '--neutral-lighter',
      //   color: colors.dark_theme['neutral-lighter'],
      //   title: 'Neutral Lighter',
      //   groupName: 'Neutral',
      // },
      // '--neutral-lightest': {
      //   key: '--neutral-lightest',
      //   color: colors.dark_theme['neutral-lightest'],
      //   title: 'Neutral Lightest',
      //   groupName: 'Neutral',
      // },
    },
  },
}

export const transformColorPaletteForClient = (value) => {
  const data = {}
  const colorPalette = value || defaultColorPalette
  Object.keys(colorPalette).forEach((palette) => {
    let singleColorKey = ''
    Object.keys(colorPalette[palette]).forEach((theme) => {
      Object.keys(colorPalette[palette][theme]).forEach((key) => {
        singleColorKey = `${palette}@@${theme}@@${key}`
        data[singleColorKey] = colorPalette[palette][theme][key].color
      })
    })
  })
  return data
}

export const transformColorPaletteForServer = (data) => {
  let value = {}
  Object.keys(data || {}).map((item) => {
    const spitValue = item.split('@@')
    const palette = spitValue[0]
    const theme = spitValue[1]
    const key = spitValue[2]
    value = {
      ...value,
      [palette]: {
        ...(value[palette] || {}),
        [theme]: {
          ...(value[palette] || {})[theme],
          [key]: data[item],
        },
      },
    }
  })
  return value
}

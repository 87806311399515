import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.gl-code` },
    meta: {
      moduleName,
      nonLinked: true,
      titleFn(route, translator, pluralTranslator) {
        return `${pluralTranslator('purchase')} ${translator('management')}`
      },
    },
    children: [
      {
        path: 'gl-code',
        name: `${routeNamePrefix}.gl-code`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "purchase-management" */ './views/gl-code-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('gl_code')}`
          },
        },
      },
      {
        path: 'cost-center',
        name: `${routeNamePrefix}.cost-center`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "purchase-management" */ './views/cost-center-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('cost_center')}`
          },
        },
      },
      {
        path: 'address',
        name: `${routeNamePrefix}.address`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "purchase-management" */ './views/address-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('address')}`
          },
        },
      },
    ],
  },
]

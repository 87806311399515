<template>
  <a @click="trigger">
    <slot>
      <a class="flex items-center">
        <MIcon name="plus-circle" class="mx-1"></MIcon>
        {{ $t('add') }} {{ $tc('item') }}
      </a>
    </slot>
    <FlotoDrawer :open="isDrawerOpen" width="50%" @hide="isDrawerOpen = false">
      <template v-slot:title>
        {{ $tc('product', 2) }}
      </template>
      <template v-slot:trigger>
        <span />
      </template>
      <PurchaseItemList
        v-if="vendorId"
        searchable
        selectable
        :vendor-id="vendorId"
        :currency-id="currencyId"
        :exclude-items-by-id="(defaultSelected || []).map((i) => i.productId)"
        @selection-change="handleSelectionChange"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          :disabled="actionDisabled"
          variant="primery"
          class="mx-1"
          @click="handleAddAction"
        >
          {{ $t('add') }}
        </MButton>
        <MButton variant="default" @click="handleCancel(hide)">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </a>
</template>

<script>
import PurchaseItemList from './purchase-item-list'
export default {
  name: 'PurchaseItemDrawer',
  components: { PurchaseItemList },
  props: {
    vendorId: { type: Number, default: undefined },
    currencyId: { type: Number, default: undefined },
    defaultSelected: {
      type: Array,
      default() {
        return []
      },
    },
    allowedMaxItem: { type: Number, default: 10 },
  },
  data() {
    return {
      selectedItems: [],
      isDrawerOpen: false,
    }
  },
  computed: {
    actionDisabled() {
      if (
        this.selectedItems.length > 0 &&
        [...this.selectedItems, ...this.defaultSelected].length <=
          this.allowedMaxItem
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    trigger() {
      if (!this.vendorId || !this.currencyId) {
        return this.$errorNotification({
          message: this.$t('error'),
          description: this.$t('vendor_currency_validation_message'),
        })
      }
      setTimeout(() => {
        this.isDrawerOpen = true
      }, 400)
    },
    handleSelectionChange(list) {
      this.selectedItems = list
    },
    handleCancel(hide) {
      this.selectedItems = []
      hide()
    },
    handleAddAction() {
      this.$emit('selection-change', this.selectedItems)
      this.selectedItems = []
      this.isDrawerOpen = false
    },
  },
}
</script>

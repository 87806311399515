<template>
  <FlotoContentLoader
    classes="h-100"
    :transition="useTransition ? undefined : 'none'"
    :loading="loading"
  >
    <DetailView
      v-if="task.id"
      :default-sidebar-visible="isPage"
      :resource="task"
      :module-name="moduleName"
      :additional-info-col-size="isPage ? 9 : 9"
      :update-api="update"
      v-bind="{ ...$attrs }"
    >
      <template v-if="isPage" v-slot:before-title>
        <FlotoBackButton
          :to="backLink || $currentModule.getRoute()"
          class="text-neutral-light mr-2"
        />
      </template>
      <!-- default content as tabs -->
      <slot :ticket="task">
        <div :key="tabsRerenderCount" class="tabs">
          <MAffix>
            <MTab
              v-model="activeTabKey"
              size="small"
              class="bg-white affixed-tab"
            >
              <template v-for="tab in allowedTabs">
                <MTabPane v-if="!tab.disabled" :key="tab.id" :tab="tab.name" />
              </template>
            </MTab>
          </MAffix>
          <div class="my-4">
            <component
              :is="tabComponent"
              :disabled="
                tabComponentProps.alwaysEditable
                  ? false
                  : tabComponentProps.disabled
              "
              :resource-id="task.id"
              :watcher="task.watcher"
              :group-id="task.groupId"
              :module-name="moduleName"
              :resource="task"
              v-bind="tabComponentProps"
              :update-api="update"
              @refresh="refreshTicket"
            />
          </div>
        </div>
      </slot>
    </DetailView>
  </FlotoContentLoader>
</template>
<script>
import ConversationContainer from '@components/conversation/conversation-container'
import WorkLogContainer from '@components/worklog/worklog-container'
import AuditContainer from '@components/audit/audit-container'
import CheckListContainer from '@components/check-list/check-list-container'
import { getMyTaskApi, getMyTaskupdateApi } from '../my-tasks-api'
import DetailView from './detail/detail'
import TaskDetailContainer from './detail/task-detail-container'
export default {
  name: 'TaskDetail',
  components: {
    DetailView,
    WorkLogContainer,
    AuditContainer,
    ConversationContainer,
    TaskDetailContainer,
    CheckListContainer,
  },
  props: {
    backLink: { type: Object, default: undefined },
    onChange: { type: Function, default: undefined },
    resourceId: { type: [String, Number], required: true },
    useTransition: { type: Boolean, default: false },
    isPage: { type: Boolean, default: false },
    moduleName: { type: String, default: undefined },
  },
  data() {
    return {
      task: {},
      tabsRerenderCount: 1,
      activeTabKey: 'comment',
      allowedTabs: [],
      error: null,
      loading: true,
    }
  },
  computed: {
    tabComponent() {
      return (this.allowedTabs.find((t) => t.id === this.activeTabKey) || {})
        .component
    },
    tabComponentProps() {
      return (
        (this.allowedTabs.find((t) => t.id === this.activeTabKey) || {})
          .componentProps || {}
      )
    },
  },
  watch: {
    resourceId: {
      immediate: true,
      handler: 'getTask',
    },
  },
  methods: {
    getTask() {
      return getMyTaskApi(this.resourceId)
        .then((task) => {
          this.loading = false
          this.task = task
          this.setTabs(task)
          return task
        })
        .catch((e) => {
          this.error = e
          this.loading = false
          this.$router.replace(this.$currentModule.getRoute())
        })
    },
    setTabs(info) {
      const excluded = this.getExcludedTabs(info)
      let tabs = [
        {
          id: 'comment',
          name: this.$tc('comment', 2),
          component: ConversationContainer,
          componentProps: {
            stickySearch: true,
            alwaysEditable: true,
            offsetTop: 36,
          },
        },
        {
          id: 'detail',
          name: this.$tc('detail', 2),
          disabled: excluded && excluded.indexOf('detail') >= 0,
          component: TaskDetailContainer,
          componentProps: {
            stickySearch: true,
            alwaysEditable: true,
            offsetTop: 36,
          },
        },
        {
          id: 'work_log',
          name: this.$t('work_log'),
          component: WorkLogContainer,
          componentProps: {
            alwaysEditable: true,
            stickySearch: true,
            offsetTop: 36,
          },
        },
        {
          id: 'checklist',
          name: this.$tc('check_list'),
          disabled: excluded && excluded.indexOf('checklist') >= 0,
          component: CheckListContainer,
        },
        {
          id: 'activity',
          name: this.$tc('audit_trail'),
          component: AuditContainer,
        },
      ]
      this.allowedTabs = Object.freeze(tabs)
    },
    getExcludedTabs(task) {
      const excluded = []
      if (task && task.moduleName === undefined) {
        excluded.push('detail')
      }
      if (task && task.moduleName !== this.$constants.PROJECT) {
        excluded.push('checklist')
      }
      return excluded
    },
    refreshTicket(reRenderTabs = undefined) {
      return getMyTaskApi(this.resourceId).then((task) => {
        this.task = task
        if (reRenderTabs && reRenderTabs.indexOf(this.activeTabKey) >= 0) {
          this.tabsRerenderCount = this.tabsRerenderCount + 1
        }
        this.setTabs(task)
        return task
      })
    },
    update(change, forceUpdate = false) {
      if (Object.keys(change).length) {
        return getMyTaskupdateApi({
          id: this.task.id,
          ...change,
        }).then((data) => {
          this.task = { ...this.task, ...data }
          if (this.onChange) {
            this.onChange(data)
          }
          return data
        })
      }
      return this.refreshTicket(['comment'])
    },
  },
}
</script>

<template>
  <div class="flex flex-col flex-1 overflow-scroll">
    <GanttChart
      v-if="items.length && chartOptions"
      ref="chart"
      :constructor-type="'ganttChart'"
      class="hc"
      :options="chartOptions"
    ></GanttChart>
    <FlotoNoData v-else />
    <FlotoTaskDrawer
      v-if="selectedTask.taskId"
      :open="Boolean(selectedTask.taskId)"
      disabled
      :default-task="{ ...selectedTask, id: selectedTask.taskId }"
      @close="selectedTask = {}"
    >
      <template v-slot:trigger> <span /> </template>
    </FlotoTaskDrawer>
  </div>
</template>

<script>
import GanttChart from '@components/gantt/gantt'
import { TaskComputed } from '@state/modules/task'
import { TechnicianComputed } from '@state/modules/technician'
import { getPlanningTasksApi } from '../project-task/api'
import GanttAdapter from './gantt-adapter'
export default {
  name: 'GanttVue',
  components: { GanttChart },
  props: {
    resourceId: { type: [String, Number], required: true },
    resource: { type: Object, required: true },
    moduleName: {
      type: String,
      default() {
        return this.$constants.PROJECT
      },
    },
  },
  data() {
    return {
      data: [],
      items: [],
      chartOptions: null,
      selectedTask: {},
    }
  },
  computed: {
    ...TaskComputed,
    ...TechnicianComputed,
    milestoneId() {
      return (
        this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
      ).id
    },
  },
  watch: {
    resourceId: {
      immediate: true,
      handler: 'getTasks',
    },
  },
  methods: {
    getTasks() {
      return getPlanningTasksApi(
        {
          moduleName: this.moduleName,
        },
        this.resourceId
      ).then((data) => {
        const tasks = (data || {}).hierarchy || []
        this.items = tasks
        this.chartOptions = GanttAdapter(
          tasks,
          this.resource,
          this.milestoneId,
          this.technicianOptions,
          this.callBackFn
        )
        this.loading = false
      })
    },
    callBackFn(data) {
      if (data.point.task && data.point.task.type !== 'summary_task') {
        this.selectedTask = data.point.task
      }
    },
  },
}
</script>

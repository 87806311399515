<template>
  <div class="flex h-100 flex-col flex-1">
    <FlotoCrudContainer
      ref="list"
      :fetch-fn="getMembers"
      :scrollable="false"
      :delete-fn="removeMember"
      :columns="tableColumns"
      :show-no-data="false"
      as-table
    >
      <template v-slot:add-controls>
        <Component
          :is="stickySearch ? 'MAffix' : 'div'"
          v-if="canManageMembers && !disabled"
          :offset-top="offsetTop"
          @change="searchAffixed = $event"
        >
          <div :class="{ 'pt-2': searchAffixed }">
            <MRow>
              <MCol class="m-align-button">
                <MButton variant="neutral" @click="showAddMemberForm = true">
                  {{ $t('add') }} {{ $tc('member') }}
                </MButton>
              </MCol>
            </MRow>
            <MDivider />
          </div>
        </Component>
      </template>
      <MemberForm
        v-if="showAddMemberForm"
        :create-fn="updateMember"
        :resource="resource"
        :resource-id="resourceId"
        :available-member-ids="availableMemberIds"
        @hide="showAddMemberForm = false"
      />
      <template v-slot:actions="{ item, edit, remove }">
        <td>
          <FlotoDeleteBtn
            :disabled="!canManageMembers || item.isOwner"
            :message="$t('confirm_remove_item', { item: $t('member') })"
            @confirm="remove"
          />
        </td>
      </template>
    </FlotoCrudContainer>
    <NoteRules ref="noteDrawer" :create-fn="handleNoteAdded" />
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import MemberForm from './member-container-form'
import { updateApi, getMembersApi } from './../../project-api'
import NoteRules from '@components/note-rules/note-rules'
import { CustomRulesComputed } from '@state/modules/custom-rules'

export default {
  name: 'MemberContainer',
  components: { MemberForm, NoteRules },
  props: {
    disabled: { type: Boolean, default: false },
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
    resource: { type: Object, required: true },
  },
  data() {
    return {
      availableMemberIds: [],
      showAddMemberForm: false,
      searchAffixed: false,
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    ...CustomRulesComputed,
    canManageMembers() {
      return this.myAllowedModules.indexOf('project.manage_members') >= 0
    },
    tableColumns() {
      return [
        { name: `${this.$tc('name')}`, key: 'memberName' },
        {
          name: `${this.$tc('project')} ${this.$tc('role')}`,
          key: 'projectRole',
        },
        { name: `${this.$tc('task', 2)}`, key: 'completedTask' },
        { name: `${this.$tc('milestone', 2)}`, key: 'completedMilestone' },
        { name: this.$tc('action', 2), key: 'actions', width: '5rem' },
      ]
    },
  },
  methods: {
    refresh() {
      this.$refs.list.refresh()
    },
    getMembers(limit, offset) {
      return getMembersApi(this.resourceId, limit, offset).then((data) => {
        this.availableMemberIds = (data.items || []).map((d) => d.id)
        return data
      })
    },
    updateMember(data) {
      return updateApi({
        id: this.resourceId,
        ...data,
      }).then(() => {
        this.refresh()
      })
    },
    removeMember({ id, notes }, forceUpdate = false) {
      const updatedIds = this.availableMemberIds.filter((i) => i !== id)
      const noteRequiredFields =
        this[`${this.moduleName}NoteRequiredFields`] || []
      if (
        noteRequiredFields.includes('member_removed') &&
        forceUpdate === false
      ) {
        this.$refs.noteDrawer.openForm()
        return new Promise((resolve, reject) => {
          this.pendingChanges = id
          this.resolveFn = resolve
          this.rejectFn = reject
        })
      }
      return this.updateMember({
        id: this.resourceId,
        members: updatedIds,
        notes: notes,
      })
    },
    handleNoteAdded(data) {
      return this.removeMember(
        {
          ...data,
          id: this.pendingChanges,
        },
        true
      )
        .then((data) => {
          this.pendingChanges = null
          this.$refs.noteDrawer.closeForm()
          this.resolveFn(data)
        })
        .finally(() => {
          this.$refs.noteDrawer.closeForm()
        })
    },
  },
}
</script>

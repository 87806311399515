<template>
  <div v-if="relatedResourceType" class="my-2 bordered rounded">
    <ResourceListTitleWithStatus
      :module-name="relatedResourceType"
      bordered
      :use-link="!isPortalLogin"
      :link="resourceLink"
      :resource="relatedResource"
    />
  </div>
</template>
<script>
import { getTaskResourceDetailApi } from '@modules/ticket/ticket-api'
import { authComputed } from '@state/modules/auth'
import ResourceListTitleWithStatus from '@components/resource/resource-list-title-with-status.vue'
export default {
  name: 'TaskDetailContainer',
  components: { ResourceListTitleWithStatus },
  props: {
    resource: { type: Object, default: undefined },
  },
  data() {
    return {
      relatedResource: {},
      relatedResourceType: '',
    }
  },
  computed: {
    ...authComputed,
    resourceLink() {
      if (
        [
          this.$constants.REQUEST,
          this.$constants.PROBLEM,
          this.$constants.CHANGE,
        ].indexOf(this.resource.moduleName) >= 0
      ) {
        return this.$modules.getModuleRoute('ticket', 'view', {
          params: {
            ticketType: this.resource.moduleName,
            id: this.resource.parentId,
          },
        })
      }
      return undefined
    },
  },
  created() {
    this.getRelatedResource()
  },
  methods: {
    getRelatedResource() {
      if (this.resource.parentId) {
        getTaskResourceDetailApi(
          this.resource.moduleName,
          this.resource.parentId
        ).then((data) => {
          this.relatedResource = data
          this.relatedResourceType =
            this.resource.moduleName || this.$constants.REQUEST
        })
      }
    },
  },
}
</script>

import { generateId } from '@utils/id'
import { getModuleTranslator } from '@utils/get-module-translator'
import configs from '../config'

const __t = getModuleTranslator(configs.translationKey)

export const pageText = {
  title: __t('basic_setup'),
  description: __t('basic_setup_description'),
  icon: 'sliders-h',
  color: '#3cc1c8',
  iconBackground: '#dff4f4',
}

export const availableSections = (availableModules) => [
  {
    key: generateId(),
    id: 'email',
    title: __t('support_email'),
    description: __t('primary_email_configuration'),
    sectionDetailTitle: __t('setting_up_support_email'),
    sectionDetailDescription: __t('effortlessly_setup_email_servers'),
    steps: [
      {
        key: generateId(),
        id: 'incoming_email_config',
        title: __t('configuring_email_servers'),
        description: __t('configuring_email_servers_description'),
        helpBubble: {
          icon: 'envelope',
          title: __t('incoming_email_server_settings'),
          description: __t('incoming_email_server_settings_description'),
        },
        route: {
          text: __t('incoming_email_server_route'),
          module: 'it-infrastructure',
          name: 'email-server-config',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'email',
                  step: 'incoming_email_config',
                  defaultData: {
                    currentTab: 'incoming',
                  },
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'outgoing_email_config',
        title: __t('configuring_outgoing_email_servers'),
        description: __t('configuring_outgoing_email_servers_description'),
        helpBubble: {
          icon: 'envelope',
          title: __t('outgoing_email_server_setting'),
          description: __t('outgoing_email_server_setting_description'),
        },
        route: {
          text: __t('outgoing_email_server_route'),
          module: 'it-infrastructure',
          name: 'email-server-config',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'email',
                  step: 'outgoing_email_config',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'enable_email_to_ticket',
        title: __t('enable_email_to_ticket_title'),
        description: __t('enable_email_to_ticket_description'),
        helpBubble: {
          icon: 'envelope',
          title: __t('convert_email_to_incident_request'),
          description: __t('convert_email_to_incident_request_description'),
        },
        route: {
          text: __t('email_preference_route'),
          module: 'it-infrastructure',
          name: 'email-server-config',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'email',
                  step: 'enable_email_to_ticket',
                  defaultData: {
                    currentTab: 'emailpreference',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'branding',
    title: __t('rebranding_and_theming'),
    description: __t('rebranding_and_theming_description'),
    sectionDetailTitle: __t('rebranding_serviceops_ui_colors'),
    sectionDetailDescription: __t(
      'uploading_company_logo_to_changing_name_of_support_portal'
    ),
    steps: [
      {
        key: generateId(),
        id: 'branding_details',
        title: __t('details'),
        description: `<ul><li>
        ${__t('rename_helpdesk_technician_support_portal')}
        </li><li>
        ${__t('upload_company_logo_and_favicon')}
        </li></ul>`,
        helpBubble: {
          icon: 'paint-brush',
          title: __t('logo_and_portal_name_personalization'),
          description: __t('logo_and_portal_name_personalization_description'),
        },
        route: {
          text: __t('branding_detail_route'),
          module: 'organization',
          name: 'branding',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'branding',
                  step: 'branding_details',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'branding_theme',
        title: __t('theme'),
        description: `<ul><li>
        ${__t('set_default_color_theme')}
        </li><li>
        ${__t('change_look_and_feel_of_pages')}
        </li></ul>`,
        helpBubble: {
          icon: 'paint-brush',
          title: __t('branding_theme_help_bubble_title'),
          description: __t('branding_theme_help_bubble_title_description'),
        },
        route: {
          text: __t('branding_theme_route'),
          module: 'organization',
          name: 'branding',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'branding',
                  step: 'branding_theme',
                  defaultData: {
                    currentTab: 'theme',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'user',
    title: __t('user_title'),
    description: __t('user_title_description'),
    sectionDetailTitle: __t('user_section_title'),
    sectionDetailDescription: __t('user_section_description'),
    steps: [
      {
        key: generateId(),
        id: 'add_new_technician',
        title: __t('add_new_technician'),
        description: `<ul><li>
        ${__t('create_one_technician_at_a_time')}
        </li><li>
        ${__t('bulk_upload_multiple_technician')}
        </li></ul>`,
        helpBubble: {
          icon: 'user',
          title: __t('add_new_technician_help_bubble_title'),
          description: __t('add_new_technician_help_bubble_description'),
        },
        route: {
          text: __t('add_new_technician_route'),
          module: 'users',
          name: 'create',
          params: { userType: 'technician' },
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'user',
                  step: 'add_new_technician',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'assign_technician_role',
        title: __t('assign_technician_role'),
        description: `<ul><li>
        ${__t('create_and_assign_roles_to_technician')}
        </li><li>
        ${__t('limit_technician_access')}
        </li></ul>`,
        helpBubble: {
          icon: 'user',
          title: __t('assign_technician_role_help_bubble_title'),
          description: __t('assign_technician_role_help_bubble_description'),
        },
        route: {
          text: __t('assign_technician_role_route'),
          module: 'roles',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'user',
                  step: 'assign_technician_role',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'add_new_requester',
        title: __t('add_new_requester'),
        description: `<ul><li>
        ${__t('create_one_requester_at_a_time')}
        </li><li>
        ${__t('bulk_upload_multiple_requesters')}
        </li></ul>`,
        helpBubble: {
          icon: 'user',
          title: __t('add_new_requester_help_bubble_title'),
          description: __t('add_new_requester_help_bubble_description'),
        },
        route: {
          text: __t('add_new_requester_route'),
          module: 'users',
          name: '',
          params: { userType: 'requester' },
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'user',
                  step: 'add_new_requester',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'customizing_dashboard',
    title: __t('customizing_dashboard'),
    description: __t('customizing_dashboard_description'),
    sectionDetailTitle: __t('customizing_dashboard_section_title'),
    sectionDetailDescription: __t('customizing_dashboard_section_description'),
    steps: [
      {
        key: generateId(),
        id: 'managing_dashboard',
        title: __t('managing_dashboard'),
        description: __t('managing_dashboard_description'),
        helpBubble: {
          icon: 'tachometer-alt',
          title: __t('managing_dashboard_help_bubble_title'),
          description: __t('managing_dashboard_help_bubble_description'),
        },
        route: {
          text: __t('managing_dashboard_route'),
          module: 'dashboard',
          name: 'managedashboard',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'customizing_dashboard',
                  step: 'managing_dashboard',
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'adding_kpis',
        title: __t('adding_kpis'),
        description: __t('adding_kpis_description'),
        helpBubble: {
          icon: 'tachometer-alt',
          title: __t('adding_kpis_help_bubble_title'),
          description: __t('adding_kpis_help_bubble_description'),
        },
        route: {
          text: __t('adding_kpis_route'),
          module: 'dashboard',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'customizing_dashboard',
                  step: 'adding_kpis',
                  defaultData: {
                    customize: true,
                    type: 'kpi',
                  },
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'adding_widgets',
        title: __t('adding_widgets'),
        description: __t('adding_widgets_description'),
        helpBubble: {
          icon: 'tachometer-alt',
          title: __t('adding_widgets_help_bubble_title'),
          description: __t('adding_widgets_help_bubble_description'),
        },
        route: {
          text: __t('adding_widgets_route'),
          module: 'dashboard',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'basic',
                  section: 'customizing_dashboard',
                  step: 'adding_widgets',
                  defaultData: {
                    customize: true,
                    type: 'widget',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
]

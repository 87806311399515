import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformResponseTemplate,
  transformResponseTemplateForServer,
} from '@data/response-template'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getResponseTemplatesApi(filter, limit, offset) {
  return api
    .post(
      `responsetemplate/search/byqual`,
      {
        qualDetails:
          filter.name || filter.enabled
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformResponseTemplate),
        total: data.totalCount,
      }
    })
}

export function createResponseTemplateApi(data) {
  return api
    .post(`/responsetemplate`, transformResponseTemplateForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('response_template'),
      }),
    })
    .then(transformResponseTemplate)
}

export function getResponseTemplateApi(id) {
  return api.get(`responsetemplate/${id}`).then(transformResponseTemplate)
}

export function updateResponseTemplateApi(data) {
  return api
    .patch(
      `/responsetemplate/${data.id}`,
      transformResponseTemplateForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('response_template'),
        }),
      }
    )
    .then(transformResponseTemplate)
}

export function deleteResponseTemplateApi(id) {
  return api.delete(`/responsetemplate/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('response_template'),
    }),
  })
}

export const calculateTax = (total, percentage) =>
  parseFloat((parseFloat(total) * (parseFloat(percentage) / 100)).toFixed(2))

export const isValidNumberForInput = (v) => /^-?\d+\.?\d?\d*$/.test(v)

// validate number
// export const getValidNumber = (v) => (/^[+-]?\d+(\.\d+)?$/.test(v) ? v : 0)

// validate float number
export const getValidNumber = (v) => (/^\d+\.?\d?\d*$/.test(v) ? v : 0)

export const isValidPercentage = (v) =>
  /^\d+\.?\d?\d*$/.test(v) && parseFloat(v) <= 100

export const getValidPercentage = (v) => (isValidPercentage(v) ? v : 0)

export const calculateTotal = (purchaseOrder, purchaseCustomFields = []) => {
  const subTotal = purchaseOrder.itemList.reduce(
    (t, item) => t + item.totalAmount,
    0
  )
  const final = { ...purchaseOrder }
  final.subTotal = parseFloat(getValidNumber(subTotal))
  final.totalNet =
    subTotal -
    calculateTax(
      parseFloat(getValidNumber(final.subTotal)),
      parseFloat(getValidPercentage(final.discount) || 0) || 0
    )
  final.discountAmount = calculateTax(
    final.subTotal,
    parseFloat(getValidPercentage(final.discount) || 0) || 0
  )
  final.totalCost =
    parseFloat(final.totalNet || 0) +
    parseFloat(getValidNumber(final.shippingCost) || 0)
  final.netPlusShippingAmount = final.totalCost
  const customFieldsAdjustedAmount = purchaseCustomFields.reduce(
    (result, currentField) => {
      let amount
      let returnAmount
      if (
        purchaseOrder.fieldValueDetails[currentField.id] ||
        purchaseOrder.fieldValueDetails[currentField.id] === 0
      ) {
        amount = parseFloat(
          getValidNumber(purchaseOrder.fieldValueDetails[currentField.id] || 0)
        )
      } else if (currentField.defaultValue !== Number.MIN_VALUE) {
        amount = parseFloat(getValidNumber(currentField.defaultValue) || 0)
      } else {
        amount = 0
      }
      if (currentField.percentageField) {
        const amountOnCalcuation =
          currentField.attributes.countOnNetValueWithShipping === 'true'
            ? final.netPlusShippingAmount
            : final.totalNet
        amount = calculateTax(amountOnCalcuation, amount)
      }
      if (currentField.attributes.operation === 'subtract') {
        returnAmount = result - amount
      } else {
        returnAmount = result + amount
      }
      final[`${currentField.id}Amount`] = amount
      return returnAmount
    },
    final.totalCost
  )
  final.totalCost = customFieldsAdjustedAmount
  final.totalCost = parseFloat(valueFormatter(final.totalCost).toFixed(2))
  return final
}

export const valueFormatter = (value) => {
  if (/^0+[123456789]+/.test(value)) {
    return value.replace(/^0+/, '')
  }
  if (/^0+$/.test(value)) {
    return 0
  }
  return value
}

export const priceChecker = (value) => {
  if (!isValidNumberForInput(value)) {
    return 0
  }
  return valueFormatter(value)
}

export const percentageChecker = (value) => {
  if (value === '') {
    return ''
  }
  if (!isValidPercentage(value)) {
    return 0
  }
  return valueFormatter(value)
}

export const qtyChecker = (value, allowZero = false) => {
  if (value === '' || isNaN(value)) {
    return 0
  }
  if (+value === 0 && !allowZero) {
    // alert('not allow zero')
    return 1
  }
  return parseInt(value, 10)
}

export const getVendorFromId = (vendors, vendorId) =>
  vendors.find(({ id }) => id === vendorId)

export const getTotalAmount = (price, qty, taxRate) => {
  const totalPrice = price * qty
  return totalPrice + calculateTax(totalPrice, taxRate)
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 items-center min-w-0",attrs:{"id":_vm.item.name}},[_c('InlineEdit',{staticClass:"min-w-0",attrs:{"field":_vm.$tc(_vm.type),"rules":_vm.rules,"value":_vm.item.name,"item-style":{ display: 'flex', flex: 1, alignItems: 'center' }},on:{"change":function($event){return _vm.$emit('update', Object.assign({}, _vm.item, {name: $event}))},"current-editing":function($event){return _vm.$emit('current-editing', $event)}},scopedSlots:_vm._u([{key:"item-renderer",fn:function(ref){
var edit = ref.edit;
return [(_vm.useColor === true)?_c('ColorPicker',{attrs:{"id":"color-picker"},on:{"change":function($event){return _vm.$emit('update', Object.assign({}, _vm.item, {color: $event}))}},model:{value:(_vm.item.color),callback:function ($$v) {_vm.$set(_vm.item, "color", $$v)},expression:"item.color"}}):_vm._e(),_c('div',{staticClass:"item-title flex-1 mx-1 min-w-0"},[_c('div',{staticClass:"flex flex-col min-w-0"},[_c('div',{staticClass:"text-ellipsis"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.name)+" ")]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])],1),(_vm.useDescription)?_c('small',{staticClass:"text-ellipsis text-neutral",attrs:{"title":_vm.item.description}},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]):_vm._e()])]),(!_vm.disabled)?_c('HoverableAction',{attrs:{"disabled":!_vm.isHoverableAction},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var stickAction = ref.stickAction;
var unstickAction = ref.unstickAction;
return [(!_vm.disabled)?_vm._t("edit-action",function(){return [(_vm.item.canEdit)?_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"mx-1 cursor-pointer text-neutral-light",attrs:{"id":"edit-icon","name":"pencil","size":"lg"},on:{"click":edit}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e()]},{"stickAction":stickAction,"unstickAction":unstickAction}):_vm._e(),(_vm.item.canDelete && !_vm.item.default)?_c('FlotoDeleteBtn',{staticClass:"mx-1",attrs:{"id":"delete-icon","message":_vm.$t('confirm_remove_item', {
                item: ("" + (_vm.$tc(_vm.type))),
              })},on:{"trigger":stickAction,"cancel":unstickAction,"confirm":function($event){return _vm.$emit('remove', _vm.item)}}},[_c('MIcon',{staticClass:"text-secondary-red",attrs:{"name":"times-circle","size":"lg"}})],1):_vm._e()]}}],null,true)}):_vm._e()]}}])}),(!_vm.hideDefaultAction)?_c('MTooltip',[_c('a',{staticClass:"text-neutral-light",attrs:{"slot":"trigger","href":"javascript:;"},slot:"trigger"},[_c('MSwitch',{staticClass:"mx-1",attrs:{"id":"default-switch","disabled":_vm.item.default,"size":"small","checked":_vm.item.default},on:{"change":function($event){return _vm.$emit('update', Object.assign({}, _vm.item, {default: $event}))}}})],1),_vm._v(" "+_vm._s(_vm.$t('set_as'))+" "+_vm._s(_vm.$t('default'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a @click="triggerAction">
    <MIcon name="times" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $tc('cancel') }} {{ $tc('project') }}</span
    >
    <FlotoConfirmModal
      :open="showModal"
      prevent-auto-close-on-confirm
      :processing="processing"
      @hide="showModal = false"
      @confirm="handleConfirmAction"
    >
      <template v-slot:icon>
        <MIcon name="times" class="w-full text-secondary-red" size="2x" />
      </template>
      <template v-slot:message>
        {{
          $t('confirm_cancel', {
            resource: $tc(moduleName),
          })
        }}
      </template>
    </FlotoConfirmModal>
    <NoteRules ref="noteDrawer" :create-fn="handleNoteAdded" />
  </a>
</template>
<script>
import NoteRules from '@components/note-rules/note-rules'
import { CustomRulesComputed } from '@state/modules/custom-rules'

export default {
  name: 'CancelProject',
  components: { NoteRules },
  props: {
    project: { type: Object, required: true },
    moduleName: { type: String, required: true },
    updateApi: { type: Function, default: undefined },
  },
  data() {
    return {
      showModal: false,
      processing: false,
    }
  },
  computed: { ...CustomRulesComputed },
  methods: {
    handleConfirmAction(data, forceUpdate = false) {
      this.showModal = false
      this.processing = true
      const noteRequiredFields =
        this[`${this.moduleName}NoteRequiredFields`] || []
      if (
        noteRequiredFields.includes('cancel_project') &&
        forceUpdate === false
      ) {
        this.$refs.noteDrawer.openForm()
        return new Promise((resolve, reject) => {
          this.resolveFn = resolve
          this.rejectFn = reject
        })
      }
      return this.updateApi({
        ...data,
        projectStatus: 'cancelled',
      }).finally((this.processing = false))
    },
    triggerAction() {
      this.$emit('trigger')
      // show modal after dropdown is hide
      setTimeout(() => {
        this.showModal = true
      }, 400)
    },
    handleNoteAdded(data) {
      return this.handleConfirmAction(data, true)
        .then((data) => {
          this.$refs.noteDrawer.closeForm()
        })
        .finally(() => {
          this.$refs.noteDrawer.closeForm()
        })
    },
  },
}
</script>

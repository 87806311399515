import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.patch-settings` },
    meta: { moduleName, breadcrumbKey: 'patch_management', nonLinked: true },
    children: [
      {
        path: 'patch-settings',
        name: `${routeNamePrefix}.patch-settings`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/patch-settings'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('patch_settings')}`
          },
        },
      },
      {
        path: 'access-controls',
        name: `${routeNamePrefix}.access-controls`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/access-controls')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('access_controls')}`
          },
        },
      },
      {
        path: 'deployment-policy',
        name: `${routeNamePrefix}.deployment-policy`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/deployment-policy-configuration/list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('deployment_policy')}`
          },
        },
      },
      {
        path: 'decline-patch',
        name: `${routeNamePrefix}.decline-patch`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/decline-patch/list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('decline_patch')}`
          },
        },
      },
      {
        path: 'remote-office',
        name: `${routeNamePrefix}.remote-office`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/remote-office/list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('remote_office')}`
          },
        },
      },
      {
        path: 'endpoints-scope',
        name: `${routeNamePrefix}.endpoints-scope`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/endpoints-scope'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('endpoints')} ${pluralTranslator(
              'scope'
            )}`
          },
        },
      },
      {
        path: 'system-health-settings',
        name: `${routeNamePrefix}.system-health-settings`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/system-health-settings'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('system_health_settings')}`
          },
        },
      },
      {
        path: 'computer-groups',
        name: `${routeNamePrefix}.computer-groups`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/computer-groups'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('computer')} ${pluralTranslator(
              'group',
              2
            )}`
          },
        },
      },
      {
        path: 'create-computer-groups',
        name: `${routeNamePrefix}.create-computer-groups`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/create-computer-groups'
            )
          ),
        meta: {},
      },
      {
        path: 'edit-computer-groups/:id',
        name: `${routeNamePrefix}.edit-computer-groups`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/create-computer-groups'
            )
          ),
        meta: {},
      },
      {
        path: 'agent-nomination',
        name: `${routeNamePrefix}.agent-nomination`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "patch-management" */ './views/agent-nomination'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('agent_nomination')}`
          },
        },
      },
    ],
  },
]

import api from '@api'
import {
  transformAvailableQualification,
  buildFlatQualificationStructure,
  buildNameFilterQuery,
  buildUnaryQualificationStructure,
} from '@data/qualification'
import {
  transformReportForServer,
  transformTabularReportPreview,
  transformMatrixReportPreview,
  transformSummaryReportPreview,
  transformReport,
  transformReportForList,
  transformReportSchedule,
  transformReportScheduleForServer,
  transformAvailableColumn,
  transformReportHistory,
  transformDownloadForServer,
} from '@data/report'
import { transformWidgetTable } from '@data/dashboard'
import { errorToast } from '@motadata/ui'

import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'

import moduleConfig from './config'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()
const __moduleT = getModuleTranslator(moduleConfig.translationKey)

export function getAvailableColumnsApi(moduleName, additionalUrlParams = {}) {
  return api
    .get(`analytics/report/${moduleName}/supportedData`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      const transformedData = {}
      Object.keys(data).forEach((key) => {
        if (['dateFilter', 'summary'].indexOf(key) === -1) {
          transformedData[key] = data[key].map(transformAvailableColumn)
        }
      })
      transformedData.summary = data.summary.map((c) => ({
        text: c.sectionTitle,
        key: c.sectionKey,
        selectableColumn: true,
        availableColumns: (c.reportColumns || []).map(transformAvailableColumn),
      }))
      transformedData.dateFilters = data.dateFilter.map(
        transformAvailableColumn
      )
      return transformedData
    })
}
export function getAvailableQualificationsApi(
  moduleName,
  additionalUrlParams = {}
) {
  return api
    .get(`analytics/report/${moduleName}/supportedQual`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then((data) => {
      const transformedData = {}
      Object.keys(data).forEach((key) => {
        transformedData[key] = data[key].map(transformAvailableQualification)
      })
      return transformedData
    })
}

export function getReportsApi(filterFactor, limit, offset) {
  return api
    .post(
      `analytics/report/${filterFactor.moduleName}/search/byqual`,
      {
        qualDetails:
          filterFactor.name || filterFactor.oobType
            ? buildFlatQualificationStructure(
                [
                  filterFactor.name && buildNameFilterQuery(filterFactor.name),
                  filterFactor.oobType &&
                    buildUnaryQualificationStructure(
                      'oobType',
                      filterFactor.oobType,
                      'db'
                    ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformReportForList),
        total: data.totalCount,
      }
    })
}

export function createReportApi(data) {
  return api.post(
    `analytics/report/${data.moduleName}`,
    transformReportForServer(data),
    {
      message: __rootT('created_successfully', {
        resource: __rootTc('report'),
      }),
    }
  )
}

export function updateReportApi(data) {
  return api.patch(
    `analytics/report/${data.moduleName}/${data.id}`,
    transformReportForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('report'),
      }),
    }
  )
}

export function getReportApi(moduleName, id) {
  return api.get(`analytics/report/${moduleName}/${id}`).then(transformReport)
}

export function deleteReportApi(moduleName, id) {
  return api.delete(`analytics/report/${moduleName}/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('report'),
    }),
  })
}

export function getReportColumnValuesApi(
  moduleName,
  columnKeys,
  additionalUrlParams = {}
) {
  return api
    .post(`analytics/report/${moduleName}/columnvalue`, columnKeys, {
      params: {
        ...additionalUrlParams,
      },
      notify: false,
    })
    .then((data) => {
      const obj = {}
      Object.keys(data).forEach((key) => {
        if (data[key].length) {
          obj[key] = data[key].map((c) => ({ text: c.value, key: c.id }))
        }
      })
      return obj
    })
}

export function getReportPreviewApi(moduleName, id, reportType, data) {
  const transformer =
    reportType === 'matrix'
      ? transformMatrixReportPreview
      : transformTabularReportPreview
  return api
    .post(
      `analytics/report/${moduleName}/${id}/preview`,
      {
        startDate: data.startDate,
        endDate: data.endDate,
      },
      {
        timeout: 0,
        message: '',
        notification: {
          error: {
            message: __rootT('error'),
            description: __moduleT('preview_failed'),
          },
        },
      }
    )
    .then((data) => ({
      chart: data.chart ? transformWidgetTable(data.chart) : null,
      report:
        reportType === 'summary'
          ? transformSummaryReportPreview(data.reports)
          : data.reports.map(transformer).filter((t) => t !== undefined),
    }))
}

export function getReportPreviewOnlyApi(moduleName, query) {
  return api
    .post(
      `analytics/public/${moduleName}/${
        query.moduleName
      }/${+query.reportId}/chart?userId=${query.userId}&token=${query.token}`,
      query,
      {
        timeout: 0,
        message: '',
        notification: {
          error: {
            message: __rootT('error'),
            description: __moduleT('preview_failed'),
          },
        },
      }
    )
    .then((data) => transformWidgetTable(data))
}

export function exportReportApi(id, data) {
  return api.post(
    `analytics/report/${id}/export`,
    {
      emailAddresses: data.emails,
      startDate: data.startDate,
      endDate: data.endDate,
      type: data.type,
      enabled: data.enabled,
      attachmentPassword: data.protectedPassword,
    },
    {
      params: {
        exporttype: data.exportFormat,
      },
      notification: {
        message: __rootT('success'),
        description: __rootT('resource_will_be_sent', {
          resource: __rootTc('report'),
        }),
      },
    }
  )
}

export function createReportScheduleApi(report, data) {
  return api
    .post(
      `analytics/report/${report.moduleName}/${report.id}/scheduler`,
      transformReportScheduleForServer(data),
      {
        message: __rootT('created_successfully', {
          resource: __rootTc('schedule'),
        }),
      }
    )
    .then(transformReportSchedule)
}

export function updateReportScheduleApi(report, data) {
  return api
    .patch(
      `analytics/report/${report.moduleName}/${report.id}/scheduler/${data.id}`,
      transformReportScheduleForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('schedule'),
        }),
      }
    )
    .then(transformReportSchedule)
}

export function deleteReportScheduleApi(report, id) {
  return api.delete(
    `analytics/report/${report.moduleName}/${report.id}/scheduler/${id}`,
    {
      message: __rootT('deleted_successfully', {
        resource: __rootTc('schedule'),
      }),
    }
  )
}

export function getReportSchedulesApi(filterFactor, report, limit, offset) {
  return api
    .post(
      `analytics/report/${report.moduleName}/${report.id}/scheduler/search/byqual`,
      {
        qualDetails: filterFactor.name
          ? buildFlatQualificationStructure(
              [
                filterFactor.name && buildNameFilterQuery(filterFactor.name),
              ].filter(Boolean)
            )
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformReportSchedule),
        total: data.totalCount,
      }
    })
}

export function toggleScheduleEnableApi(moduleName, id, data) {
  return api
    .patch(`analytics/report/${moduleName}/${id}/scheduler`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('report'),
      }),
    })
    .then((data) => transformReportForList(data))
}

export function getReportHistoryApi(moduleName, searchCriteria, limit, offset) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]

  return api
    .post(
      `analytics/reporthistory/${moduleName}/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformReportHistory),
        total: data.totalCount,
      }
    })
}

export function getReportSettingApi() {
  return api.get(`analytics/reportSetting`)
}

export function reportSettingUpdateApi(data) {
  return api.patch(`analytics/reportSetting`, data, {
    message: __rootT('updated_successfully', {
      resource: `${__rootTc('report')} ${__rootTc('setting', 2)}`,
    }),
  })
}

export function downloadApi(moduleName, data) {
  return api
    .post(
      `analytics/download/${moduleName}/list`,
      transformDownloadForServer(data),
      {
        timeout: 0,
        responseType: 'blob',
        notify: false,
      }
    )
    .catch((error) => {
      if ((error.response || {}).status === 429) {
        errorToast(__rootTc('too_many_download_process_requests'))
      }
      if ((error.response || {}).status === 500) {
        const reader = new Response(error.response.data).text()
        reader.then((d) => {
          const message = JSON.parse(d).userMessage
          errorToast(message)
        })
      }
    })
}

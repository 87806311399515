import { getRootPluaralTranslator } from '@utils/get-module-translator'
const __rootTc = getRootPluaralTranslator()
export const deploymentDaysOptions = () => [
  { text: __rootTc('sunday'), key: 'SUNDAY' },
  { text: __rootTc('monday'), key: 'MONDAY' },
  { text: __rootTc('tuesday'), key: 'TUESDAY' },
  { text: __rootTc('wednesday'), key: 'WEDNESDAY' },
  { text: __rootTc('thursday'), key: 'THURSDAY' },
  { text: __rootTc('friday'), key: 'FRIDAY' },
  { text: __rootTc('saturday'), key: 'SATURDAY' },
]

export const FILESERVER_HOST_SUFFIX = '/fileserver'

<template>
  <div :id="item.name" class="flex flex-1 items-center min-w-0">
    <InlineEdit
      :field="$tc(type)"
      :rules="rules"
      :value="item.name"
      :item-style="{ display: 'flex', flex: 1, alignItems: 'center' }"
      class="min-w-0"
      @change="$emit('update', { ...item, name: $event })"
      @current-editing="$emit('current-editing', $event)"
    >
      <template v-slot:item-renderer="{ edit }">
        <ColorPicker
          v-if="useColor === true"
          id="color-picker"
          v-model="item.color"
          @change="$emit('update', { ...item, color: $event })"
        />
        <div class="item-title flex-1 mx-1 min-w-0">
          <div class="flex flex-col min-w-0">
            <div class="text-ellipsis">
              <MTooltip>
                <template v-slot:trigger>
                  {{ item.name }}
                </template>
                {{ item.name }}
              </MTooltip>
            </div>
            <small
              v-if="useDescription"
              class="text-ellipsis text-neutral"
              :title="item.description"
            >
              {{ item.description }}
            </small>
          </div>
        </div>
        <HoverableAction v-if="!disabled" :disabled="!isHoverableAction">
          <template v-slot="{ stickAction, unstickAction }">
            <slot
              v-if="!disabled"
              name="edit-action"
              :stick-action="stickAction"
              :unstick-action="unstickAction"
            >
              <MTooltip v-if="item.canEdit">
                <template v-slot:trigger>
                  <MIcon
                    id="edit-icon"
                    name="pencil"
                    size="lg"
                    class="mx-1 cursor-pointer text-neutral-light"
                    @click="edit"
                  />
                </template>
                {{ $t('edit') }}
              </MTooltip>
            </slot>
            <FlotoDeleteBtn
              v-if="item.canDelete && !item.default"
              id="delete-icon"
              class="mx-1"
              :message="
                $t('confirm_remove_item', {
                  item: `${$tc(type)}`,
                })
              "
              @trigger="stickAction"
              @cancel="unstickAction"
              @confirm="$emit('remove', item)"
            >
              <MIcon
                name="times-circle"
                class="text-secondary-red"
                size="lg"
              ></MIcon>
            </FlotoDeleteBtn>
          </template>
        </HoverableAction>
      </template>
    </InlineEdit>
    <MTooltip v-if="!hideDefaultAction">
      <a slot="trigger" href="javascript:;" class="text-neutral-light">
        <MSwitch
          id="default-switch"
          :disabled="item.default"
          size="small"
          class="mx-1"
          :checked="item.default"
          @change="$emit('update', { ...item, default: $event })"
        />
      </a>
      {{ $t('set_as') }} {{ $t('default') }}
    </MTooltip>
  </div>
</template>

<script>
import HoverableAction from '@components/hoverable-action'
import InlineEdit from '@components/inline-edit/inline-edit'
import ColorPicker from '@components/color-picker'

export default {
  name: 'Item',
  components: { InlineEdit, HoverableAction, ColorPicker },
  model: {
    event: 'change',
  },
  props: {
    item: { type: Object, required: true },
    hideDefaultAction: { type: Boolean, required: false },
    type: { type: String, default: 'item' },
    disabled: { type: Boolean, default: false },
    rules: { type: Object, default: undefined },
    useColor: { type: Boolean, default: false },
    useDescription: { type: Boolean, default: false },
    // eslint-disable-next-line
    isHoverableAction: { type: Boolean, default: true },
  },
  methods: {},
}
</script>

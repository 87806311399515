<template>
  <FlotoCrudContainer
    as-table
    prepend-new-item
    :fetch-fn="getPurchaseItem"
    :delete-fn="deleteProductVendor"
    :update-fn="updateProductVendor"
    :columns="tableColumns"
    :selectable="selectable"
    form-width="50%"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-if="searchable" v-slot:add-controls="{ refreshList }">
      <MRow class="mb-4" :gutter="0">
        <MCol :size="6">
          <MInput
            v-model="searchTerm"
            class="search-box"
            :placeholder="$t('search')"
            @update="refreshList"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
        </MCol>
      </MRow>
    </template>
    <template v-slot:form> </template>
    <template v-slot:productTypeName="{ item }">
      <td class="text-ellipsis">
        {{ item.productTypeName }}
      </td>
    </template>
    <template v-slot:manufacturerDisplayName="{ item }">
      <td class="text-ellipsis">
        {{ item.manufacturerDisplayName }}
      </td>
    </template>
    <template v-slot:warranty_period="{ item }">
      <td class="text-ellipsis">
        {{ item.year }} {{ $tc('years') }} {{ item.month }} {{ $tc('months') }}
      </td>
    </template>

    <template v-if="hasActions" v-slot:actions="{ item, remove }">
      <td class="text-ellipsis">
        <FlotoDeleteBtn
          :class="{ invisible: !item.canDelete }"
          :message="$t('confirm_delete_item', { item: $tc(type) })"
          @confirm="remove"
        />
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import {
  getLinkedProductsFromVendorIdApi,
  deleteProductVendorApi,
  updateProductVendorApi,
} from '@modules/asset-management/api/product-vendor-api'
export default {
  name: 'PurchaseItemList',
  props: {
    vendorId: { type: Number, required: true },
    currencyId: { type: Number, required: true },
    searchable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hasActions: { type: Boolean, default: false },
    excludeItemsById: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      tableColumns: [
        { name: `${this.$tc('id')}`, key: 'productName' },
        { name: `${this.$tc('name')}`, key: 'productDisplayName' },
        {
          name: `${this.$tc('product')} ${this.$tc('type')}`,
          key: 'productTypeName',
        },
        {
          name: `${this.$t('manufacturer')}`,
          key: 'manufacturerDisplayName',
        },
        {
          name: `${this.$t('price')}`,
          key: 'price',
        },
        {
          name: `${this.$t('tax_rate')} (%)`,
          key: 'taxRate',
        },
        {
          name: `${this.$t('warranty_period')}`,
          key: 'warranty_period',
        },
        ...(this.hasActions
          ? [
              {
                name: `${this.$tc('action', 2)}`,
                key: 'actions',
                width: '120px',
              },
            ]
          : []),
      ],
      searchTerm: '',
    }
  },
  methods: {
    getPurchaseItem(limit, offset) {
      return getLinkedProductsFromVendorIdApi(
        this.vendorId,
        this.currencyId,
        {
          displayName: this.searchTerm,
          excludeItemsByProductId: this.excludeItemsById,
        },
        limit,
        offset
      )
    },
    deleteProductVendor({ id }) {
      return deleteProductVendorApi(id, this.type)
    },
    updateProductVendor(data) {
      return updateProductVendorApi(data, this.type)
    },
  },
}
</script>

<template>
  <a @click="triggerAction">
    <!-- <FlotoLink :to="generateLink"> -->
    <MIcon name="cube" class="action-menu-icon" />
    <span class="ml-1 action-menu-text"> {{ title }} </span>
    <!-- </FlotoLink> -->
    <FlotoConfirmModal
      :open="showModal"
      prevent-auto-close-on-confirm
      @hide="showModal = false"
      @confirm="handleConfirmAction"
    >
      <template v-slot:icon>
        <MIcon
          name="exclamation-triangle"
          class="w-full text-secondary-yellow"
          size="2x"
        />
      </template>
      <template v-slot:message>
        {{ $t('confirm_deploy_patch') }}
      </template>
    </FlotoConfirmModal>
  </a>
</template>

<script>
export default {
  name: 'DeployAction',
  props: {
    takeConfirmation: { type: Boolean, default: false },
    title: {
      type: String,
      default() {
        return `${this.$t('deploy')} ${this.$tc('patch')}`
      },
    },
    action: { type: String, default: 'install' },
    patchId: {
      type: [String, Number],
      default: undefined,
    },
    patchIds: {
      type: Array,
      default: undefined,
    },
    agentId: {
      type: [String, Number],
      default: undefined,
    },
    agentIds: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    generateLink() {
      this.$emit('done')
      return this.$modules.getModuleRoute('remote-deployment', 'create', {
        params: {
          configType: this.$constants.PATCH_REMOTE_DEPLOYMENT,
          patchId: this.patchId,
          patchIds: this.patchIds,
          agentId: this.agentId,
          agentIds: this.agentIds,
          action: this.action,
        },
      })
    },
  },
  methods: {
    handleConfirmAction() {
      this.$emit('done')
      this.showModal = false
      return this.$router.push(
        this.$modules.getModuleRoute('remote-deployment', 'create', {
          params: {
            configType: this.$constants.PATCH_REMOTE_DEPLOYMENT,
            patchId: this.patchId,
            patchIds: this.patchIds,
            agentId: this.agentId,
            agentIds: this.agentIds,
            action: this.action,
          },
        })
      )
    },
    triggerAction() {
      if (this.action === 'install') {
        setTimeout(() => {
          this.showModal = true
        }, 400)
      } else {
        this.handleConfirmAction()
      }
      this.$emit('trigger')
    },
  },
}
</script>

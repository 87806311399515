import api from '@api'
import SortBy from 'lodash/sortBy'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { transformSource, transformSourceForServer } from '@data/source'
import { isLoggedIn } from '@utils/auth'
import { buildFlatQualificationStructure } from '@/src/data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function searchSourceApi({ quals }, params = {}) {
  return api
    .post(
      `${!isLoggedIn() ? '/public' : ''}/source/search/byqual`,
      quals
        ? {
            qualDetails: buildFlatQualificationStructure(
              [...(quals || [])].filter(Boolean)
            ),
          }
        : {},
      {
        params: {
          offset: 0,
          size: 1000,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) =>
      SortBy(data.objectList || [], 'objOrder').map(transformSource)
    )
}

export function createSourcesApi(data) {
  const requestData = (data || []).map(transformSourceForServer)
  return api
    .post('source/bulk/create', requestData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('source'),
      }),
    })
    .then((data) => data.map(transformSource))
}

export function removeSourceApi(data) {
  return api.delete(`source/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('source'),
    }),
  })
}

export function updateSourceApi(data) {
  const updateData = transformSourceForServer(data)
  return api
    .patch(`source/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('source'),
      }),
    })
    .then(transformSource)
}

export function updateSourcesApi(data) {
  const patchData = {}
  data.map((c, index) => {
    patchData[c.id] = { name: c.name, objOrder: index + 1 }
  })
  return api.patch(`source/bulk/update`, patchData, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('source', 2),
    }),
  })
}

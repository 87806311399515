<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { PageMethods } from '@state/modules/page'
import { isLoggedIn, isCxoUser } from '@utils/auth'
export default {
  name: 'KnowledgeModule',
  page() {
    return {
      title: this.$tc('knowledge'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // add any module level clean up logic here

    next((vm) => {
      if (isLoggedIn() && isCxoUser()) {
        return vm.$router.replace({ name: '404' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      this.knowledgePageReset()
    }, 400)
    next()
  },
  methods: {
    ...PageMethods,
  },
}
</script>

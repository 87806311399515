<template>
  <FlotoContentLoader :loading="purchaseLoading" :height="600">
    <FlotoForm ref="formRef" @submit="handleFormSubmitted">
      <MRow :gutter="0">
        <MCol :size="12">
          <MRow :gutter="0" class="flex-1">
            <MCol v-if="!Boolean(formValue.id)" :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    :label="$tc('requester')"
                    rules="required|nonzero"
                  >
                    <FlotoTechnicianPicker
                      v-model="formValue.requesterId"
                      as-input
                      mandatory-selection
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    v-model="formValue.displayName"
                    :label="$t('name')"
                    :placeholder="$t('name')"
                    rules="required|short_text"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    v-model="formValue.orderNumber"
                    :label="`${$tc('order')} ${$tc('number')}`"
                    :placeholder="`${$tc('order')} ${$tc('number')}`"
                    rules="max:100"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem
                    :label="`${$tc('required_by')}`"
                    rules="required|nonzero"
                  >
                    <FlotoDatePicker
                      v-model="formValue.requiredByTime"
                      :min-date="
                        Moment().add(1, 'day').endOf('day').unix() * 1000
                      "
                      :show-time="false"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6">
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <div :style="{ position: 'relative' }">
                    <ProductCatalogListDrawer
                      :style="
                        isRtl
                          ? { position: 'absolute', left: '0', zIndex: '9' }
                          : { position: 'absolute', right: '0', zIndex: '9' }
                      "
                    />
                  </div>
                  <FlotoFormItem
                    :label="`${$tc('vendor')}`"
                    rules="required|nonzero"
                  >
                    <VendorPicker
                      v-model="formValue.vendorId"
                      allow-clear
                      as-input
                      @selected="($event) => (selectedVendor = $event)"
                    />
                    <span v-if="selectedVendor" class="text-neutral-light">
                      <template v-if="selectedVendor.email">
                        <span class="md-text-xs"> {{ $tc('email') }}: </span>
                        <span class="text-primary">
                          {{ selectedVendor.email }}
                        </span>
                      </template>
                      <template v-if="selectedVendor.contactNo">
                        <span class="md-text-xs">
                          {{ $tc('contact_no') }}:
                        </span>
                        <span class="text-primary">
                          {{ selectedVendor.contactNo }}
                        </span>
                      </template>
                    </span>
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol>
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <FlotoFormItem :label="`${$tc('currency')}`" rules="required">
                    <CurrencyPicker
                      v-model="formValue.currencyId"
                      :allow-clear="false"
                      @change="handleCurrencyChange"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="12">
              <FlotoFormItem
                class="mt-2"
                :validation-label="`${$tc('order')} ${$tc('item', 2)}`"
                rules="required"
              >
                <OrderItemSection
                  v-model="formValue"
                  :custom-form-fields="availablePriceField"
                  :vendor-id="formValue.vendorId"
                  :hidden-columns="[
                    'remainingQuantity',
                    'receivedQuantity',
                    'assetTypeId',
                  ]"
                />
              </FlotoFormItem>
            </MCol>
            <MDivider
              v-if="(formValue.itemList || []).length > 0"
              class="mt-0 mb-2"
            />
            <MCol :size="6" class="mt-2">
              <MRow :gutter="0">
                <MCol class="mx-2 flex justify-between">
                  <h5 class="text-neutral-light">
                    {{ $tc('shipping') }} {{ $tc('address') }}
                  </h5>
                  <div>
                    <AddressDrawer
                      @selected="handleAddAddress($event, 'shipping')"
                    />
                  </div>
                </MCol>
                <MDivider class="mt-0 mb-2" />
              </MRow>
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <AddressForm
                    v-model="formValue.shippingAddress"
                    type="shipping"
                    :mandatory-fields="['street', 'city', 'state', 'country']"
                  />
                </MCol>
              </MRow>
            </MCol>
            <MCol :size="6" class="mt-2">
              <MRow :gutter="0">
                <MCol class="ml-2 flex justify-between">
                  <h5
                    class="text-neutral-light flex justify-between cursor-pointer items-center"
                  >
                    {{ $tc('billing') }} {{ $tc('address') }}
                    <FlotoFormItem :style="{ margin: 0, padding: 0 }">
                      <MCheckbox v-model="formValue.copyToBilling" class="mx-3">
                        <span class="md-text-sm font-light">
                          {{ $tc('same_as_shipping') }}
                        </span>
                      </MCheckbox>
                    </FlotoFormItem>
                  </h5>
                  <div v-if="!formValue.copyToBilling">
                    <AddressDrawer
                      @selected="handleAddAddress($event, 'billing')"
                    />
                  </div>
                </MCol>
                <MDivider class="mt-0 mb-2" />
              </MRow>
              <MRow :gutter="0">
                <MCol class="mx-2">
                  <AddressForm
                    v-model="formValue.billingAddress"
                    type="billing"
                    :mandatory-fields="['street', 'city', 'state', 'country']"
                  />
                </MCol>
              </MRow>
            </MCol>
          </MRow>
          <MCol :size="12">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem :label="$tc('description')">
                  <FlotoRichEditor
                    v-model="formValue.description"
                    :affix-toolbar="false"
                    :min-rows="8"
                    :placeholder="$tc('description')"
                  />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol :size="12">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem
                  v-model="formValue.terms"
                  rules="description"
                  :label="`${$tc('terms')}`"
                  :rows="3"
                  :placeholder="$t('terms')"
                  type="textarea"
                />
              </MCol>
            </MRow>
          </MCol>
          <MCol v-if="!Boolean(formValue.id)" :size="6">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem :label="$tc('owner')">
                  <FlotoTechnicianPicker
                    v-model="formValue.technicianId"
                    as-input
                    mandatory-selection
                  />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol v-if="!Boolean(formValue.id)" :size="6">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem :label="$tc('cost_center')">
                  <CostCenterPicker v-model="formValue.costCenter" as-input />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol v-if="!Boolean(formValue.id)" :size="6">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem :label="$tc('gl_code')">
                  <GlCodePicker v-model="formValue.glCode" as-input />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol v-if="!Boolean(formValue.id)" :size="12">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem :label="$tc('tag', 2)">
                  <FlotoTagsPicker v-model="formValue.tags" />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol
            v-if="regularCustomFields.length > 0 && !Boolean(formValue.id)"
            :size="12"
          >
            <MRow :gutter="0">
              <FormConsumer
                v-if="regularCustomFields.length > 0"
                v-model="formValue.fieldValueDetails"
                :form-fields="regularCustomFields"
                :avoid-default-value="Boolean(formValue.id)"
                :with-submit="false"
              />
            </MRow>
          </MCol>
          <MCol :size="12">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <FlotoFormItem :label="$tc('attachment', 2)">
                  <FlotoAttachment v-model="formValue.fileAttachments" />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol :size="12">
            <MRow>
              <MCol class="mx-2" :size="6">
                <FlotoFormItem :label="`${$tc('print')} ${$tc('template')}`">
                  <PrintTemplatePicker
                    v-model="formValue.printTemplateId"
                    mandatory
                    as-input
                    :module-name="$constants.PURCHASE"
                    :allow-clear="false"
                  />
                </FlotoFormItem>
              </MCol>
            </MRow>
          </MCol>
          <MCol :size="6">
            <MRow :gutter="0">
              <MCol class="mx-2">
                <SigningAuthorityMapping
                  v-model="formValue.signingAuthorities"
                  :module-name="moduleName"
                />
              </MCol>
            </MRow>
          </MCol>
        </MCol>
      </MRow>
      <MDivider class="mt-0" />
      <template v-slot:submit>
        <span />
      </template>
    </FlotoForm>
  </FlotoContentLoader>
</template>

<script>
import Moment from 'moment'
import { authComputed } from '@state/modules/auth'
import { LicenseComputed } from '@state/modules/license'
import { FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import { PreferenceComputed } from '@state/modules/preference'
import VendorPicker from '@components/data-picker/vendor-picker'
import AddressForm from '@components/address-form.vue'
import CostCenterPicker from '@components/data-picker/cost-center-picker'
import GlCodePicker from '@components/data-picker/gl-code-picker'
import OrderItemSection from './form-components/order-item-section'
import { calculateTotal } from '../helpers/utils'
import CurrencyPicker from '@components/data-picker/currency-picker'
import PrintTemplatePicker from '@components/data-picker/print-template-picker'
import AddressDrawer from '@modules/purchase/components/address-drawer.vue'
import SigningAuthorityMapping from '../components/signing-authority-mapping'
import ProductCatalogListDrawer from '../components/product-catalog-list-drawer.vue'

export default {
  name: 'PurchaseForm',
  components: {
    FormConsumer,
    VendorPicker,
    OrderItemSection,
    AddressForm,
    CostCenterPicker,
    GlCodePicker,
    CurrencyPicker,
    PrintTemplatePicker,
    AddressDrawer,
    SigningAuthorityMapping,
    ProductCatalogListDrawer,
  },
  model: {
    event: 'change',
  },
  props: {
    useTemplate: { type: Boolean, default: false },
    // eslint-disable-next-line
    withSubmit: { type: Boolean, default: true },
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    this.Moment = Moment
    return {
      moduleName: this.$constants.PURCHASE,
      purchase: Object.assign({}, { ...this.defaultValue, ...this.value }),
      selectedVendor: null,
      billingAddressKey: 1,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...FormComputed,
    ...authComputed,
    formValue: {
      get() {
        if (this.withSubmit) {
          return this.purchase
        }
        return this.value
      },
      set(value) {
        if (this.withSubmit) {
          this.purchase = {
            ...value,
            ...((value.itemList || []).length
              ? calculateTotal(value, this.availablePriceField)
              : {}),
          }
        } else {
          this.$emit('change', {
            ...value,
            ...((value.itemList || []).length
              ? calculateTotal(value, this.availablePriceField)
              : {}),
          })
        }
      },
    },
    customFormFields() {
      return this[`${this.moduleName}Fields`]({
        exclude: [...(this.useTemplate ? [] : ['template'])],
      })
    },
    priceCustomFields() {
      if (this.purchase.id) {
        return this.customFormFields.filter(
          (f) => f.attributes.costField === 'true'
        )
      }
      return this.customFormFields.filter(
        (f) => f.attributes.costField === 'true' && !f.hidden
      )
    },
    availablePriceField() {
      if (this.purchase.id) {
        const fieldValueDetails = this.purchase.fieldValueDetails || {}
        return this.priceCustomFields.filter((f) => {
          if (f.hidden) {
            return fieldValueDetails[f.id]
          }
          return f
        })
      }
      return this.priceCustomFields
    },
    regularCustomFields() {
      return this.customFormFields.filter(
        (f) => !f.attributes.costField || f.attributes.costField === 'false'
      )
    },
  },
  watch: {
    'formValue.vendorId': {
      handler() {
        this.formValue = {
          ...this.formValue,
          itemList: [],
        }
      },
    },
    'formValue.copyToBilling': {
      immediate: true,
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.formValue = {
            ...this.formValue,
            billingAddress: this.formValue.shippingAddress,
          }
        } else {
          const billing = Object.assign(
            {},
            { ...this.formValue.billingAddress }
          )
          this.formValue = {
            ...this.formValue,
            shippingAddress: { ...this.formValue.shippingAddress },
            billingAddress: billing,
          }
        }
      },
    },
  },
  methods: {
    handleFormSubmitted() {
      const requestedData = this.formValue
      // delete following property
      delete requestedData.invoiceReceived
      delete requestedData.paymentStatus
      // delete spread custom field values
      Object.keys(requestedData).forEach((paramName) => {
        if (/^\d+$/.test(paramName)) {
          delete requestedData[paramName]
        }
      })
      // delete on update
      if (requestedData.id) {
        delete requestedData.requesterId
        delete requestedData.technicianId
        delete requestedData.costCenter
        delete requestedData.glCode
        delete requestedData.tags
      }
      this.$emit('submit', {
        ...requestedData,
      })
    },
    submit() {
      this.$refs.formRef.submit()
    },
    handleCurrencyChange() {
      this.formValue = {
        ...this.formValue,
        itemList: [],
      }
    },
    handleAddAddress(selectedItems, type) {
      if (selectedItems) {
        const addressData = {
          city: selectedItems.city,
          country: selectedItems.country,
          landmark: selectedItems.landmark,
          state: selectedItems.state,
          pinCode: selectedItems.zipCode,
          street: selectedItems.streetLine,
        }
        if (type === 'shipping') {
          this.formValue = {
            ...this.formValue,
            shippingAddress: addressData,
            billingAddress: this.formValue.copyToBilling
              ? addressData
              : this.formValue.billingAddress,
          }
        } else if (type === 'billing') {
          this.formValue = {
            ...this.formValue,
            billingAddress: addressData,
          }
        }
      }
    },
  },
}
</script>

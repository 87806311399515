<template>
  <SortableListItem
    use-separator
    :active="active"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <div class="flex flex-1 cursor-pointer min-w-0">
      <div class="text-ellipsis">
        {{ folder.name }}
      </div>
    </div>
    <div class="inline-flex">
      <HoverableAction v-if="!disabled">
        <template v-slot="{ stickAction, unstickAction }">
          <MTooltip v-if="folder.canEdit">
            <template v-slot:trigger>
              <MIcon
                name="pencil"
                size="lg"
                class="mx-2 cursor-pointer text-neutral-light"
                @click="$emit('edit')"
              />
            </template>
            {{ $t('edit') }}
          </MTooltip>
          <FlotoDeleteBtn
            v-if="folder.canDelete"
            max-width="400px"
            :message="$t('confirm_archive_service_category')"
            @trigger="stickAction"
            @cancel="unstickAction"
            @confirm="$emit('remove', folder)"
          >
            <MIcon
              name="times-circle"
              class="text-secondary-red"
              size="lg"
            ></MIcon>
          </FlotoDeleteBtn>
        </template>
      </HoverableAction>
    </div>
  </SortableListItem>
</template>

<script>
import HoverableAction from '@components/hoverable-action'
import SortableListItem from '@components/sortable/sortable-list-item'

export default {
  name: 'ServiceCategoryItem',
  components: { HoverableAction, SortableListItem },
  props: {
    folder: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
  },
}
</script>

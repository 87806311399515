<template>
  <div class="flex items-center text-neutral-light justify-around flex-1">
    <div class="inline-flex items-center">
      <MIcon name="thumbs-up" class="mx-1 text-secondary-green" size="lg" />
      <span class="md-text-lg">{{ positiveFeedback }}</span>
    </div>
    <div class="inline-flex items-center ml-2">
      <MIcon name="thumbs-down" class="mx-1 text-secondary-red" size="lg" />
      <span class="md-text-lg">
        {{ negativeFeedback }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackCount',
  props: {
    positiveFeedback: { type: Number, default: 0 },
    negativeFeedback: { type: Number, default: 0 },
  },
}
</script>

<template>
  <div
    class="flex flex-col service-catalog-item mb-4 p-2 rounded bg-neutral-lightest"
    :class="{ active }"
  >
    <component
      :is="avoidRedirection ? 'div' : disabled ? 'FlotoLink' : 'div'"
      v-bind="
        disabled
          ? {
              to: $modules.getModuleRoute(
                isPortalLogin ? 'support-portal' : 'service-catalog',
                'request-service',
                {
                  params: {
                    id: service.id,
                    ...(searchTerm ? { searchTerm } : {}),
                    ...(filterType ? { filterType } : {}),
                  },
                }
              ),
            }
          : {}
      "
      class="cursor-pointer"
      @click="$emit('service-click', service)"
    >
      <HoverAction v-if="!disabled" class="flex justify-end">
        <template v-slot="{ stickAction, unstickAction }">
          <MTooltip>
            <template v-slot:trigger>
              <a @click="$emit('clone')">
                <MIcon
                  :id="`duplicate-btn-${service.name}`"
                  name="clone"
                  class="text-neutral-light"
                />
              </a>
            </template>
            {{ $tc('duplicate') }}
          </MTooltip>
          <FlotoLink
            :to="
              $modules.getModuleRoute(
                isPortalLogin ? 'support-portal' : 'service-catalog',
                'edit',
                {
                  params: {
                    id: service.id,
                    ...(searchTerm ? { searchTerm } : {}),
                    ...(filterType ? { filterType } : {}),
                  },
                }
              )
            "
            class="mx-1"
          >
            <MTooltip>
              <template v-slot:trigger>
                <MIcon
                  :id="`edit-btn-${service.name}`"
                  name="pencil"
                  class="text-neutral-light"
                />
              </template>
              {{ $t('edit') }}
            </MTooltip>
          </FlotoLink>
          <FlotoDeleteBtn
            :message="$t('confirm_delete_item', { item: $tc('service') })"
            @confirm="$emit('remove')"
            @trigger="persistHover(stickAction)"
            @cancel="unpersistHover(unstickAction)"
          >
            <MIcon
              :id="`delete-btn-${service.name}`"
              name="times-circle"
              class="text-neutral-light"
            />
          </FlotoDeleteBtn>
        </template>
      </HoverAction>
      <div v-else class="favourite-items">
        <MTooltip v-if="loggedIn">
          <template v-slot:trigger>
            <a @click.stop.prevent="$emit('favourite')">
              <MIcon
                name="star"
                :type="isFavourite ? 'fas' : 'fal'"
                class="favorite-icon"
              />
            </a>
          </template>
          {{ $tc('favourite') }}
        </MTooltip>
      </div>
      <component
        :is="avoidRedirection ? 'div' : !disabled ? 'FlotoLink' : 'div'"
        class="flex items-center justify-center mb-2"
        :class="{ 'mt-2': disabled }"
        v-bind="
          !disabled
            ? {
                to: $modules.getModuleRoute(
                  isPortalLogin ? 'support-portal' : 'service-catalog',
                  'edit',
                  {
                    params: {
                      id: service.id,
                      ...(searchTerm ? { searchTerm } : {}),
                      ...(filterType ? { filterType } : {}),
                    },
                  }
                ),
              }
            : {}
        "
      >
        <img :src="service.image" height="100" width="100" />
      </component>
      <div class="flex flex-col justify-center items-center">
        <div class="w-full flex flex-col">
          <MTooltip>
            <template v-slot:trigger>
              <h5 class="m-0 text-ellipsis text-center">{{ service.name }}</h5>
            </template>
            {{ service.name }}
          </MTooltip>
          <small class="text-neutral-light text-ellipsis text-center">
            {{ categoryName }}
          </small>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { CategoryComputed } from '@state/modules/category'
import HoverAction from '@components/hoverable-action'

export default {
  name: 'ServiceCatalogItem',
  components: { HoverAction },
  props: {
    service: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    avoidRedirection: { type: Boolean, default: false },
    isFavourite: { type: Boolean, default: false },
    searchTerm: { type: String, default: undefined },
    filterType: { type: String, default: undefined },
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    ...authComputed,
    ...CategoryComputed,
    categoryName() {
      const category = this.serviceCatalogCategories.find(
        (c) => c.id === this.service.categoryId
      )
      return category ? category.name : '---'
    },
  },
  methods: {
    unpersistHover(fn) {
      this.active = false
      fn()
    },
    persistHover(fn) {
      this.active = true
      fn()
    },
  },
}
</script>

<style lang="less" scoped>
.service-catalog-item {
  transition: box-shadow 0.2s linear;

  // @apply relative;

  // .item-action {
  //   top: 10px;
  //   right: 10px;

  //   @apply absolute;
  // }
  .item-action {
    text-align: right;

    @apply my-1;
  }

  &:hover,
  &.active {
    @apply shadow-lg;

    .item-action {
      opacity: 1;
    }
  }

  .favorite-icon {
    color: var(--primary);
  }
}
</style>

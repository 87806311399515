import api from '@api'
import {
  transformLeaveType,
  transformLeaveTypeForServer,
} from '@data/leave-type'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  // getModuleTranslator,
} from '@/src/utils/get-module-translator'

// import config from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
export function getLeaveTypesApi(pageSize, offset, params = {}) {
  return api
    .post(
      '/leavetype/search/byqual',
      {},
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformLeaveType),
        total: data.totalCount,
      }
    })
}

export function createLeaveTypeApi(data) {
  const updateData = transformLeaveTypeForServer(data)
  return api
    .post('/leavetype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('leave_type'),
      }),
    })
    .then(transformLeaveType)
}

export function removeLeaveTypeApi(data) {
  return api.delete(`/leavetype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('leave_type'),
    }),
  })
}

export function updateLeaveTypeApi(data) {
  const updateData = transformLeaveTypeForServer(data)
  return api
    .patch(`/leavetype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('leave_type'),
      }),
    })
    .then(transformLeaveType)
}

import api from '@api'
import SortBy from 'lodash/sortBy'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

import {
  transformRiskType,
  transformRiskTypeForServer,
  transformProjectType,
  transformProjectTypeForServer,
} from '@data/project-management'
// import moduleConfig from './config'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// export function project-managementApi() {
//   // @TODO add api call here
// }
const buildQualification = ({ name, selectedName, quals }) => {
  return {
    ...(name || selectedName
      ? {
          qualDetails: buildFlatQualificationStructure(
            [
              name && buildNameFilterQuery(name),
              selectedName && buildNameFilterQuery(selectedName),
              ...(quals || []),
            ].filter(Boolean)
          ),
        }
      : {}),
  }
}
export function getRiskTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset
) {
  return api
    .post(
      '/project/risktype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformRiskType), total: data.totalCount }
    })
}

export function createRiskTypeApi(data) {
  const updateData = transformRiskTypeForServer(data)
  return api
    .post('/project/risktype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('project_risk'),
      }),
    })
    .then(transformRiskType)
}

export function removeRiskTypeApi(data) {
  return api.delete(`/project/risktype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('project_risk'),
    }),
  })
}

export function updateRiskTypeApi(data) {
  const updateData = transformRiskTypeForServer(data)
  return api
    .patch(`/project/risktype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('project_risk'),
      }),
    })
    .then(transformRiskType)
}

// project type apis

export function getProjectTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset,
  params = {}
) {
  return api
    .post(
      '/project/projecttype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformProjectType), total: data.totalCount }
    })
}

export function createProjectTypeApi(data) {
  const updateData = transformProjectTypeForServer(data)
  return api
    .post('/project/projecttype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('project_type'),
      }),
    })
    .then(transformProjectType)
}

export function removeProjectTypeApi(data) {
  return api.delete(`/project/projecttype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('project_type'),
    }),
  })
}

export function updateProjectTypeApi(data) {
  const updateData = transformProjectTypeForServer(data)
  return api
    .patch(`/project/projecttype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('project_type'),
      }),
    })
    .then(transformProjectType)
}

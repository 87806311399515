<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import { PageMethods } from '@state/modules/page'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'PurchaseModule',
  page() {
    return {
      title: this.$t('purchase'),
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name.indexOf('create') >= 0) {
      return next((vm) => {
        if (vm.availableModulesInLicense.indexOf(Constants.PURCHASE) === -1) {
          return vm.$router.replace({ name: 'upgrade-plan' })
        }
        if (vm.myAllowedModules.indexOf(Constants.PURCHASE) === -1) {
          return vm.$router.replace({ name: '404' })
        }
      })
    }
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf(Constants.PURCHASE) === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (vm.myAllowedModules.indexOf(Constants.PURCHASE) === -1) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.purchaseFetchNoteRules) {
        vm.purchaseFetchNoteRules()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      if (this.purchasePageReset) {
        this.purchasePageReset()
      }
    }, 400)
    next()
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  methods: {
    ...PageMethods,
    ...CustomRulesMethods,
  },
}
</script>

import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __rootTc = getRootPluaralTranslator()

export const SupportPortalPermissionOptions = () => {
  return [
    { text: __rootTc('public'), key: 'all_requester_user' },
    { text: __rootTc('all_logged_in_users'), key: 'all_logged_user' },
    { text: __rootTc('requester_group', 2), key: 'requester_group' },
  ]
}

export const TechnicianPortalPermissionOptions = () => {
  return [
    {
      text: `${__rootTc('all')} ${__rootTc('technician', 2)}`,
      key: 'all_technician',
    },
    { text: __rootTc('technician_group', 2), key: 'technician_group' },
  ]
}

<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'ReportsModule',
  page() {
    return {
      title: this.$tc('report', 2),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (vm.myAllowedModules.indexOf(vm.$constants.REPORT) === -1) {
        return vm.$router.replace({ name: '404' })
      }
      return true
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  computed: {
    ...PreferenceComputed,
  },
}
</script>

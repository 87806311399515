import Modular from '@plugins/modular'
import store from '@state/store'
import availableModules from './available-modules'

// create module manager with auth as upfront module

/** @var {import('@/src/plugins/modular/manager')} */
const modules = new Modular(availableModules, {
  store,
  moduleLoader: (moduleName) => import(`@modules/${moduleName}/${moduleName}`),
})

export default modules

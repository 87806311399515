<template>
  <MRow :gutter="0">
    <MCol :size="12">
      <MRow v-if="!disabled" :gutter="0">
        <MCol class="mx-2 flex">
          <h5 class="text-neutral-light">
            {{ $tc('order') }} {{ $tc('item', 2) }}
          </h5>
          <PurchaseItemDrawer
            v-if="(value.itemList || []).length < allowedMaxItem"
            class="flex-1 mx-3"
            :vendor-id="vendorId"
            :currency-id="value.currencyId"
            :default-selected="value.itemList"
            :allowed-max-item="allowedMaxItem"
            @selection-change="handleSectionChange"
          />
        </MCol>
      </MRow>
      <MDivider class="mt-0 mb-2" />
      <table
        v-if="purchaseItems.length > 0"
        :style="{ borderCollapse: 'separate' }"
        class="item-list-table w-full order-item-table"
      >
        <thead>
          <tr>
            <td
              v-for="column in tableColumns"
              :key="column.name"
              :width="column.width ? column.width : undefined"
              :style="column.style ? column.style : undefined"
            >
              <span v-if="column.name === 'Price'" class="flex">
                {{ column.name }}
                (<CurrencyPicker
                  v-model="value.currencyId"
                  text-only
                  disabled
                  style="white-space: nowrap"
                />)
              </span>
              <span v-else>{{ column.name }}</span>
            </td>
          </tr>
        </thead>
        <MultipleFormItem v-model="purchaseItems" as-table :can-add="false">
          <template
            v-slot="{
              item,
              remove,
              add,
              isLastItem,
              isFirstItem,
              update,
              index,
            }"
          >
            <PurchaseItemRow
              :key="item.guid"
              :item="item"
              :item-index="index"
              :remove="remove"
              :update="update"
              :disabled="disabled"
              :hidden-columns="hiddenColumns"
              :can-editeceived-quantity="canEditeceivedQuantity"
            />
          </template>
          <template
            v-if="!hideTotal && purchaseItems.length > 0"
            v-slot:additional-rows
          >
            <tr>
              <td :colspan="totalAmountColspan" class="border-none">
                <div class="flex justify-end items-center">
                  <div class="flex mr-2 title">
                    {{ `${$tc('sub_total')}` }}
                    (<CurrencyPicker
                      v-model="value.currencyId"
                      text-only
                      disabled
                      style="white-space: nowrap"
                    />)
                  </div>
                  <FlotoFormItem
                    v-if="!disabled"
                    v-model="value.subTotal"
                    style="width: 350px"
                    disabled
                    type="number"
                    :precision="2"
                    class="mb-0"
                    :validation-label="$tc('sub_total')"
                    :placeholder="$tc('sub_total')"
                  />
                  <div
                    v-else
                    :title="(value.subTotal || 0).toFixed(2)"
                    class="disabled-amount font-semibold"
                  >
                    {{ (value.subTotal || 0).toFixed(2) }}
                  </div>
                </div>
              </td>
              <td v-if="!disabled" class="border-none"> </td>
            </tr>
            <tr>
              <td :colspan="totalAmountColspan" class="border-none">
                <div class="flex justify-end items-center">
                  <small class="mr-2"> - {{ $tc('discount') }} (%) </small>
                  <template v-if="!disabled">
                    <FlotoFormItem
                      v-model="discount"
                      style="width: 100px"
                      type="number"
                      :min="0"
                      :max="100"
                      :precision="2"
                      class="mb-0"
                      :validation-label="$tc('discount')"
                      :placeholder="$tc('discount')"
                    />
                    <FlotoFormItem
                      v-model="value.discountAmount"
                      style="width: 250px"
                      disabled
                      type="number"
                      :min="0"
                      :precision="2"
                      class="mb-0"
                      :validation-label="$tc('discount')"
                      :placeholder="$tc('discount')"
                    />
                  </template>
                  <div
                    v-else
                    :title="`(${(value.discount || 0).toFixed(2)}%) ${(
                      value.discountAmount || 0
                    ).toFixed(2)}`"
                    class="disabled-amount"
                  >
                    ({{ (value.discount || 0).toFixed(2) }}%)
                    {{ (value.discountAmount || 0).toFixed(2) }}
                  </div>
                </div>
              </td>
              <td v-if="!disabled" class="border-none"> </td>
            </tr>
            <tr>
              <td :colspan="totalAmountColspan" class="border-none">
                <div class="flex justify-end items-center">
                  <div class="mr-2 title flex">
                    {{ $tc('total_net') }} (
                    <CurrencyPicker
                      v-model="value.currencyId"
                      text-only
                      disabled
                      style="white-space: nowrap"
                    />
                    )
                  </div>
                  <FlotoFormItem
                    v-if="!disabled"
                    v-model="value.totalNet"
                    style="width: 350px"
                    disabled
                    type="number"
                    :min="0"
                    :precision="2"
                    class="mb-0"
                    :validation-label="$tc('total_net')"
                    :placeholder="$tc('total_net')"
                  />
                  <div
                    v-else
                    :title="(value.totalNet || 0).toFixed(2)"
                    class="disabled-amount font-semibold"
                  >
                    {{ (value.totalNet || 0).toFixed(2) }}
                  </div>
                </div>
              </td>
              <td v-if="!disabled" class="border-none"> </td>
            </tr>
            <tr>
              <td :colspan="totalAmountColspan" class="border-none">
                <div class="flex justify-end items-center">
                  <small class="mr-2 flex">
                    + {{ $tc('shipping') }} {{ $tc('charges') }} (
                    <CurrencyPicker
                      v-model="value.currencyId"
                      text-only
                      disabled
                      style="white-space: nowrap"
                    />
                    )
                  </small>
                  <FlotoFormItem
                    v-if="!disabled"
                    v-model="shippingCost"
                    style="width: 350px"
                    type="number"
                    :min="0"
                    :max="100000"
                    :precision="2"
                    class="mb-0"
                    :validation-label="`${$tc('shipping')} ${$tc('charges')}`"
                    :placeholder="`${$tc('shipping')} ${$tc('charges')}`"
                  />
                  <div
                    v-else
                    :title="(value.shippingCost || 0).toFixed(2)"
                    class="disabled-amount"
                  >
                    {{ (value.shippingCost || 0).toFixed(2) }}
                  </div>
                </div>
              </td>
              <td v-if="!disabled" class="border-none"> </td>
            </tr>
            <tr v-for="field in customFormFields" :key="field.id">
              <td :colspan="totalAmountColspan" class="border-none">
                <div class="flex justify-end items-center">
                  <small class="mr-2">
                    {{ field.attributes.operation === 'subtract' ? '-' : '+' }}
                    {{ field.title }} {{ field.percentageField ? '(%)' : '' }}
                  </small>
                  <template v-if="!disabled">
                    <FlotoFormItem
                      v-if="field.percentageField"
                      :value="
                        customFieldValue[
                          field.isSystemField ? field.paramName : field.id
                        ]
                      "
                      :disabled="field.hidden"
                      style="width: 100px"
                      type="number"
                      :min="0"
                      :max="100"
                      :precision="2"
                      class="mb-0"
                      :validation-label="field.title"
                      :placeholder="field.title"
                      @update="
                        handleCustomFieldUpdate({
                          [field.isSystemField ? field.paramName : field.id]:
                            $event,
                        })
                      "
                    />
                    <FlotoFormItem
                      :style="{
                        width: field.percentageField ? '250px' : '350px',
                      }"
                      :value="value[`${field.id}Amount`]"
                      :disabled="field.percentageField || field.hidden"
                      type="number"
                      :min="0"
                      :precision="2"
                      class="mb-0"
                      :validation-label="field.title"
                      :placeholder="field.title"
                      @update="
                        field.percentageField
                          ? undefined
                          : handleCustomFieldUpdate({
                              [field.isSystemField
                                ? field.paramName
                                : field.id]: $event,
                            })
                      "
                    />
                  </template>
                  <div
                    v-else
                    :title="
                      field.percentageField
                        ? `(${(
                            customFieldValue[
                              field.isSystemField ? field.paramName : field.id
                            ] || 0
                          ).toFixed(2)}%) ${(
                            value[`${field.id}Amount`] || 0
                          ).toFixed(2)}`
                        : (value[`${field.id}Amount`] || 0).toFixed(2)
                    "
                    class="disabled-amount"
                  >
                    <template v-if="field.percentageField">
                      ({{
                        (
                          customFieldValue[
                            field.isSystemField ? field.paramName : field.id
                          ] || 0
                        ).toFixed(2)
                      }}%)
                    </template>
                    {{ (value[`${field.id}Amount`] || 0).toFixed(2) }}
                  </div>
                </div>
              </td>
              <td v-if="!disabled" class="border-none"> </td>
            </tr>
            <tr>
              <td :colspan="totalAmountColspan" class="border-none">
                <div class="flex justify-end items-center">
                  <div class="mr-2 title flex">
                    {{ $tc('total') }} {{ $tc('cost') }} (
                    <CurrencyPicker
                      v-model="value.currencyId"
                      text-only
                      disabled
                      style="white-space: nowrap"
                    />
                    )
                  </div>
                  <FlotoFormItem
                    v-if="!disabled"
                    v-model="value.totalCost"
                    style="width: 350px"
                    disabled
                    type="number"
                    :min="0"
                    :precision="2"
                    class="mb-0"
                    :validation-label="`${$tc('total')} ${$tc('cost')}`"
                    :placeholder="`${$tc('total')} ${$tc('cost')}`"
                  />
                  <div
                    v-else
                    :title="(value.totalCost || 0).toFixed(2)"
                    class="disabled-amount font-semibold"
                  >
                    {{ (value.totalCost || 0).toFixed(2) }}
                  </div>
                </div>
              </td>
              <td v-if="!disabled" class="border-none"> </td>
            </tr>
          </template>
        </MultipleFormItem>
      </table>
    </MCol>
  </MRow>
</template>

<script>
import CurrencyPicker from '@components/data-picker/currency-picker'
import { OrganizationComputed } from '@state/modules/organization'
import MultipleFormItem from '@components/multiple-form-item'
import { generateId } from '@utils/id'
import { flattenFields } from '@data/form'
import PurchaseItemDrawer from './purchase-item-drawer'
import PurchaseItemRow from './purchase-item-row.vue'
import * as HELPER from '../../helpers/utils'
export default {
  name: 'OrderItemSection',
  components: {
    PurchaseItemDrawer,
    MultipleFormItem,
    PurchaseItemRow,
    CurrencyPicker,
  },
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    vendorId: { type: Number, default: undefined },
    hideTotal: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    customFormFields: {
      type: Array,
      default() {
        return []
      },
    },
    hiddenColumns: {
      type: Array,
      default() {
        return ['receivedQuantity']
      },
    },
    canEditeceivedQuantity: { type: Boolean, default: false },
    allowedMaxItem: { type: Number, default: 20 },
  },
  computed: {
    ...OrganizationComputed,
    tableColumns() {
      return [
        {
          name: `${this.$tc('sr_no')}`,
          width: '60px',
        },
        {
          name: `${this.$tc('product')} ${this.$tc('name')}`,
        },
        ...(this.hiddenColumns.indexOf('partNumber') < 0
          ? [
              {
                name: `${this.$tc('part_number')}`,
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('price') < 0
          ? [
              {
                name: `${this.$tc('price')}`,
              },
            ]
          : []),
        ...(this.hiddenColumns.indexOf('taxRate') < 0
          ? [
              {
                name: `${this.$tc('tax_rate')} (%)`,
              },
            ]
          : []),
        {
          name: this.disabled
            ? `${this.$tc('ordered')} ${this.$tc('quantity')}`
            : `${this.$tc('quantity')}`,
        },
        ...(this.hiddenColumns.indexOf('remainingQuantity') < 0
          ? [{ name: `${this.$tc('remaining')} ${this.$tc('quantity')}` }]
          : []),
        ...(this.hiddenColumns.indexOf('receivedQuantity') < 0
          ? [{ name: `${this.$tc('received')} ${this.$tc('quantity')}` }]
          : []),
        ...(this.hiddenColumns.indexOf('assetTypeId') < 0
          ? [{ name: `${this.$tc('asset')} ${this.$tc('type')}` }]
          : []),
        ...(this.hiddenColumns.indexOf('amount') < 0
          ? [{ name: this.$tc('amount'), style: 'text-align: right' }]
          : []),
        ...(!this.disabled
          ? [{ name: this.$tc('action'), width: '60px' }]
          : []),
      ]
    },
    totalAmountColspan() {
      const columnsLength = this.tableColumns.length
      if (!this.disabled) {
        return columnsLength - 1
      }
      return columnsLength
    },
    purchaseItems: {
      get() {
        return (this.value || {}).itemList || []
      },
      set(itemList) {
        return this.$emit('change', { ...(this.value || {}), itemList })
      },
    },
    purchase: {
      get() {
        return this.value || {}
      },
      set(value) {
        return this.$emit('change', value)
      },
    },
    discount: {
      get() {
        return (this.value || {}).discount
      },
      set(discount) {
        return this.$emit('change', {
          ...(this.value || {}),
          discount,
        })
      },
    },
    shippingCost: {
      get() {
        return (this.value || {}).shippingCost
      },
      set(shippingCost) {
        return this.$emit('change', {
          ...(this.value || {}),
          shippingCost,
        })
      },
    },
    customFieldValue: {
      get() {
        return (this.value || {}).fieldValueDetails
      },
      set(fieldValueDetails) {
        return this.$emit('change', {
          ...(this.value || {}),
          fieldValueDetails,
        })
      },
    },
  },
  created() {
    this.buildDefaultData()
  },
  methods: {
    handleSectionChange(selected) {
      const itemList = [...(this.purchaseItems || [])]
      selected.forEach((i) => {
        setTimeout(() => {
          itemList.push({
            guid: generateId(),
            productId: i.productId,
            itemName: i.productDisplayName,
            partNumber: i.partNumber,
            price: i.price || 0,
            taxRate: i.taxRate || 0,
            quantity: i.quantity || 1,
            totalAmount: HELPER.getTotalAmount(
              i.price || 0,
              i.quantity || 1,
              i.taxRate || 0
            ),
          })
        })
      })
      setTimeout(() => {
        this.$emit('change', { ...(this.value || {}), itemList })
      })
    },
    buildDefaultData() {
      const defaultData = {}
      flattenFields(this.customFormFields).forEach((f) => {
        if (f.defaultValue !== undefined) {
          defaultData[f.isSystemField ? f.paramName : f.id] = f.defaultValue
        }
      })
      this.$nextTick(() => {
        this.$emit('change', {
          ...(this.value || {}),
          fieldValueDetails: {
            ...defaultData,
            ...(this.purchase.fieldValueDetails || {}),
          },
        })
      })
      // this.customFieldValue = defaultData
    },
    handleCustomFieldUpdate(value) {
      this.customFieldValue = {
        ...this.customFieldValue,
        ...value,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.order-item-table {
  & .disabled-amount {
    width: 250px;
    text-align: right;
  }

  thead {
    // font-size: 95%;
    tr {
      @apply border-l border-b-0 border-r-0 border-t-0 border-solid border-neutral-lighter;

      td {
        @apply py-2 px-2 border-b border-l-0 border-r-0 border-t-0 border-solid border-neutral-lighter text-neutral-dark;

        &::before {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          content: unset;
          background-color: @neutral-lighter;
        }
      }
    }
  }

  tbody {
    // font-size: 90%;
    tr {
      td {
        @apply px-2 py-1;
      }
    }
  }
}

td.header-column {
  font-weight: bold;
  background: @neutral-lightest !important;
}
.@{ant-prefix}-form-vertical .@{ant-prefix}-form-item {
  padding-bottom: 0;
  margin-bottom: 0;
}

.title {
  font-size: 95%;
  font-weight: bold;
  color: #364658;
}

.border-none {
  border: none !important;
}
</style>

<style lang="less">
.order-item-table {
  input.@{ant-prefix}-input-number-input {
    text-align: right;
  }
  .@{ant-prefix}-input-number-handler-wrap {
    display: none;
  }
}
</style>

import api from '@api'
import SortBy from 'lodash/sortBy'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformRiskType,
  transformRiskTypeForServer,
} from '@data/release-risk-type'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualification = ({ name, selectedName, quals }) => {
  return {
    ...(name || selectedName || quals
      ? {
          qualDetails: buildFlatQualificationStructure(
            [
              name && buildNameFilterQuery(name),
              selectedName && buildNameFilterQuery(selectedName),
              ...(quals || []),
            ].filter(Boolean)
          ),
        }
      : {}),
  }
}

export function getRiskTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset
) {
  return api
    .post(
      '/release/risktype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformRiskType), total: data.totalCount }
    })
}

export function createRiskTypeApi(data) {
  const updateData = transformRiskTypeForServer(data)
  return api
    .post('/release/risktype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('release_risk'),
      }),
    })
    .then(transformRiskType)
}

export function removeRiskTypeApi(data) {
  return api.delete(`/release/risktype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('release_risk'),
    }),
  })
}

export function updateRiskTypeApi(data) {
  const updateData = transformRiskTypeForServer(data)
  return api
    .patch(`/release/risktype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('release_risk'),
      }),
    })
    .then(transformRiskType)
}

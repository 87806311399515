import api from '@api'

import {
  getModuleTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'
import { isPortalLogin } from '@utils/auth'

const transformPasswordPolicy = (policy) => {
  return {
    id: policy.id,
    minLength: policy.passwordMinLength,
    enableSpecialChar: policy.haveSpecialChar,
    enableAlphabetAndNumber: policy.haveAlphabetAndNumber,
    enableMixCase: policy.haveMixCase,
    expiryDays: policy.passwordExpiry,
    doNotallowUserToUsePreviousPassword:
      policy.doNotallowUserToUsePreviousPassword,
    previousPassword: policy.previousPassword,
  }
}

export const transformPasswordPolicyForServer = (policy) => {
  return {
    id: policy.id,
    passwordMinLength: policy.minLength,
    haveSpecialChar: policy.enableSpecialChar,
    haveAlphabetAndNumber: policy.enableAlphabetAndNumber,
    haveMixCase: policy.enableMixCase,
    passwordExpiry: policy.expiryDays,
    doNotallowUserToUsePreviousPassword:
      policy.doNotallowUserToUsePreviousPassword,
    previousPassword: policy.previousPassword,
  }
}

const __rootTc = getModuleTranslator()
const __rootT = getRootTranslator()

export function getPasswordPolicyApi() {
  return api
    .get(`${isPortalLogin() ? 'public' : ''}/passwordPolicy`)
    .then(transformPasswordPolicy)
}

export function upadatePasswordPolicyApi(data) {
  return api.patch('passwordPolicy', transformPasswordPolicyForServer(data), {
    message: __rootT('updated_successfully', {
      resource: `${__rootTc('password_policy')}`,
    }),
  })
}

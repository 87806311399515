import api from '@api'
// import Moment from 'moment'
import GroupBy from 'lodash/groupBy'
import Constants from '@constants'

import {
  // getModulePluaralTranslator,
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
// import moduleConfig from './config'

import { TransformKeyword } from '@src/state/modules/keyword/api.js'

import {
  transformTenantPrefrences,
  transformTenantPrefrencesForServer,
} from '@data/tenant-prefrences'

// const __tc = getModulePluaralTranslator(moduleConfig.translationKey)
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getTenantPrefrencesApi() {
  return api.get(`/tenantpreference`).then(transformTenantPrefrences)
}

export function updateTenantPrefrencesApi(data, sectionType) {
  return api
    .patch(`/tenantpreference`, transformTenantPrefrencesForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootT(sectionType || 'tenant_prefrences'),
      }),
    })
    .then(transformTenantPrefrences)
}

export function assetQRSupportDataApi() {
  return api.get(`/asset/qrcode/supportedData`).then((data) =>
    data.map((field) => ({
      key: field.key,
      name: field.name,
      required: field.required,
      customField: field.customField,
    }))
  )
}
export function assetBarcodeSupportDataApi() {
  return api.get(`/barcodeformatconfig/supportedData`).then((data) =>
    data.map((field) => ({
      key: field.key,
      name: field.name,
      required: field.required,
      customField: field.customField,
    }))
  )
}

export function getAssetKeywordsApi(model) {
  return api.get(`asset/${model}/nameformation/supporteddata`).then((data) => {
    const keywords = data.map((m) => ({
      ...m,
      groupName: __rootTc(model === Constants.CMDB ? 'ci' : 'asset'),
    }))
    return GroupBy(keywords.map(TransformKeyword), 'group')
  })
}

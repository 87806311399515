<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'RegistryTemplateModule',
  page() {
    return {
      title: this.$tc('registry_template'),
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.DEPLOYMENT) === -1
      ) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.DEPLOYMENT) === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...LicenseComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.deployment') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>

import api from '@api'

import { getRootTranslator } from '@utils/get-module-translator'
import { transformSupportData } from '@data/workflow'
import {
  transformScenarioForList,
  transformScenarioForServer,
  transformScenario,
} from '@data/scenario'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'

const __rootT = getRootTranslator()

export function getSupportDataApi(moduleName, id) {
  return api
    .get(`${moduleName}${id ? `/${id}` : ''}/scenario/supportedData`)
    .then(transformSupportData)
}

export function createScenarioApi(scenario) {
  return api.post(
    `${scenario.moduleName}${
      scenario.parentId ? `/${scenario.parentId}` : ''
    }/scenario`,
    transformScenarioForServer(scenario),
    {
      message: __rootT('created_successfully', {
        resource: __rootT('scenario'),
      }),
    }
  )
}

export function getScenarioApi(moduleName, id) {
  return api
    .get(`${moduleName}/scenario/${id}`)
    .then((data) => transformScenario(data))
}

export function getScenariosApi(
  { moduleName, filter, parentId },
  limit,
  offset
) {
  return api
    .post(
      `${moduleName}${parentId ? `/${parentId}` : ''}/scenario/search/byqual`,
      {
        qualDetails: filter
          ? buildFlatQualificationStructure([buildNameFilterQuery(filter)])
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformScenarioForList),
        total: data.totalCount,
      }
    })
}

export function updateScenarioApi(moduleName, data) {
  return api.patch(
    `${moduleName}/scenario/${data.id}`,
    transformScenarioForServer(data),
    {
      message: __rootT('saved_successfully', {
        resource: __rootT('scenario'),
      }),
    }
  )
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/scenario/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootT('scenario'),
      }),
    })
    .then((data) => transformScenarioForList(data))
}

export function deleteScenarioApi(moduleName, id) {
  return api.delete(`${moduleName}/scenario/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootT('scenario'),
    }),
  })
}

export function getScenariosForResourceApi(moduleName, id, limit, offset) {
  return api
    .get(`${moduleName}/${id}/scenario`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformScenarioForList),
        total: data.totalCount,
      }
    })
}

export function executeScenarioApi(scenarioId, targetId) {
  return api.post(
    '/scenario/execute',
    {
      objectId: targetId,
      scenarioId: scenarioId,
    },
    {
      message: __rootT('executed_successfully', {
        resource: __rootT('scenario'),
      }),
    }
  )
}

<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { isLoggedIn, isCxoUser } from '@utils/auth'
export default {
  name: 'MyApprovalModule',
  page() {
    return {
      title: this.$tc('my_approval', 2),
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (isLoggedIn() && isCxoUser()) {
        return vm.$router.replace({ name: '404' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
}
</script>

import api from '@api'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformDeploymentPolicies,
  transformDeploymentPoliciesForServer,
} from '@data/deployment-policy'
import {
  // getModuleTranslator,
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()
const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}
export function getDeploymentPoliciesApi(filter = {}, limit, offset) {
  return api
    .post(
      `/deploymentpolicy/search/byqual`,
      {
        qualDetails: filter.name ? buildQualifications(filter) : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformDeploymentPolicies) || [],
        total: data.totalCount,
      }
    })
}

export function createDeploymentPoliciesApi(data) {
  return api
    .post(`/deploymentpolicy`, transformDeploymentPoliciesForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('deployment_policy'),
      }),
    })
    .then(transformDeploymentPolicies)
}

export function readDeploymentPoliciesApi(data) {
  return api
    .get(`/deploymentpolicy/${data.id}`)
    .then(transformDeploymentPolicies)
}

export function updateDeploymentPoliciesApi(data) {
  return api
    .patch(
      `/deploymentpolicy/${data.id}`,
      transformDeploymentPoliciesForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('deployment_policy'),
        }),
      }
    )
    .then(transformDeploymentPolicies)
}

export function deleteDeploymentPoliciesApi(id) {
  return api.delete(`/deploymentpolicy/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('deployment_policy'),
    }),
  })
}

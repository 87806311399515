import { generateId } from '@utils/id'
import { getModuleTranslator } from '@utils/get-module-translator'
import configs from '../config'

const __t = getModuleTranslator(configs.translationKey)

export const pageText = {
  title: __t('sla'),
  description: __t('sla_description'),
  icon: 'file-alt',
  color: '#fab81f',
  iconBackground: '#FAEDCD',
}

export const availableSections = (availableModules) => [
  {
    key: generateId(),
    id: 'business_hours',
    title: __t('business_hours'),
    description: __t('business_hours_description'),
    sectionDetailTitle: __t('business_hours_section_title'),
    sectionDetailDescription: __t('business_hours_section_description'),
    steps: [
      {
        key: generateId(),
        id: 'sla_business_hours',
        title: __t('sla_business_hours'),
        description: __t('sla_business_hours_description'),
        helpBubble: {
          icon: 'business-time',
          title: __t('sla_business_hours_help_bubble_title'),
          description: __t('sla_business_hours_help_bubble_description'),
        },
        route: {
          text: __t('sla_business_hours_route'),
          module: 'business-hours',
          name: 'create',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'sla',
                  section: 'business_hours',
                  step: 'sla_business_hours',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'sla_guide',
    title: __t('sla_guide'),
    description: __t('sla_guide_description'),
    sectionDetailTitle: __t('sla_guide_section_title'),
    sectionDetailDescription: __t('sla_guide_section_description'),
    steps: [
      {
        key: generateId(),
        id: 'configuring_sla',
        title: __t('configuring_sla'),
        description: __t('configuring_sla_description'),
        helpBubble: {
          icon: 'file-alt',
          title: __t('configuring_sla_help_bubble_title'),
          description: __t('configuring_sla_help_bubble_description'),
        },
        route: {
          text: __t('configuring_sla_route'),
          module: 'sla',
          name: 'create',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'sla',
                  section: 'sla_guide',
                  step: 'configuring_sla',
                })
              )
            ),
          },
        },
      },
    ],
  },
]

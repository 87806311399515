import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "product-setup" */ './views/setup')
          ),
        meta: {},
      },
      {
        path: 'basic',
        name: `${routeNamePrefix}.basic`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "product-setup" */ './views/basic')
          ),
        meta: {},
      },
      {
        path: 'sla',
        name: `${routeNamePrefix}.sla`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "product-setup" */ './views/sla')
          ),
        meta: {},
      },
      {
        path: 'automation',
        name: `${routeNamePrefix}.automation`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "product-setup" */ './views/automation')
          ),
        meta: {},
      },
    ],
  },
]

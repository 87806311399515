import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.matrix` },
    meta: {
      moduleName,
      breadcrumbKey: 'request',
      nonLinked: true,
      titleFn(route, translator, pluralTranslator) {
        return `${translator(
          route.params.moduleName || 'request'
        )} ${translator('management')}`
      },
    },
    children: [
      {
        path: 'priority/matrix',
        name: `${routeNamePrefix}.matrix`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request-management" */ './views/priority-matrix-page'
            )
          ),
        meta: {
          breadcrumbKey: 'priority_matrix',
        },
      },
      {
        path: 'feedback-setting',
        name: `${routeNamePrefix}.feedback_setting`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request-management" */ './views/feedback-setting'
            )
          ),
        meta: {
          breadcrumbKey: 'feedback_setting',
        },
      },
      {
        path: 'source',
        name: `${routeNamePrefix}.source`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request-management" */ './views/source-list'
            )
          ),
        meta: {
          breadcrumbKey: 'source',
        },
      },
      {
        path: 'email-command-settings',
        name: `${routeNamePrefix}.email_command_settings`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "request-management" */ './views/email-command-settings'
            )
          ),
        meta: {
          breadcrumbKey: 'email_command_settings',
        },
      },
    ],
  },
]

<template>
  <FormRulesProvider :module-name="$constants.TASK">
    <FlotoContentLoader :loading="loading">
      <MRow :gutter="0">
        <MCol v-if="disabled" :size="12">
          <MInput
            v-model="searchTerm"
            class="search-box"
            :placeholder="$t('search')"
            @update="getTasks"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
          <MDropdown
            :options="filterOptions"
            :value="(filterType || {}).value"
            @change="handleFilterChange($event)"
          >
            <template v-slot:trigger>
              <MButton variant="transparent" :shadow="false" class="pr-1">
                {{
                  filterType
                    ? filterOptions.find((t) => t.key === filterType).text
                    : $t('all')
                }}
                <MIcon name="filter" size="lg" />
              </MButton>
            </template>
          </MDropdown>
        </MCol>
        <MCol v-if="!disabled" :size="12" class="text-right">
          <MTooltip>
            <template v-slot:trigger>
              <MIcon
                name="sync"
                class="mr-2 cursor-pointer text-neutral-light"
                @click="refresh"
              />
            </template>
            {{ $t('refresh') }}
          </MTooltip>
          <MButton
            id="add-task-btn"
            variant="primary"
            @click="() => handleCreate('task')"
          >
            {{ $t('add') }} {{ $tc('task') }}
          </MButton>
          <MButton
            id="add-summary-task-btn"
            class="mx-2"
            variant="primary"
            @click="() => handleCreate('summary_task')"
          >
            {{ $t('add') }} {{ $tc('summary_task') }}
          </MButton>
          <MButton
            id="add-milestone-btn"
            variant="primary"
            @click="() => handleCreate('milestone')"
          >
            {{ $t('add') }} {{ $tc('milestone') }}
          </MButton>
        </MCol>
      </MRow>
      <MRow :gutter="0">
        <MCol :size="12">
          <HierarchyExplorer
            :value="taskList"
            class="no-tree-line"
            :sortable="!disabled"
            :max-level="5"
            :default-value="taskList"
            :add-fn="addFn"
            :remove-fn="removeFn"
            :allow-drop="!disabled"
            :bulk-update-fn="bulkUpdateFn"
            :scroll-sensitivity="200"
            :force-fallback="true"
            notify-parent
            @move="handleChangeOrder"
            @add="handleAdd"
            @remove="handleRemove"
          >
            <template v-slot="{ item, canAdd, toggle, remove }">
              <ProjectTaskItem
                :item="item"
                :tasks="tasks"
                :can-add="canAdd"
                :toggle-expand="toggle"
                :disabled="disabled"
                :task-type-options="taskTypeOptions"
                :resource-id="resourceId"
                @add-action="handleCreate"
                @update="updateFn"
                @edit="handleEdit"
                @remove="handleRemoveProjectTaskItem(item, remove)"
              />
            </template>
          </HierarchyExplorer>
        </MCol>
      </MRow>
      <FlotoDrawerForm
        :open="showCreateDrawer"
        @cancel="hideDrawer"
        @submit="createFn"
      >
        <template v-slot:header>
          {{
            formData.taskId
              ? `${$tc('edit')}: ${formData.name}`
              : `${$tc('add')} ${$tc(type)}`
          }}
        </template>
        <ProjectTaskForm
          :task="formData"
          :resource="resource"
          :type="type"
          @change="handleChangeFormData"
        />
        <template v-slot:actions="{ hide, submit }">
          <MButton
            id="update-add-btn"
            class="mr-2"
            :loading="processing"
            @click="submit"
          >
            {{ formData.taskId ? $tc('update') : $tc('add') }}
          </MButton>
          <MButton id="cancel-btn" variant="default" @click="hide">
            {{ $t('cancel') }}
          </MButton>
        </template>
      </FlotoDrawerForm>
    </FlotoContentLoader>
  </FormRulesProvider>
</template>

<script>
import InfiniteTree from 'infinite-tree'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
import CloneDeep from 'lodash/cloneDeep'
import HierarchyExplorer from '@components/sortable/hierarchy-explorer'
import { defaultTask } from '@data/task'
import ProjectTaskForm from './project-task-form'
import { TaskComputed } from '@state/modules/task'
import { buildHierarchy } from '@data/recursive'
import {
  createTaskApi,
  updateTaskApi,
  deleteTaskApi,
  getPlanningTasksApi,
  createSummaryTaskApi,
  updateTasksApi,
  deleteSummaryTaskApi,
  updateSummaryTaskApi,
  projectTasksBulkUpdateApi,
} from './api'
import ProjectTaskItem from './project-task-item'
export default {
  name: 'ProjectTaskList',
  components: {
    FormRulesProvider,
    ProjectTaskForm,
    HierarchyExplorer,
    ProjectTaskItem,
  },
  props: {
    resourceId: { type: [String, Number], required: true },
    resource: { type: Object, required: true },
    moduleName: {
      type: String,
      default() {
        return this.$constants.PROJECT
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.filterOptions = [
      { text: this.$tc('all'), key: 'all' },
      { text: `${this.$tc('task')} ${this.$tc('only')}`, key: 'task' },
      {
        text: `${this.$tc('milestone')} ${this.$tc('only')}`,
        key: 'milestone',
      },
      { text: `${this.$tc('unassigned')}`, key: 'unassigned' },
      { text: `${this.$tc('overdue')}`, key: 'overdue' },
    ]
    return {
      loading: true,
      processing: false,
      formData: {},
      data: [],
      taskList: [],
      tasks: [],
      type: 'task',
      searchTerm: '',
      showCreateDrawer: false,
      level1Processing: false,
      level2Processing: false,
      filterType: undefined,
    }
  },
  computed: {
    ...TaskComputed,
    defaultTaskData() {
      const task = CloneDeep(defaultTask)
      if (this.type === 'milestone') {
        const taskTypeId = (
          this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
        ).id
        return {
          ...task,
          taskTypeId,
        }
      }
      return task
    },
    milestoneId() {
      return (
        this.taskTypeOptions.find((t) => t.systemName === 'Milestone') || {}
      ).id
    },
  },
  created() {
    this.getTasks()
  },
  methods: {
    loadData(data) {
      this.tree.loadData(data)
    },
    handleFilterChange(option) {
      this.filterType = option.key === 'all' ? undefined : option.key
      this.getTasks()
    },
    handleAddAction(type, item) {
      this.type = type
      const task = CloneDeep(this.defaultTaskData)
      this.formData = {
        ...task,
        taskParentId: item.taskId,
        taskParentModel: 'summary_task',
      }
      this.showCreateDrawer = true
    },
    handleCreate(type, parentItem) {
      this.type = type
      if (type === 'summary_task') {
        const task = CloneDeep(this.defaultTaskData)
        this.formData = {
          ...task,
        }
        this.showCreateDrawer = true
        return
      }
      this.$router.push(
        this.$modules.getModuleRoute('my-tasks', 'create', {
          query: {
            moduleName: this.moduleName,
            refModel: this.moduleName,
            refId: this.resourceId,
            backLink: JSON.stringify(
              this.$modules.getModuleRoute('project', 'edit', {
                params: { id: this.resourceId },
              })
            ),
            ...(type === 'milestone' ? { taskType: this.type } : {}),
            ...(parentItem && parentItem.taskId
              ? {
                  taskParentId: parentItem.taskId,
                  taskParentModel: parentItem.type,
                }
              : {}),
          },
        })
      )
    },
    handleEdit(data) {
      const item = CloneDeep(data)
      const type =
        item.type !== 'summary_task'
          ? (
              this.taskTypeOptions.find((t) => t.systemName === 'Milestone') ||
              {}
            ).id === item.taskTypeId
            ? 'milestone'
            : 'task'
          : 'summary_task'
      this.type = type
      if (item.type === 'summary_task') {
        this.formData = {
          ...item,
        }
        this.showCreateDrawer = true
        return
      }
      this.$router.push(
        this.$modules.getModuleRoute('my-tasks', 'edit', {
          params: { id: item.taskId },
          query: {
            moduleName: this.moduleName,
            refModel: this.moduleName,
            refId: this.resourceId,
            backLink: JSON.stringify(
              this.$modules.getModuleRoute('project', 'edit', {
                params: { id: this.resourceId },
              })
            ),
          },
        })
      )
    },
    hideDrawer() {
      this.showCreateDrawer = false
      this.formData = {}
    },
    handleChangeFormData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    handleRemoveProjectTaskItem(item, removeFn) {
      return removeFn(item).then(() => this.refresh())
    },
    getTasks() {
      return getPlanningTasksApi(
        {
          moduleName: this.moduleName,
          filter: this.searchTerm,
          ...(this.filterType ? { [this.filterType]: true } : {}),
          milestoneId: this.milestoneId,
          serchable: this.disabled,
        },
        this.resourceId
      ).then((data) => {
        this.data = (data || {}).hierarchy || []
        const options = { data: (data || {}).hierarchy || [] }
        this.tree = new InfiniteTree(options)
        this.tasks = (data || {}).items || []
        this.taskList = this.tree.nodes || []
        this.$emit('task-exist', ((data || {}).items || []).length > 0)
        this.loading = false
      })
    },
    updateTasks(tasks, notify = true) {
      projectTasksBulkUpdateApi(this.resourceId, tasks, notify).finally(() => {
        this.getTasks()
      })
    },
    async removeNode(id) {
      const nodes = Object.keys(this.tree.nodeTable.data)
        .filter((nodeId) => String(nodeId).indexOf(id) >= 0)
        .map((nodeId) => this.tree.nodeTable.data[nodeId])
      if (!nodes.length) {
        return
      }
      const node = nodes[0]
      if (node) {
        this.tree.removeNode(node)
      }
    },
    async updateNode(id, data, nodeFields) {
      const nodes = Object.keys(this.tree.nodeTable.data)
        .filter((nodeId) => String(nodeId).indexOf(id) >= 0)
        .map((nodeId) => this.tree.nodeTable.data[nodeId])
      if (!nodes.length) {
        return
      }
      const node = nodes[0]
      if (node) {
        if (nodeFields.length) {
          nodeFields.forEach((field) => {
            node[field] = data[field]
          })
        }
        this.tree.nodeTable.data[node.id] = node
        this.tree.updateNode(node)
      }
    },
    handleRemove(event, parent) {
      if (event.item.type === 'summary_task') {
        return this.refresh()
      }
      setTimeout(() => {
        if (parent) {
          const parentNode = this.tree.getNodeById(parent.id)
          const children = parentNode.getChildren()
          const remainingChildren = children.filter(
            (c) => c.id !== event.item.id
          )
          const nodes = Object.keys(this.tree.nodeTable.data)
            .filter((nodeId) => String(nodeId).indexOf(parent.id) >= 0)
            .map((nodeId) => this.tree.nodeTable.data[nodeId])
          if (!nodes.length) {
            return
          }
          const node = nodes[0]
          if (node) {
            this.tree.removeChildNodes(node)
            this.tree.addChildNodes(remainingChildren, 0, node)
            this.updateTasks([...this.tree.nodes], false)
          }
        } else {
          this.removeNode(event.item.id)
          this.updateTasks([...this.tree.nodes], false)
        }
      }, 500)
    },
    handleAdd(event, parent) {
      if (event.item.type === 'summary_task') {
        return this.refresh()
      }
      const placingInde = event.index
      if (parent) {
        const parentNode = this.tree.getNodeById(parent.id)
        this.updateNode(
          event.item.id,
          {
            taskParentId: parent.taskId,
            taskParentModel: parent.type,
          },
          ['taskParentId', 'taskParentModel']
        )
        const node = this.tree.getNodeById(event.item.id)
        this.tree.moveNodeTo(node, parentNode, placingInde)
        this.updateTasks([...this.tree.nodes])
      } else {
        this.updateNode(
          event.item.id,
          {
            taskParentId: 0,
            taskParentModel: null,
            parent: null,
          },
          ['taskParentId', 'taskParentModel']
        )
        const node = this.tree.getNodeById(event.item.id)
        this.tree.addChildNodes([node], 0)
      }
    },
    handleChangeOrder(event) {
      if (event.parent) {
        const node = event.parent
        if (node) {
          const list = this.tasks.filter(
            (t) => t.taskParentId !== event.parent.taskId
          )
          const hierarchy = buildHierarchy(
            list,
            'children',
            'taskId',
            'taskParentId'
          )
          const options = { data: hierarchy || [] }
          const updatedTree = new InfiniteTree(options)
          // this.tree.removeChildNodes(node)
          const parentNode = updatedTree.getNodeById(event.parent.id)
          updatedTree.addChildNodes(
            (event.items || []).map((i, index) => ({ ...i, order: index + 1 })),
            0,
            parentNode
          )
          this.updateTasks(updatedTree.nodes)
        }
      } else {
        const updatedList = (event.items || []).map((i, index) => ({
          ...i,
          order: index + 1,
        }))
        this.updateTasks(updatedList)
      }
    },
    createFn() {
      const data = this.formData
      this.processing = true
      if (this.type === 'task' || this.type === 'milestone') {
        return this.createTask({ ...data })
          .then(() => {
            this.hideDrawer()
          })
          .finally(() => {
            this.processing = false
          })
      }
      if (this.type === 'summary_task') {
        return this.createSummaryTask(data)
          .then(() => {
            this.hideDrawer()
          })
          .finally(() => {
            this.processing = false
          })
      }
    },
    createTask(data) {
      if (data.taskId) {
        return updateTaskApi(this.moduleName, this.resourceId, {
          ...data,
          id: data.taskId,
          moduleName: this.moduleName,
        }).then(() => this.refresh())
      }
      return createTaskApi(this.moduleName, this.resourceId, {
        ...data,
        moduleName: this.moduleName,
      }).then(() => this.refresh())
    },
    createSummaryTask(data) {
      if (data.taskId) {
        return updateSummaryTaskApi(this.moduleName, this.resourceId, {
          ...data,
          id: data.taskId,
          moduleName: this.moduleName,
        }).then(() => this.refresh())
      }
      return createSummaryTaskApi(this.moduleName, this.resourceId, {
        ...data,
        moduleName: this.moduleName,
      }).then(() => this.refresh())
    },

    updateFn(data) {
      if (data.type === 'summary_task') {
        return updateSummaryTaskApi(this.moduleName, this.resourceId, {
          ...data,
          id: data.taskId,
          moduleName: this.moduleName,
        }).then(() => this.refresh())
      }
      return updateTaskApi(this.moduleName, this.resourceId, {
        ...data,
        id: data.taskId,
        moduleName: this.moduleName,
      }).then(() => this.refresh())
    },
    bulkUpdateOnlyTaks(items, parent) {
      if (!parent) {
        return Promise.resolve()
      }
      this.level2Processing = true
      return updateTasksApi(
        this.moduleName,
        this.resourceId,
        items.map((item) => ({
          ...item,
          taskParentId: item.remove ? 0 : parent.taskId,
          taskParentModel: item.remove ? null : parent.type,
        }))
      ).finally(() => {
        this.level2Processing = false
        this.refreshList()
      })
    },
    refreshList() {
      if (!(this.level1Processing && this.level2Processing)) {
        this.refresh()
      }
    },
    bulkUpdateFn(items) {
      return Promise.resolve(items)
    },
    refresh() {
      this.getTasks()
    },
    addFn(data, parent) {
      return Promise.resolve([data])
    },
    removeFn(item) {
      if (item.type === 'summary_task') {
        return deleteSummaryTaskApi(this.moduleName, this.resourceId, {
          id: item.taskId,
        })
      }
      return deleteTaskApi(this.moduleName, this.resourceId, {
        id: item.taskId,
      })
    },
  },
}
</script>

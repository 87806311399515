import { getRootPluaralTranslator } from '@utils/get-module-translator'

const $tc = getRootPluaralTranslator()

export const patchCategoryOptions = () => [
  { key: 'tools', value: 'tools', text: 'Tools' },
  { key: 'feature_packs', value: 'feature_packs', text: 'Feature Packs' },
  { key: 'service_packs', value: 'service_packs', text: 'Service Packs' },
  { key: 'update_rollups', value: 'update_rollups', text: 'Update Rollups' },
  {
    key: 'definition_updates',
    value: 'definition_updates',
    text: 'Definition Updates',
  },
  {
    key: 'critical_updates',
    value: 'critical_updates',
    text: 'Critical Updates',
  },
  { key: 'updates', value: 'updates', text: 'Updates' },
  { key: 'hotfix', value: 'hotfix', text: 'Hotfix' },
  {
    key: 'security_updates',
    value: 'security_updates',
    text: 'Security Updates',
  },
]
export const patchSeverityOptions = () => [
  { key: 'critical', value: 'critical', text: 'Critical', color: '#ff3636' },
  { key: 'important', value: 'important', text: 'Important', color: '#ef7422' },
  { key: 'moderate', value: 'moderate', text: 'Moderate', color: '#bc9b84' },
  { key: 'low', value: 'low', text: 'Low', color: '#14212D' },
  {
    key: 'unspecified',
    value: 'unspecified',
    text: 'Unspecified',
    color: '#242527',
  },
]

export const commandStateOptions = () => [
  { key: 'yet_to_receive', value: 'yet_to_receive', text: 'Yet to Receive' },
  {
    key: 'partially_installed',
    value: 'partially_installed',
    text: 'Partially Installed',
  },
  { key: 'in_progress', value: 'in_progress', text: 'In Progress' },
  { key: 'success', value: 'success', text: 'Success' },
  { key: 'failed', value: 'failed', text: 'Failed' },
  { key: 'cancelled', value: 'cancelled', text: 'Cancelled' },
  { key: 'not_applicable', value: 'not_applicable', text: 'Not Applicable' },
  { key: 'not_ready', value: 'not_ready', text: 'Not Ready' },
  { key: 'received', value: 'received', text: 'Received' },
  {
    key: 'resolving_dependency',
    value: 'resolving_dependency',
    text: 'Resolving Dependency',
  },
]

export const patchApprovalStatusOptions = () => [
  {
    key: 'not_approved',
    value: 'not_approved',
    text: 'Not Approved',
    color: '#f5bc18',
  },
  { key: 'approved', value: 'approved', text: 'Approved', color: '#3CB371' },
  { key: 'declined', value: 'declined', text: 'Declined', color: '#FF0000' },
]

export const patchDownloadStatusOptions = () => [
  { key: 'pending', value: 'pending', text: 'Pending' },
  { key: 'in_progress', value: 'in_progress', text: 'In Progress' },
  { key: 'success', value: 'success', text: 'Success' },
  { key: 'failed', value: 'failed', text: 'Failed' },
]

export const patchTestStatusOptions = () => [
  { key: 'not_tested', value: 'not_tested', text: 'Not Tested' },
  { key: 'in_testing', value: 'in_testing', text: 'In Testing' },
  { key: 'test_success', value: 'test_success', text: 'Success' },
  { key: 'test_failed', value: 'test_failed', text: 'Failed' },
]

export const testStatusOptions = () => [
  { key: 'pending', value: 'pending', text: 'Pending' },
  {
    key: 'under_observation',
    value: 'under_observation',
    text: 'Under Observation',
  },
  { key: 'success', value: 'success', text: 'Success' },
  { key: 'failed', value: 'failed', text: 'Failed' },
]

export const rebootBehaviourOptions = () => [
  { key: 'yes', value: 'yes', text: 'Yes', singleValueSelection: true },
  { key: 'no', value: 'no', text: 'No', singleValueSelection: true },
  {
    key: 'may_be',
    value: 'may_be',
    text: 'May be',
    singleValueSelection: true,
  },
]

export const manualPatchplatform = () => [{ text: 'Windows', key: 'windows' }]
export const patchSourceOptions = () => [
  { key: 'patch_scanning', value: 'patch_scanning', text: 'Patch Scanning' },
  { key: 'manual', value: 'manual', text: 'Manual' },
]

export const patchStatusOptions = () => [
  { key: 'draft', value: 'draft', text: 'Draft' },
  { key: 'published', value: 'published', text: 'Published' },
]

export const patchUploadStatusOptions = () => [
  { key: 'yet_to_upload', value: 'yet_to_upload', text: 'Yet to Upload' },
  { key: 'success', value: 'success', text: 'Success' },
]

export const patchArchitectureOptions = () => [
  { text: '32 BIT', key: 'bit_32' },
  { text: '64 BIT', key: 'bit_64' },
]

export const patchTypeOptions = () => [
  { key: 'os_patch', value: 'os_patch', text: $tc('os_patch') },
  { key: 'third_party', value: 'third_party', text: $tc('third_party') },
]

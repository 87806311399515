var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',{staticClass:"my-5 items-center",attrs:{"gutter":0}},[_c('MCol',{attrs:{"id":"technician-picker","size":3}},[_c('FlotoTechnicianPicker',{attrs:{"value":_vm.value.technicianId,"disabled":_vm.disabled,"placeholder":_vm.disabled ? '---' : undefined,"group-id":_vm.resource.groupId,"change-technician-on-group-change":""},on:{"change":function($event){return _vm.handleChange({ technicianId: $event })}},scopedSlots:_vm._u([{key:"trigger",fn:function(slotData){return [_c('FlotoDropdownTrigger',_vm._b({attrs:{"lable":_vm.$tc('assigned_to'),"icon-name":"vector","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlotoUserAvatar',{style:({
                minWidth: ((24) + "px"),
              }),attrs:{"size":34,"avatar":(slotData.currentItem || {}).avatar}})]},proxy:true}],null,true)},'FlotoDropdownTrigger',slotData,false))]}}])})],1),(
      _vm.moduleName !== _vm.$constants.CHANGE && _vm.moduleName !== _vm.$constants.RELEASE
    )?_c('MCol',{attrs:{"size":6}},[_c('FlotoTriggerView',{attrs:{"icon-name":"calendar-alt","lable":((_vm.$t('start')) + " " + (_vm.$t('date')) + " - " + (_vm.$t('end')) + " " + (_vm.$t('date')))},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('div',[(
              (_vm.value.timeRange || {}).startTime ||
              (_vm.value.timeRange || {}).endTime
            )?[_c('span',[_vm._v(_vm._s(_vm._f("datetime")((_vm.value.timeRange || {}).startTime)))]),(
                (_vm.value.timeRange || {}).startTime &&
                (_vm.value.timeRange || {}).endTime
              )?_c('span',{staticClass:"text-center"},[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("datetime")(_vm.value.timeRange.endTime)))])]:[_vm._v(" --- ")]],2)]},proxy:true}],null,false,1750743193)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
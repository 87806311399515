<template>
  <FlotoFixedView :gutter="0">
    <MInput
      v-model="query"
      auto-focus
      class="search-box mt-2 mb-4"
      :placeholder="$t('search')"
    >
      <template v-slot:prefix>
        <MIcon name="search" />
      </template>
    </MInput>
    <FlotoScrollView class="overflow-x-hidden">
      <MRow :gutter="0">
        <MCol :size="12">
          <MCheckboxGroup v-model="selectedTask" class="w-full">
            <TransitionGroup name="placeholder">
              <MRow
                v-for="(task, index) in currentColumns"
                :key="task.id"
                :gutter="0"
                class="p-1"
                :class="{
                  'bg-neutral-lightest': index % 2 === 0,
                  'my-2': index !== 0 && index !== currentColumns.length - 1,
                }"
              >
                <MCol>
                  <MCheckbox :disabled="disabled" :value="task.taskId">
                    <FlotoDot :bg="task.color" />
                    {{ task.name }}: {{ task.subject }}
                  </MCheckbox>
                </MCol>
              </MRow>
            </TransitionGroup>
          </MCheckboxGroup>
        </MCol>
      </MRow>
    </FlotoScrollView>
  </FlotoFixedView>
</template>

<script>
import { searchList } from '@utils/arr'
export default {
  name: 'DependencySelectionList',
  model: { event: 'change' },
  props: {
    value: { type: Array, default: undefined },
    tasks: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      query: undefined,
    }
  },
  computed: {
    currentColumns() {
      if (!this.query) {
        return this.tasks
      }
      return searchList(this.tasks, this.query, 'subject')
    },
    selectedTask: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
  },
}
</script>

<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'ScenarioModule',
  page() {
    return {
      title: this.$t('scenario'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.REQUEST) === -1 &&
        !to.params.parentId
      ) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
    })
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  created() {
    // if user doesn't have permission to handle admin automation section
    if (
      this.myAllowedModules.indexOf('admin.automation') === -1 &&
      !this.$route.params.parentId
    ) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.myAllowedModules.indexOf('admin.service_catalog') === -1 &&
      this.$route.params.parentId
    ) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>

import constants from '@/src/constants/index'
import { generateId } from '@utils/id'
import { getModuleTranslator } from '@utils/get-module-translator'
import configs from '../config'

const __t = getModuleTranslator(configs.translationKey)

export const pageText = {
  title: __t('automation'),
  description: __t('automation_description'),
  icon: 'cogs',
  color: '#2549f2',
  iconBackground: '#bdc8fb',
}

export const availableSections = (availableModules) => [
  {
    key: generateId(),
    id: 'automation_setup',
    title: __t('automation_setup'),
    description: __t('automation_setup_description'),
    sectionDetailTitle: __t('automation_setup_section_title'),
    sectionDetailDescription: __t('automation_setup_section_description'),
    steps: [
      {
        key: generateId(),
        id: 'auto_assignment_setup',
        title: __t('auto_assignment_setup'),
        description: __t('auto_assignment_setup_description'),
        helpBubble: {
          icon: 'clipboard-list-check',
          title: __t('auto_assignment_setup_help_bubble_title'),
          description: __t('auto_assignment_setup_help_bubble_description'),
        },
        route: {
          text: __t('auto_assignment_setup_route'),
          module: 'admin',
          name: 'auto-assignment',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'automation_setup',
                  step: 'auto_assignment_setup',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'workflow_setup',
    title: __t('workflow_setup'),
    description: __t('workflow_setup_description'),
    sectionDetailTitle: __t('workflow_setup_section_title'),
    sectionDetailDescription: __t('workflow_setup_section_description'),
    steps: [
      {
        key: generateId(),
        id: 'creating_workflow_setup',
        title: __t('creating_workflow_setup'),
        description: __t('creating_workflow_setup_description'),
        helpBubble: {
          icon: 'retweet',
          title: __t('workflow_setup_help_bubble_title'),
          description: `<p>${__t(
            'based_on_module_follow_steps_to_create_workflow'
          )}</p>
          <b>${__t('request_module')}</b>
          <ul>
          <li>${__t(
            'give_workflow_name_select_module_request_and_type_event'
          )}</li>
          <li>${__t('select_trigger_event_and_add_condition')}</li>
          <li>${__t('select_request_workflow_condition')}</li>
          <li>${__t('select_request_workflow_action')}</li>
          <li>${__t('save_request_workflow')}</li></ul>
          <b>${__t('asset_module')}</b>
          <ul>
          <li>${__t(
            'give_workflow_name_select_module_asset_and_type_event'
          )}</li>
          <li>${__t('select_asset_trigger_event_and_add_condition')}</li>
          <li>${__t('select_asset_workflow_action')}</li>
          <li>${__t('save_asset_workflow')}</li>
          </ul>`,
        },
        route: {
          text: __t('creating_workflow_setup_route'),
          module: 'workflow',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'workflow_setup',
                  step: 'creating_workflow_setup',
                })
              )
            ),
          },
        },
      },
    ],
  },
  {
    key: generateId(),
    id: 'approval_workflow_setup',
    title: __t('approval_workflow_setup'),
    description: __t('approval_workflow_setup_description'),
    sectionDetailTitle: __t('approval_workflow_setup_section_title'),
    sectionDetailDescription: __t(
      'approval_workflow_setup_section_description'
    ),
    steps: [
      {
        key: generateId(),
        id: 'approval_workflow_setting_setup',
        title: __t('approval_workflow_setting_setup'),
        description: __t('approval_workflow_setting_setup_description'),
        helpBubble: {
          icon: 'bars',
          title: __t('approval_workflow_setting_setup_help_bubble_title'),
          description: __t(
            'approval_workflow_setting_setup_help_bubble_description'
          ),
        },
        route: {
          text: __t('approval_workflow_setting_setup_route'),
          module: 'approval',
          name: '',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'approval_workflow_setup',
                  step: 'approval_workflow_setting_setup',
                  defaultData: {
                    approvalSettings: {
                      open: true,
                      currentModule: availableModules.includes(
                        constants.REQUEST
                      )
                        ? 'request'
                        : 'asset',
                    },
                  },
                })
              )
            ),
          },
        },
      },
      {
        key: generateId(),
        id: 'creating_approval_workflow_setup',
        title: __t('creating_approval_workflow_setup'),
        description: __t('creating_approval_workflow_setup_description'),
        helpBubble: {
          icon: 'check-double',
          title: __t('creating_approval_workflow_setup_help_bubble_title'),
          description: __t(
            'creating_approval_workflow_setup_help_bubble_description'
          ),
        },
        route: {
          text: __t('creating_approval_workflow_setup_route'),
          module: 'approval',
          name: 'create',
          query: {
            _setup: encodeURIComponent(
              btoa(
                JSON.stringify({
                  __setupguide: true,
                  guide: 'automation',
                  section: 'approval_workflow_setup',
                  step: 'creating_approval_workflow_setup',
                  defaultData: {
                    currentModule: availableModules.includes(constants.REQUEST)
                      ? 'request'
                      : 'asset',
                  },
                })
              )
            ),
          },
        },
      },
    ],
  },
]

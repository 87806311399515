<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
import { PageMethods } from '@state/modules/page'
import { PreferenceComputed } from '@state/modules/preference'
// import Constants from '@constants'
export default {
  name: 'RemoteDeploymentModule',
  page() {
    return {
      title: this.$t('Remote Deployment'),
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  watch: {
    '$route.params.configType': function (newValue, prevValue) {
      if (this[`${prevValue}PageReset`]) {
        this[`${prevValue}PageReset`]()
      }
    },
  },
  methods: {
    ...PageMethods,
  },
  // TO DO: Will do in pre-7.5
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (
  //       vm.availableModulesInLicense.indexOf(Constants.PATCH) === -1 &&
  //       vm.availableModulesInLicense.indexOf(Constants.DEPLOYMENT) === -1
  //     ) {
  //       return vm.$router.replace({ name: 'upgrade-plan' })
  //     }
  //     if (
  //       vm.myAllowedModules.indexOf(Constants.PATCH) === -1 &&
  //       vm.myAllowedModules.indexOf(Constants.DEPLOYMENT) === -1
  //     ) {
  //       return vm.$router.replace({ name: '404' })
  //     }
  //   })
  // },
}
</script>

<template>
  <MDropdown ref="dropdownMenu">
    <template v-slot:trigger>
      <MButton
        id="action-btn"
        variant="transparent"
        class="text-neutral"
        :shadow="false"
        shape="circle"
      >
        <MIcon name="ellipsis-v" size="2x" />
      </MButton>
    </template>
    <template v-slot:menu>
      <MMenu class="action-menu">
        <MMenuItem
          v-if="
            project.projectStatus === 'cancelled' ||
            project.projectStatus === 'closed'
          "
          id="archive-item"
        >
          <Archive
            :resource-id="project.id"
            :module-name="moduleName"
            @trigger="hide"
            @done="handleArchive"
          />
        </MMenuItem>
        <MMenuItem
          v-if="
            project.projectStatus !== 'cancelled' &&
            project.projectStatus !== 'closed'
          "
          id="cancel-project-item"
        >
          <CancelProject
            :project="project"
            :module-name="moduleName"
            :update-api="updateApi"
            @trigger="hide"
          />
        </MMenuItem>
        <MMenuItem id="clone-project-item">
          <FlotoLink
            :to="
              $modules.getModuleRoute('project', 'create', {
                params: { cloningId: project.id },
              })
            "
          >
            <MIcon name="clone" class="action-menu-icon mr-1" />
            <span class="ml-1 action-menu-text">
              {{ $t('clone') }} {{ $tc('project') }}</span
            >
          </FlotoLink>
        </MMenuItem>
      </MMenu>
    </template>
  </MDropdown>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import CancelProject from './action-menu-items/cancel-project'
import Archive from './action-menu-items/archive'
export default {
  name: 'ActionMenu',
  components: {
    Archive,
    CancelProject,
  },
  props: {
    moduleName: { type: String, required: true },
    project: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    updateApi: { type: Function, default: undefined },
  },
  computed: {
    ...authComputed,
  },
  methods: {
    hide() {
      this.$refs.dropdownMenu.hide()
    },
    handleArchive() {
      this.$emit('archive')
    },
  },
}
</script>
